import React from "react";
import { VscLoading } from "react-icons/vsc";

const StreamLoadingPopup = () => {
  return (
    <div className="w-full h-full flex flex-col items-end justify-end z-50 mb-4">
      <div className="bg-blue-600 bg-opacity-80 flex items-center px-3 py-2 rounded space-x-4 w-full">
        <VscLoading className="text-white animate-spin w-6 h-6" />
        <div className="flex flex-col items-start text-white space-y-0.5">
          <p className="text-sm font-medium">Analyzing files...</p>
          <p className="text-xs text-blue-200">This takes about 60 seconds</p>
        </div>
      </div>
    </div>
  );
};

export default StreamLoadingPopup;
