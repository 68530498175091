const landing = {
  mode: "en",
  demo: "Sign up for a monthly check report",
  getStarted: "Get Started",
  inquire: "Contact Us",
  title1: "Auto search impfood",
  title2: "by simply uploading",
  title3: "your document",
  subtitle: "Check now",
  dropzone: "PDF files up to 20MB",
  dropzoneTitle: "Drop your document here",
  dropzoneButton: "Select Files",
  actionTitle: "services",
  action1: "Discrepancy Inspection",
  action2: "Drafting Solution",
  aboutBody1: "TradDocs is at the forefront of revolutionizing international trade finance.",
  aboutBody2:
    "Leveraging advanced AI technology, we specialize in automating trade documents like Letters of Credit, Bills of Lading, and Certificates of Origin. Our solutions, equipped with AI-powered discrepancy inspection, ensure accuracy and efficiency, minimizing risks and maximizing gains for trade companies and banks.",
  aboutBody3:
    "Experience seamless trade document processing with TradDocs, your partner in smart trade finance solutions.",
  sol1title1: "Upload Multiple Documents at Once",
  sol1subtitle:
    "We extract the contents of all shipping documents, including B/L, C/I, C/O, P/L, and third-party certificates, using AI.",
  sol1body: `["Zoom in on the documents for easy viewing.","Add notes to the document and download it.","Missing items are identified and highlighted.","Checklists are given to assist with inspection."]`,
  learnMore: "Learn More",
  sol2title1: "Automatically Organizes your Documents",
  sol2subtitle: "Using our AI classifier, we organize documents by folder and document type.",
  sol2body: `["Spell check", "Punctuation/Grammar Check", "Missing Check", "Document Writing Instructions Check"]`,
  sol3title1: "Efficiency through",
  sol3title2: "Team Collaboration",
  sol3title3: "",
  sol3subtitle:
    "Set team members' access permissions to facilitate team collaboration through real-time AI analysis and shared workspace.",
  sol3body1: "1. Upload trade documents to the shared system.",
  sol3body2: "2. Real-time AI analysis for team review.",
  sol3body3: "3. Identification of discrepancies through collaboration.",
  sol3body4: "4. Comprehensive reports accessible to all team members.",
  security: "Security",
  securityTitle1: "Secure Customer Data",
  securityTitle2: "With TradDocs",
  securitySubtitle: "When dealing with important data in shipping documents, security is the priority.",
  login: "Log in",
};

export default landing;
