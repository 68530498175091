const fileViewer = {
  mode: "ko",
  alert: "TradDocs AI는 실수를 할 수 있습니다. 중요한 정보는 직접 확인하는 것을 권장합니다.",
  confidence: "인식률",
  thisDocument: "현재 서류",
  missingText: "다음의 정보는 직접 검토를 권장합니다",
  checked: "체크완료",
  save: "저장",
  download: "PDF 다운로드",
  memo: "메모 작성...",
  toast: {
    title: "저장 되었습니다.",
  },
  checklist: "체크리스트",
};

export default fileViewer;
