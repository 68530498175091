export const formatDocumentType = (type) => {
  return type
    ?.replace(/_/g, " ") // Replace all underscores with spaces
    .split(" ") // Split into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter of each word
    .join(" "); // Rejoin into a single string
};

export function camelToNormalText(camelCaseString) {
  // Add a space before each uppercase letter and convert the string to lower case
  const spacedString = camelCaseString.replace(/([a-z])([A-Z])/g, "$1 $2");
  // Capitalize the first letter of each word
  const normalText = spacedString.replace(/\b\w/g, (char) => char.toUpperCase());
  return normalText;
}
