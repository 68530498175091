import React, { useEffect, useRef, useState } from "react";
import { LuCopy, LuCopyCheck } from "react-icons/lu";
import { notification } from "antd";
import { FaCircleCheck } from "react-icons/fa6";
import useFetchJson from "../../hooks/useFetchJson";

const ExtractLetterOfCreditResults = ({ splitterData, files, splitterFetched }) => {
  const { fetchJson } = useFetchJson();
  const [api, contextHolder] = notification.useNotification();
  const [copiedItem, setCopiedItem] = useState(null);
  const [letterOfCredit, setLetterOfCredit] = useState(null);
  const fetchedExtractDocumentIds = useRef(new Set());
  const [extractedData, setExtractedData] = useState({});

  useEffect(() => {
    const fetchExtractedData = async () => {
      const fetchPromises = files.map((file) => {
        if (!fetchedExtractDocumentIds.current.has(file.document_id)) {
          return fetchJson("extracted", file.archive_id, file.document_id, fetchedExtractDocumentIds, setExtractedData);
        }
        return Promise.resolve();
      });
      await Promise.all(fetchPromises);
    };
    if (splitterFetched) {
      fetchExtractedData();
    }

    return () => {};
  }, [files, fetchJson, extractedData, splitterFetched]);

  useEffect(() => {
    // Helper function to find the index of the document type containing "bol"
    function findIndexOfDocumentTypeWithBol(documents) {
      return documents.findIndex((document) => document.document_type.toLowerCase().includes("letter_of_credit"));
    }

    // Process each document ID in splitterData
    const processDocumentIds = async () => {
      const allDocumentIds = Object.keys(splitterData);
      let blIndex;
      let blDocId;
      for (const docId of allDocumentIds) {
        if (findIndexOfDocumentTypeWithBol(splitterData[docId]) > -1) {
          blIndex = findIndexOfDocumentTypeWithBol(splitterData[docId]);
          blDocId = docId;
        }
      }
      setLetterOfCredit(extractedData?.[blDocId]?.[blIndex]);
    };

    if (splitterData && extractedData) {
      processDocumentIds();
    }
  }, [splitterData, extractedData]);

  const openNotification = (value) => {
    api.info({
      icon: <FaCircleCheck className="text-blue-500 w-6 h-6 opacity-90" />,
      message: <p className="text-base font-semibold text-gray-700">{"Copied to clipboard"}</p>,
      description: <p className="text-sm text-gray-500 break-keep uppercase">{value}</p>,
      placement: "topRight",
    });
  };

  const handleCopy = (key, value) => {
    navigator.clipboard.writeText(value).then(() => {
      setCopiedItem(key);
      openNotification(value);
    });
  };

  const KeyValueDisplay = ({ label, value, keyName, handleCopy, copiedItem }) => {
    const renderValue = (value) => {
      if (typeof value === "boolean") {
        return value ? "Allowed" : "Not allowed";
      } else if (Array.isArray(value)) {
        if (keyName === "46A") {
          return value.length > 0 ? value.map((doc, index) => <div key={index}>+ {doc.fullInformation},</div>) : "-";
        }
        if (keyName === "47A") {
          return value.length > 0 ? value.map((doc, index) => <div key={index}>+ {doc},</div>) : "-";
        }
        return value.join(", ");
      } else if (typeof value === "object" && value !== null) {
        return Object.entries(value).map(([key, val], index) => (
          <div key={index}>
            <strong>{key}:</strong> {val}
          </div>
        ));
      }
      return value || "-";
    };
    return (
      <div className={`text-xs pr-3`}>
        <div className="flex w-full items-center">
          {keyName && (
            <div
              style={{ whiteSpace: "pre-wrap" }}
              className="px-2 w-9 h-full text-sky-600 font-medium text-xs flex-shrink-0"
            >
              {keyName}
            </div>
          )}

          <div
            className={`${
              keyName ? "w-36" : "w-[11.2rem]"
            } px-2 h-full text-sky-600 font-medium text-xs flex-shrink-0 flex items-center`}
          >
            {label}
          </div>
          <div className="w-full border-l h-full">
            <div className="text-gray-600 h-full w-full py-4 px-2">{renderValue(value)}</div>
          </div>
          <button
            className="w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-100 transition flex-shrink-0"
            onClick={() => handleCopy(keyName, value)}
          >
            {copiedItem === keyName ? (
              <LuCopyCheck className="w-4 h-4 text-blue-500" />
            ) : (
              <LuCopy className="w-4 h-4 text-gray-400" />
            )}
          </button>
        </div>
      </div>
    );
  };

  const lcFields = [
    {
      key: "40A",
      label: "Form of Documentary Credit",
      value: letterOfCredit?.["40A"],
      text: "Form of Documentary Credit",
    },
    { key: "20", label: "Documentary Credit Number", value: letterOfCredit?.["20"], text: "Documentary Credit Number" },
    { key: "40E", label: "Applicable Rules", value: letterOfCredit?.["40E"], text: "Applicable Rules" },
    { key: "31C", label: "Date of Issue", value: letterOfCredit?.["31C"], text: "Date of Issue" },
    { key: "50", label: "Applicant Name", value: letterOfCredit?.["50"]?.fullInformation, text: "Applicant" },
    { key: "59", label: "Beneficiary Name", value: letterOfCredit?.["59"]?.fullInformation, text: "Beneficiary" },
    { key: "32B", label: "Currency Code Amount", value: letterOfCredit?.["32B"], text: "Currency Code Amount" },
    {
      key: "39A",
      label: "Percentage Credit Amount Tolerance",
      value: letterOfCredit?.["39A"],
      text: "Percentage Credit Amount Tolerance",
    },
    { key: "41A", label: "Available With", value: letterOfCredit?.["41A"], text: "Available With" },
    { key: "41D", label: "Available By", value: letterOfCredit?.["41D"], text: "Available By" },
    { key: "42C", label: "Drafts At", value: letterOfCredit?.["42C"], text: "Drafts At" },
    { key: "42A", label: "Drawee Bank", value: letterOfCredit?.["42A"], text: "Drawee Bank" },
    { key: "43P", label: "Partial Shipments", value: letterOfCredit?.["43P"], text: "Partial Shipments" },
    { key: "43T", label: "Transhipment", value: letterOfCredit?.["43T"], text: "Transhipment" },
    { key: "44E", label: "Port of Loading", value: letterOfCredit?.["44E"], text: "Port of Loading" },
    { key: "44F", label: "Port of Discharge", value: letterOfCredit?.["44F"], text: "Port of Discharge" },
    {
      key: "44B",
      label: "Place of Final Destination",
      value: letterOfCredit?.["44B"],
      text: "Place of Final Destination",
    },
    { key: "44C", label: "Latest Date of Shipment", value: letterOfCredit?.["44C"], text: "Latest Date of Shipment" },
    { key: "44D", label: "Shipment Period", value: letterOfCredit?.["44D"], text: "Shipment Period" },
    { key: "71D", label: "Charges Details", value: letterOfCredit?.["71D"], text: "Charges Details" },
    { key: "49", label: "Confirmation Instructions", value: letterOfCredit?.["49"], text: "Confirmation Instructions" },
    {
      key: "46A",
      label: "Documents Required",
      value: letterOfCredit?.["46A"],
      text: "Documents Required",
    },
    { key: "47A", label: "Additional Conditions", value: letterOfCredit?.["47A"], text: "Additional Conditions" },
  ];

  return (
    <div className="relative flex w-full">
      <div className="w-full pb-12">
        <div className="font-medium text-sky-600 uppercase text-xs py-2 border-b px-2 bg-sky-50">
          LETTER OF CREDIT INFORMATION
        </div>

        <div className="divide-y w-full rounded-lg overflow-hidden">
          {lcFields.map((field) => (
            <KeyValueDisplay
              key={field.key}
              label={`${field.label}`}
              value={field.value}
              keyName={field.key}
              handleCopy={handleCopy}
              copiedItem={copiedItem}
            />
          ))}

          {letterOfCredit?.["45A"] &&
            letterOfCredit?.["45A"].map((good, index) => (
              <div key={index}>
                <div className="font-medium text-sky-600 uppercase text-xs py-2 border-b px-2 bg-sky-50">
                  45A. DESCRIPTION OF GOODS: COMMODITY ({good.commodity?.name || `Commodity ${index + 1}`})
                </div>
                <div key={index} className="bg-white text-xs rounded-lg divide-y">
                  <KeyValueDisplay
                    key={"45A-1"}
                    label="Commodity Name"
                    value={good.commodity?.name}
                    handleCopy={handleCopy}
                    copiedItem={copiedItem}
                  />
                  <KeyValueDisplay
                    key={"45A-2"}
                    label="Origin"
                    value={good.commodity?.origin}
                    handleCopy={handleCopy}
                    copiedItem={copiedItem}
                  />
                  <KeyValueDisplay
                    key={"45A-3"}
                    label="Crop Year"
                    value={good.commodity?.cropYear}
                    handleCopy={handleCopy}
                    copiedItem={copiedItem}
                  />
                  <KeyValueDisplay
                    key={"45A-4"}
                    label="HS Code"
                    value={good.commodity?.hsCode}
                    handleCopy={handleCopy}
                    copiedItem={copiedItem}
                  />
                  <KeyValueDisplay
                    key={"45A-5"}
                    label="Quantity"
                    value={good.quantity?.value}
                    handleCopy={handleCopy}
                    copiedItem={copiedItem}
                  />
                  <KeyValueDisplay
                    key={"45A-6"}
                    label="Quantity Unit"
                    value={good.quantity?.unit}
                    handleCopy={handleCopy}
                    copiedItem={copiedItem}
                  />
                  <KeyValueDisplay
                    key={"45A-7"}
                    label="Tolerance +"
                    value={good.quantity?.tolerance?.plus}
                    handleCopy={handleCopy}
                    copiedItem={copiedItem}
                  />
                  <KeyValueDisplay
                    key={"45A-8"}
                    label="Tolerance -"
                    value={good.quantity?.tolerance?.minus}
                    handleCopy={handleCopy}
                    copiedItem={copiedItem}
                  />
                  <KeyValueDisplay
                    key={"45A-9"}
                    label="Tolerance Unit"
                    value={good.quantity?.tolerance?.unit}
                    handleCopy={handleCopy}
                    copiedItem={copiedItem}
                  />
                  <KeyValueDisplay
                    key={"45A-10"}
                    label="Price Full Information"
                    value={good.price?.fullInformation}
                    handleCopy={handleCopy}
                    copiedItem={copiedItem}
                  />
                  <KeyValueDisplay
                    key={"45A-11"}
                    label="Price Value"
                    value={good.price?.value}
                    handleCopy={handleCopy}
                    copiedItem={copiedItem}
                  />
                  <KeyValueDisplay
                    key={"45A-12"}
                    label="Price Unit"
                    value={good.price?.unit}
                    handleCopy={handleCopy}
                    copiedItem={copiedItem}
                  />
                  <KeyValueDisplay
                    key={"45A-13"}
                    label="Incoterm"
                    value={good.incoterm}
                    handleCopy={handleCopy}
                    copiedItem={copiedItem}
                  />
                  {typeof good.quality === "object" && good.quality !== null ? (
                    Object.entries(good.quality).map(([subKey, subValue]) => (
                      <KeyValueDisplay
                        key={subKey}
                        label={`Quality: ${subKey}`}
                        value={subValue}
                        handleCopy={handleCopy}
                        copiedItem={copiedItem}
                      />
                    ))
                  ) : (
                    <KeyValueDisplay
                      key={"45A"}
                      label="Quality"
                      value={good.quality}
                      handleCopy={handleCopy}
                      copiedItem={copiedItem}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
      {contextHolder}
    </div>
  );
};

export default ExtractLetterOfCreditResults;
