const landing = {
  mode: "ko",
  demo: "무료로 시작하기",
  inquire: "Product Demo",
  getStarted: "지금 시작하기",
  title1: "무역금융 자동화를 위한",
  title2: "선적서류 검토 솔루션",
  subtitle: "기준 서류(BL, LC)를 바탕으로 선적서류를 검사합니다.",
  dropzone: "20MB 이하의 pdf 파일",
  dropzoneTitle: "서류 업로드",
  dropzoneButton: "파일 선택",
  actionTitle: "제공 솔루션",
  action1: "하자 검사 솔루션",
  action2: "초안 작성 솔루션",
  aboutBody1: "트레독스는 국제 무역 금융을 혁신하는 최전선에 있습니다.",
  aboutBody2:
    "첨단 AI 기술을 활용하여, 신용장, 선하증권, 원산지 증명서와 같은 선적서류의 자동화를 전문으로 합니다. AI 기반의 불일치 검사 기능을 갖춘 우리의 솔루션은 정확성과 효율성을 보장하며, 무역 회사와 은행들의 위험을 최소화하고 이익을 극대화합니다.",
  aboutBody3: "트레독스와 함께하는 스마트 무역 금융 솔루션으로 무리 없는 선적서류 처리 경험을 하세요.",
  sol1title1: "여러 서류를 한 번에 하자 검사를 하여 시간을 절약하고 실수를 줄입니다.",
  sol1subtitle: "B/L, C/O, 패킹리스트, 인보이스, 제3자 증명서를 포함한 모든 선적서류의 내용을 추출하고 비교합니다.",
  sol1body: `["서류 내용을 확대할 수 있어 쉽게 볼 수 있습니다.","서류 위에 메모를 추가하고 다운로드할 수 있습니다.","누락 사항을 식별 및 강조합니다.","서류마다 체크리스트를 생성해 검사를 보조합니다."]`,
  learnMore: "자세히 보기",
  sol2title1: "서류 세트로 올려도 AI가 자동으로 각 문서를 인식하고 BL, CO, 패킹 등으로 분류합니다.",
  sol2subtitle: "AI 분류기를 사용해 검사 완료된 서류를 폴더별 / 서류 종류별로 찾기 쉽게 정리합니다.",
  sol2body: `["맞춤법 체크", "문장부호/구두점 체크", "누락 체크", "문서 작성 지시 체크"]`,
  sol3title1: "팀원간의 협업으로",
  sol3title2: "소통 및 서무 업무",
  sol3title3: "의 효율화",
  sol3subtitle: "팀원의 접근 권한을 설정해 실시간 AI 분석과 공유 작업 공간을 통한 팀 협업을 용이하게 합니다.",
  sol3body1: "1. 공유 시스템에 선적서류 업로드.",
  sol3body2: "2. 팀 검토를 위한 실시간 AI 분석.",
  sol3body3: "3. 협업을 통한 불일치 사항 식별.",
  sol3body4: "4. 모든 팀원이 접근 가능한 종합 보고서 생성.",
  security: "보안",
  securityTitle1: "TradDocs와 함께",
  securityTitle2: "안전한 고객 데이터",
  securitySubtitle: "중요한 데이터를 다루는 선적서류, 선택의 기준은 보안입니다.",
  login: "로그인",
  nav: {
    service: "서비스",
    service_1: "선적서류 자동 하자검사",
    service_2: "수입식품정보마루 검사",
    company: "회사소개",
    contact: "문의하기",
    pricing: "가격정책",
    blog: "블로그",
  },
  partners: {
    title1: "TradDocs는",
    title2: "세계 최대",
    title3: "커피 트레이딩 업체와 무역팀들이 신뢰합니다",
  },
};

export default landing;
