import React, { useState } from "react";
import { FaCaretDown, FaCheck } from "react-icons/fa";
import { formatDocumentType } from "../../utils/formatDocumentType";

const CrossCheckNoIssueTable = ({ currentComparedB, setHighlightedIndicies, currentDocumentType, type }) => {
  const [isCollapsed, setCollapsed] = useState(true);
  const noIssueArray = currentComparedB.filter((discrepancy) => !discrepancy.discrepancy);

  function renderValue(value) {
    if (Array.isArray(value)) {
      return value.join(", "); // or return value.map((item, index) => <li key={index}>{item}</li>);
    } else if (typeof value === "object" && value !== null) {
      return JSON.stringify(value, null, 2);
    } else if (typeof value === "string") {
      return value;
    } else {
      return "N/A";
    }
  }

  const headerCss = `rounded transition flex items-center space-x-1 transition py-1.5 px-2 w-full`;
  const headerTitleCss = `text-black text-sm font-medium`;
  const checkIconCss = "text-green-600 w-5 h-3 flex-shrink-0";
  const arrowIconCss = `${isCollapsed ? "-rotate-90" : ""} text-gray-500 transform transition`;
  const tableContainerCss = "border rounded overflow-hidden mt-2 mb-6";
  const tableCss = "w-full text-sm text-left overflow-hidden divide-y";
  const tableBodyCss = "divide-y text-xs";

  const TableHeader = () => (
    <thead className="text-gray-600 bg-gray-100 text-xs text-left">
      <tr>
        <th className="px-2 py-3 font-medium border-r">Description</th>
        <th className="px-2 py-3 font-medium border-r">
          {currentDocumentType.includes("bol")
            ? "Bill of Lading"
            : currentDocumentType.includes("coo_general")
            ? "Certificate of Origin"
            : currentDocumentType.includes("coo_ico")
            ? "Certificate of Origin (ICO)"
            : currentDocumentType.includes("coo_ldc")
            ? "Certificate of Origin (LDC)"
            : currentDocumentType.includes("coo_free_trade")
            ? "Certificate of Origin (FTA)"
            : currentDocumentType.includes("coo_gsp")
            ? "Certificate of Origin (GSP)"
            : formatDocumentType(currentDocumentType)}
        </th>
        <th className="px-2 py-3 font-medium bg-gray-200">
          {type === "bol"
            ? "Bill of Lading"
            : type === "invoice"
            ? "Commercial Invoice"
            : type === "contract"
            ? "Contract"
            : type === "di"
            ? "Document Instruction"
            : "Letter of Credit"}
        </th>
      </tr>
    </thead>
  );

  if (noIssueArray.length === 0)
    return (
      <div className="w-full">
        <button onClick={() => setCollapsed(!isCollapsed)} className={headerCss}>
          <div className="flex space-x-2 items-center">
            <FaCaretDown className={arrowIconCss} />
            <FaCheck className={checkIconCss} />
            <p className={headerTitleCss}>Check completed</p>
          </div>
        </button>
        {!isCollapsed && (
          <div className="w-full h-8 text-sm text-left text-gray-400 flex items-center pl-10 rounded overflow-hidden divide-y mb-6 mt-2">
            No Issues were found.
          </div>
        )}
      </div>
    );
  else
    return (
      <div className="">
        <button onClick={() => setCollapsed(!isCollapsed)} className={headerCss}>
          <div className="flex space-x-2 items-center">
            <FaCaretDown className={arrowIconCss} />
            <FaCheck className={checkIconCss} />
            <p className={headerTitleCss}>Check completed ({noIssueArray.length})</p>
          </div>
        </button>
        {!isCollapsed && (
          <div className="pl-7 pr-2 max-w-screen-xl">
            <div className={tableContainerCss}>
              <table style={{ tableLayout: "fixed" }} className={tableCss}>
                <TableHeader />
                <tbody className={tableBodyCss}>
                  {noIssueArray.map((discrepancy, i) => {
                    return (
                      <tr
                        key={i}
                        onClick={() => setHighlightedIndicies([discrepancy.id])}
                        className={`divide-x transition hover:bg-sky-100 cursor-pointer`}
                      >
                        <td className="py-3 px-3 relative">
                          <div>
                            <p className="text-xs mb-1 font-medium text-green-600">{discrepancy.item}</p>
                            <p className="text-gray-500 text-xs">
                              {discrepancy.discrepancy_details || "No issue detected."}
                            </p>
                          </div>
                        </td>
                        <td className="px-3 py-3 w-full">
                          <div className="text-gray-600">{renderValue(discrepancy.target_detail)}</div>
                        </td>
                        <td className="px-3 py-3 w-full bg-[#00000005]">
                          <div className="text-gray-500">{renderValue(discrepancy.ref_detail)}</div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    );
};

export default CrossCheckNoIssueTable;
