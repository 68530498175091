const faq = {
  mode: "ko",
  subtitle: "추가로 궁금하신게 있으신가요? ",
  action: "문의하기",
  q1: "결제 방법은 어떻게 되나요?",
  a1: "고객님의 사용 계획에 따라 월별 또는 연간 요금제로 결제가 가능합니다. 신용카드 또는 은행 이체를 통해 결제할 수 있으며, 자세한 결제 방법은 웹사이트에서 확인하실 수 있습니다.",
  q2: "매달 팀에 사람이 추가되면 어떻게 되나요?",
  a2: "팀에 새로운 멤버가 추가될 때마다, 해당 멤버에 대한 비용이 청구됩니다. 팀 멤버 관리 섹션에서 언제든지 멤버를 추가하거나 제거할 수 있습니다.",
  q3: "트레독스 엔터프라이즈로 변경하는 방법은 무엇인가요?",
  a3: "엔터프라이즈 계획으로의 변경은 고객 지원팀에 문의하시면 됩니다. 엔터프라이즈 계획은 더 많은 기능과 향상된 지원을 제공합니다.",
  q4: "트레독스는 학생이나 비영리 단체에 할인을 제공하나요?",
  a4: "네, 트레독스는 교육 목적이나 비영리 단체를 위해 특별 할인을 제공합니다. 할인에 대한 자격 요건은 웹사이트를 통해 확인하실 수 있으며, 신청 방법에 대한 자세한 정보도 제공합니다.",
  q5: "트레독스를 사용하려면 어떤 시스템 요구사항이 필요한가요?",
  a5: "트레독스는 대부분의 최신 웹 브라우저에서 작동합니다. 최적의 성능을 위해 최신 버전의 Chrome, Firefox, 또는 Safari를 사용하는 것이 좋습니다. 추가 소프트웨어 설치는 필요하지 않습니다.",
  q6: "데이터 보안은 어떻게 보장되나요?",
  a6: "고객님의 데이터 보안은 저희에게 최우선 과제입니다. 트레독스는 업계 표준의 암호화 프로토콜을 사용하여 데이터를 안전하게 보호합니다.",
  q7: "트레독스에서 지원하는 문서 형식은 무엇인가요?",
  a7: "트레독스는 PDF, Excel, Word 등 다양한 형식의 무역 관련 문서를 지원합니다. 특정 파일 형식에 대한 지원 여부는 웹사이트에서 확인하실 수 있습니다.",
  q8: "고객 지원은 어떻게 이루어지나요?",
  a8: "고객 지원은 이메일, 전화, 라이브 채팅을 통해 제공됩니다. 운영 시간은 평일 오전 9시부터 오후 6시까지이며, 빠른 대응을 위해 노력하고 있습니다.",
  q9: "트레독스의 업데이트는 어떻게 이루어지나요?",
  a9: "트레독스는 정기적으로 시스템을 업데이트하여 새로운 기능을 추가하고 성능을 개선합니다. 모든 업데이트는 자동으로 적용되며, 사용에 불편함이 없도록 최선을 다하고 있습니다.",
};

export default faq;
