const privacy = {
  mode: "en",
  title: "Privacy Policy",
  lastUpdated: "20 January 2023",
  introduction:
    "TradDocs, Inc. (hereinafter referred to as 'TradDocs', 'Company', 'we', or 'us') values the privacy of visitors and customers. We actively protect and manage the information collected through TradDocs.com and its affiliated websites ('Sites') and our SaaS products, web design software, tools, and related services.",

  personalInformationDefinition:
    "In this policy, 'personal information' refers to information that can directly or indirectly identify an individual. TradDocs does not sell, rent, or lease personal information to third parties. 'Usage data' refers to encrypted or anonymized information that does not include personally identifiable information and helps understand service usage trends.",

  collectionUseSharing:
    "During service registration, we may request personal information such as name, email address, and payment information. All information provided during site usage may be used for the operation, improvement, and personalization of TradDocs services. Additionally, we may share information with third parties for service improvement and marketing purposes.",

  thirdPartyDataSharing:
    "TradDocs may collect personal information from third parties, but only when it is essential for service provision. Through collaboration with third-party service providers, we enhance service quality and improve user experience.",

  userRights:
    "Users have the right to access, modify, and delete their personal information at any time. TradDocs respects such requests and complies with applicable legal requirements.",

  dataSecurity:
    "TradDocs uses industry-standard security measures to ensure the safety and security of personal information. However, no transmission over the internet can be guaranteed to be 100% secure, and the responsibility for the security of transmitted data lies with the user.",

  internationalTransfers:
    "TradDocs operates globally and complies with data protection laws of various countries. International data transfers are conducted in a manner that complies with the laws of the respective countries.",

  changesToPolicy:
    "This policy may be modified as needed. In the event of significant changes, we will notify through the website or email. If you do not agree with the policy changes, you should discontinue using the service.",

  contactUs:
    "If you have any questions about the privacy policy or TradDocs' handling of personal information, please contact us at support@tradDocs.com.",
  
  seo: {
    title: "Privacy Policy | TradDocs",
    description: "Privacy policy for TradDocs",
    keywords: "service, privacy, TradDocs",
  },
};

export default privacy;
