const pricing = {
  mode: "ko",
  title: "서비스 및 요금 안내",
  subtitle: "예산과 비즈니스 목적에 따라서 필요한 제품만 선택해 사용해 보세요.",
  sandbox: {
    title: "샌드박스",
    subtitle: "지금 무료로 시작하기",
    note: "신용카드 필요 없음",
    footerNote: "신용카드 필요 없음",
    featureHeader: "지금 무료로 시작 가능",
    // button: "지금 무료로 시작",
    button: "문의하기",
  },
  individual: {
    title: "전문가",
    subtitle: "문의하세요",
    note: "연간 구독으로 20% 할인",
    footerNote: "14일 무료체험 후 신용카드 결제",
    featureHeader: "샌드박스 서비스 포함 추가로...",
    // button: "14일 무료체험 시작",
    button: "문의하기",
  },
  professional: {
    title: "팀",
    subtitle: "문의하세요",
    note: "연간 구독으로 20% 할인",
    footerNote: "신용카드 결제",
    featureHeader: "전문가 서비스 포함 추가로...",
    button: "문의하기",
  },
  enterprise: {
    title: "기업",
    subtitle: "사용자 맞춤 가격",
    note: "연간 구독만 가능",
    footerNote: "송장으로 결제",
    featureHeader: "팀 서비스 포함 추가로...",
    button: "문의하기",
  },
  sandbox_feature: {
    1: "월 3건의 하자 검사",
    2: "CAD 선적서류 가능",
    3: "B/L, C/O, P/L",
    4: "서류 저장 공간 1GB 지원",
    5: "대시보드 제공",
  },
  individual_feature: {
    1: "월 12건의 하자 검사",
    2: "D/A, D/P 가능",
    3: "B/L, C/O, P/L (C/I 양식 제출 시)",
    4: "서류 저장 공간 5GB 지원",
    5: "내보내기 지원 (PDF, CSV, JSON)",
    6: "청구지원",
  },
  professional_feature: {
    1: "월 40건의 하자 검사",
    2: "L/C 가능",
    3: "B/L, C/I, C/O, P/L, 제3자 증명서를 포함한 모든 서류",
    4: "서류 저장 공간 20GB 지원",
    5: "실시간 협업 기능",
    6: "팀원 초대",
    7: "우선 지원",
  },
  enterprise_feature: {
    1: "무제한 하자 검사",
    2: "L/C 가능",
    3: "B/L, C/I, C/O, P/L, 제3자 증명서를 포함한 모든 서류",
    4: "무제한 서류 저장 공간 지원",
    5: "부가 서비스",
  },
  seo: {
    title: "가격 정책 | 트레독스 TradDocs",
    description: "트레독스 서비스에 대한 저렴한 가격 정책",
    keywords: "가격, 정책, 트레독스, 서비스",
  },
};

export default pricing;
