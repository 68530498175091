const seo = {
  title: "트레독스 - AI 선적서류 자동화 | 무역 금융 솔루션",
  description:
    "국제 선적서류 자동화를 선도하는 트레독스. 신용장 및 선하증권을 포함한 문서의 정확성과 효율성을 높여 글로벌 무역을 강화합니다.",

  keywords:
    "선적서류 자동화, AI 무역 금융, 신용장 자동화, 선하증권 자동화, 무역 금융 솔루션, 안전한 문서 처리, 국제 무역 AI, 효율적인 선적서류화, 무역 금융 플랫폼, 문서 불일치 검사",
  ogTitle: "트레독스 - AI 선적서류 자동화 | 무역 금융 솔루션",
  ogImage: "https://s3.traddocs.com/new_traddocs_og.png",
};

export default seo;
