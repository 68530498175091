import { Modal, Spin, notification } from "antd";
import React, { useState } from "react";
import axios from "../../utils/authAxios.js";
import { uploadDocument } from "../../utils/uploadDocument.js";
import { FaCircleCheck } from "react-icons/fa6";
import DropComponentSmall from "../dropComponent/DropComponenetSmall.jsx";

const AddContractModal = ({
  setOpen,
  open,
  archiveId,
  getArchive,
  getFiles,
  setExtractFetched,
  setSplitterFetched,
  setComparedFetched,
  setInitiallyFetched,
}) => {
  const [isDocLoading, setDocLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [selectedSalesContract, setSelectedSalesContract] = useState([]);
  const [selectedPurchaseContract, setSelectedPurchaseContract] = useState([]);
  const [hasError] = useState(false);

  const reset = () => {
    window.location.reload();
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setDocLoading(false);
    setSelectedSalesContract([]);
    setSelectedPurchaseContract([]);
    setOpen(false);
  };

  const buttonDisabled = (selectedPurchaseContract.length === 0 && selectedSalesContract.length === 0) || isDocLoading;

  // END:MODAL FUNCTIONS ======
  // START:UPLOAD FUNCTIONS ======
  const openNotification = () => {
    api.info({
      icon: <FaCircleCheck className="text-blue-500 w-6 h-6 opacity-90" />,
      message: <p className="text-base font-semibold text-gray-700">{"Folder Created Successfully"}</p>,
      description: (
        <p className="text-sm text-gray-500 break-keep">
          {"The documents are being uploaded and processed. This may take a few moments."}
        </p>
      ),
      placement: "top",
    });
  };

  const addContract = () => {
    const allSelectedItems = [...selectedSalesContract, ...selectedPurchaseContract];

    const updatedFilesResponse = allSelectedItems.map((item) => ({
      document_name:
        item.name.substring(0, item.name.lastIndexOf(".")) +
        item.name.substring(item.name.lastIndexOf("."), item.name.length).toLowerCase(),
    }));

    if (archiveId) {
      if (allSelectedItems.length > 0) {
        axios
          .post(`/v1/documents`, { archive_id: archiveId, documents: updatedFilesResponse })
          .then((secondResponse) => {
            const documentPaths = secondResponse.data.map((doc, index) => {
              const extension = allSelectedItems[index].name.split(".").pop().toLowerCase();
              return `${doc.document_id}.${extension}`;
            });

            const uploadPromises = secondResponse.data.map((doc, index) =>
              uploadDocument([allSelectedItems[index]], archiveId, doc.document_id)
            );

            Promise.all(uploadPromises).then((filesResponses) => {
              if (filesResponses.length > 0) {
                // Check and post contracts
                const contracts = [];
                let salesIndex = 0;
                let purchaseIndex = 0;

                secondResponse.data.forEach((doc, index) => {
                  const itemName = allSelectedItems[index].name;

                  if (
                    salesIndex < selectedSalesContract.length &&
                    selectedSalesContract[salesIndex].name === itemName
                  ) {
                    contracts.push({ document_id: doc.document_id, contract_type: "sales" });
                    salesIndex++;
                  } else if (
                    purchaseIndex < selectedPurchaseContract.length &&
                    selectedPurchaseContract[purchaseIndex].name === itemName
                  ) {
                    contracts.push({ document_id: doc.document_id, contract_type: "purchase" });
                    purchaseIndex++;
                  }
                });

                const postContractsPromise =
                  contracts.length > 0
                    ? axios.post(`/v1/documents/contracts`, { archive_id: archiveId, contracts })
                    : Promise.resolve();

                postContractsPromise
                  .then(() => {
                    return axios.post(`/v1/documents/run`, {
                      archive_id: archiveId,
                      document_paths: documentPaths,
                    });
                  })
                  .then(() => {
                    getArchive(archiveId);
                    getFiles(archiveId);
                    setExtractFetched(false);
                    setSplitterFetched(false);
                    setComparedFetched(false);
                    setInitiallyFetched(false);
                    openNotification();
                    handleCancel();
                  })
                  .finally(() => setDocLoading(false))
                  .catch((error) => {
                    console.error("Error in the process: ", error);
                    setDocLoading(false);
                  });
              }
            });
          });
      } else {
        openNotification(); // Show a notification of completion
      }
    }
  };

  const uploadAndRequest = () => {
    setDocLoading(true); // Indicate the start of the upload process
    addContract();
  };
  // END:UPLOAD FUNCTIONS======

  return (
    <>
      <Modal
        open={open}
        title={
          <div className="font-WantedSans">
            <p className="text-lg">Upload Contract</p>
          </div>
        }
        closeIcon={null}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={[
          <button
            key={"close"}
            className={`border text-gray-600 px-4 py-2 h-10 rounded-lg transition font-medium font-WantedSans`}
            onClick={handleCancel}
          >
            Close
          </button>,
          <button
            key={"start"}
            className={`${
              buttonDisabled ? "bg-gray-300" : "bg-blue-600"
            } text-white px-4 py-2 h-10 rounded-lg transition font-medium font-WantedSans ml-3`}
            disabled={buttonDisabled}
            onClick={() => (buttonDisabled ? null : uploadAndRequest())}
          >
            Add Contract
          </button>,
        ]}
      >
        <div className="w-full h-full mt-4 mb-4 font-WantedSans">
          {hasError ? (
            <div className="py-8 bg-white w-full rounded-3xl bg-opacity-70 shadow-lg p-6 flex flex-col space-y-6 items-center justify-center h-96">
              <div className="flex flex-col items-center space-y-1 tracking-tight">
                <p className="text-gray-400">Couldn't read the files.</p>
                <p className="text-gray-400">Please try again later.</p>
              </div>
              <button
                onClick={reset}
                className="flex items-center space-x-2 text-blue-500 py-2 px-4 rounded-xl bg-gray-100 hover:text-blue-700 font-medium hover:bg-gray-200 transition"
              >
                <p className="text-sm flex-shrink-0">Retry</p>
              </button>
            </div>
          ) : isDocLoading ? (
            <div className="h-64 bg-white w-full rounded-3xl bg-opacity-70 p-6 flex flex-col space-y-6 items-center justify-center">
              <div className="flex flex-col items-center space-y-1 tracking-tight">
                <Spin size="large" className="pb-4" />
                <p className="text-gray-400">Please wait while we upload your document.</p>
              </div>
            </div>
          ) : (
            <div className="space-y-6 pb-6">
              <div className="bg-white rounded w-full space-y-2">
                <div className="flex space-x-2">
                  <DropComponentSmall
                    title={`Sales Contract`}
                    selectedFiles={selectedSalesContract}
                    setSelectedFiles={setSelectedSalesContract}
                    multiple={false}
                  />
                  <DropComponentSmall
                    title={`Purchase Contract`}
                    selectedFiles={selectedPurchaseContract}
                    setSelectedFiles={setSelectedPurchaseContract}
                    multiple={false}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {contextHolder}
      </Modal>
    </>
  );
};

export default AddContractModal;
