const landing = {
  mode: "en",
  demo: "Sign up for free",
  inquire: "Product Demo",
  getStarted: "Get Started",
  title1: "Check Trade Documents",
  title2: "with a single upload",
  subtitle: "TradDocs lets you quickly find and fix errors in your shipping documents.",
  dropzone: "PDF files up to 20MB",
  dropzoneTitle: "Drop your document here",
  dropzoneButton: "Select Files",
  actionTitle: "services",
  action1: "Discrepancy Inspection",
  action2: "Drafting Solution",
  aboutBody1: "TradDocs is at the forefront of revolutionizing international trade finance.",
  aboutBody2:
    "Leveraging advanced AI technology, we specialize in automating trade documents like Letters of Credit, Bills of Lading, and Certificates of Origin. Our solutions, equipped with AI-powered discrepancy inspection, ensure accuracy and efficiency, minimizing risks and maximizing gains for trade companies and banks.",
  aboutBody3:
    "Experience seamless trade document processing with TradDocs, your partner in smart trade finance solutions.",
  sol1title1: "Automate your document check",
  sol1subtitle:
    "We extract the contents of all shipping documents, including B/L, C/I, C/O, P/L, and third-party certificates, using AI.",
  sol1body: `["Effortlessly spot information discrepancies.", "Quickly pinpoint missing documents and dates.", "Enhance teamflow with shared notes & alerts.", "Automatically meet regulatory standards.", "Receive real-time document updates."]`,
  learnMore: "Learn More",
  sol2title1: "Upload Documents Individually or in Bulk",
  sol2subtitle:
    "Efficiently manage your documents with TradDocs' AI-powered classifier. Organize your files effortlessly, whether uploaded one at a time or in large batches. Our system automatically sorts documents into appropriate folders and types for easy access.",
  sol2body: `["Automatically sorts documents by type and relevance.", "Split large batches into individual sections with AI precision.", "Extract and tag key information for easy search.", "Customize processing and organization preferences post-upload."]`,
  sol3title1: "Efficiency through",
  sol3title2: "Team Collaboration",
  sol3title3: "",
  sol3subtitle:
    "Set team members' access permissions to facilitate team collaboration through real-time AI analysis and shared workspace.",
  sol3body1: "1. Upload trade documents to the shared system.",
  sol3body2: "2. Real-time AI analysis for team review.",
  sol3body3: "3. Identification of discrepancies through collaboration.",
  sol3body4: "4. Comprehensive reports accessible to all team members.",
  security: "Security",
  securityTitle1: "Secure Customer Data",
  securityTitle2: "With TradDocs",
  securitySubtitle: "When dealing with important data in shipping documents, security is the priority.",
  login: "Log in",
  nav: {
    service: "Service",
    service_1: "Auto discrepancy check",
    service_2: "Impfood check (KOR)",
    company: "Why TradDocs",
    contact: "Contact",
    pricing: "Pricing",
    blog: "Blog",
  },
  partners: {
    title1: "Trusted by",
    title2: "world largest",
    title3: "coffee exporters and trading teams",
  },
};

export default landing;
