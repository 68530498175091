import React from "react";
import { useTranslation } from "react-i18next";
import { TbHexagonNumber1, TbHexagonNumber2 } from "react-icons/tb";
import { Link } from "react-router-dom";

const SolutionIntro = () => {
  const { t } = useTranslation("landing");

  const ExplanationCard = ({ icon, title, subtitle, body }) => (
    <div className="w-full text-white">
      <div className="mb-6 w-10 h-10">{icon}</div>
      <div className="text-xl md:text-2xl font-medium break-keep leading-tight space-y-2">
        <p>{title}</p>
      </div>
      <p className="mt-6 break-keep text-gray-400">{subtitle}</p>

      {body && (
        <>
          <div className="h-px bg-gray-500 w-full max-w-md mt-8" />
          <div className="mt-8 space-y-4 text-base">
            {JSON.parse(body)?.map((item, index) => (
              <p className="break-keep text-gray-white" key={index}>
                • {item}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );

  const QuotationCard = ({ image, logo, quote, name, title, height }) => (
    <div className="w-full p-6 md:p-8 flex flex-col items-center">
      <div className="mb-6 h-12 flex items-center justify-center">
        <img src={logo} draggable={false} className={`${height} w-full object-contain`} alt="" />
      </div>

      <p className="mb-12 break-keep max-w-lg w-full text-center text-lg">"{quote}"</p>

      <div className="mb-6 flex space-x-3 items-center">
        <img src={image} draggable={false} className="object-cover rounded-lg w-10 h-12" alt="" />
        <div className="text-xs space-y-2 flex flex-col">
          <p>{name}</p>
          <p className="text-gray-500">{title}</p>
        </div>
      </div>
    </div>
  );

  const Header = ({ label, subtitle, title }) => (
    <div className="flex flex-col items-center">
      <div className="font-medium w-full justify-center flex text-blue-600 mb-6">{label}</div>
      <h3 style={{ whiteSpace: "pre-wrap" }} className="text-4xl font-semibold text-center leading-normal">
        {title}
      </h3>
      <h4 className="mt-4 mb-8 break-keep text-base max-w-lg text-gray-500 leading-relaxed text-center w-full">
        {subtitle}
      </h4>
      <Link to="/auth/signup">
        <button className="flex w-auto items-center justify-center space-x-3 h-9 px-3 md:px-5 rounded-full shadow md:text-base text-white bg-blue-500 transition font-medium hover:brightness-125 mb-16">
          <p>Start now for free</p>
        </button>
      </Link>
    </div>
  );

  return (
    <div className="flex w-full items-center justify-center flex-col md:pt-24 py-16 text-gray-700 bg-gradient-to-b from-white to-sky-50 px-4">
      <Header
        label="TradDocs AI Solutions"
        title="Automate Your Document Verification"
        subtitle="Save time with AI-powered checks for 20+ different shipping documents. No manual work required."
      />
      <div className="max-w-screen-xl w-full py-14 relative mb-24 flex items-center justfiy-center group">
        <div
          style={{
            backgroundImage: `url(https://s3.traddocs.com/landing/background3.png)`,
            backgroundSize: "contain", // Ensures the image is contained within the div
            backgroundPosition: "center", // Centers the background image
            backgroundRepeat: "no-repeat", // Prevents the image from repeating
          }}
          className="absolute w-full h-full bg-no-repeat bg-contain bg-center z-10 opacity-40 group-hover:opacity-80 transition"
        />
        <div className="flex lg:flex-row flex-col items-center justify-between w-full z-50 gap-4 px-2">
          <div className="flex flex-col gap-4 w-full items-center lg:items-start">
            <div
              className={`hover:bg-gray-700 hover:text-white transition w-full md:w-[27rem] h-52 bg-gray-50 border rounded-lg bg-opacity-90 p-4 flex items-center flex-col justify-center text-center`}
            >
              <h3 className="text-xl font-semibold">Automatic Contract Extraction</h3>
              <p className="text-gray-400 mt-2">Effortlessly extract key details from any contract</p>
            </div>
            <div
              className={`hover:bg-gray-700 hover:text-white transition w-full md:w-[27rem] h-52 bg-gray-50 border rounded-lg bg-opacity-90 p-4 flex items-center flex-col justify-center text-center`}
            >
              <h3 className="text-xl font-semibold">Contract - BL Verification</h3>
              <p className="text-gray-400 mt-2">Ensure alignment between contract terms and Bill of Lading details.</p>
            </div>
            <div
              className={`hover:bg-gray-700 hover:text-white transition w-full md:w-[27rem] h-52 bg-gray-50 border rounded-lg bg-opacity-90 p-4 flex items-center flex-col justify-center text-center`}
            >
              <h3 className="text-xl font-semibold">Comprehensive BL Audit</h3>
              <p className="text-gray-400 mt-2">
                Validate packing lists, certificates, and other documents against the Bill of Lading.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-4 w-full items-center lg:items-end">
            <div
              className={`hover:bg-gray-700 hover:text-white transition w-full md:w-[27rem] h-52 bg-gray-50 border rounded-lg bg-opacity-90 p-4 flex items-center flex-col justify-center text-center`}
            >
              <h3 className="text-xl font-semibold">Invoice Consistency Check</h3>
              <p className="text-gray-400 mt-2">
                Cross-check commercial invoices with related documentation for accuracy.
              </p>
            </div>
            <div
              className={`hover:bg-gray-700 hover:text-white transition w-full md:w-[27rem] h-52 bg-gray-50 border rounded-lg bg-opacity-90 p-4 flex items-center flex-col justify-center text-center`}
            >
              <h3 className="text-xl font-semibold">Impfood Verification</h3>
              <p className="text-gray-400 mt-2">Confirm shipper and packing place compliance with Impfood standards.</p>
            </div>
          </div>
        </div>
      </div>

      <Header
        label={"TradDocs services"}
        title={`Streamline Your Trade Document\nWorkflow with TradDocs`}
        subtitle={
          "Upload multiple documents simultaneously and let our AI extract and analyze critical data from all types of shipping documents."
        }
      />
      <div className="space-y-12">
        <div className="max-w-screen-xl w-full flex flex-col-reverse md:flex-row bg-gray-700 py-14 rounded-lg md:space-x-4">
          <div className="w-full md:w-2/5 p-6 md:p-8">
            <ExplanationCard
              icon={<TbHexagonNumber1 className="w-full h-full text-white" />}
              title={t("sol1title1")}
              subtitle={t("sol1subtitle")}
              body={t("sol1body")}
            />
          </div>
          <div className="pl-4 md:pl-0 w-full md:w-3/5 overflow-hidden">
            <img
              src="https://s3.traddocs.com/landing/sample1.png"
              className="rounded-l-lg"
              alt=""
            />
          </div>
        </div>
        <div className="max-w-screen-xl w-full flex flex-col md:flex-row bg-gray-700 py-14 rounded-lg md:space-x-4">
          <div className="pr-4 md:pl-0 w-full md:w-3/5 overflow-hidden">
            <img
              src="https://s3.traddocs.com/landing/sample2.png"
              className="h-full w-full object-cover object-right rounded-r-lg"
              alt=""
            />
          </div>
          <div className="w-full md:w-2/5 p-6 md:p-8">
            <ExplanationCard
              icon={<TbHexagonNumber2 className="w-full h-full text-white" />}
              title={t("sol2title1")}
              subtitle={t("sol2subtitle")}
              body={t("sol2body")}
            />
          </div>
        </div>
      </div>

      <div className="max-w-screen-xl w-full flex flex-col md:flex-row pt-16 pb-4 rounded-lg md:space-x-4 divide-y md:divide-y-0 md:divide-x">
        <QuotationCard
          image={"https://s3.traddocs.com/landing/quote-person-2.png"}
          logo={"https://s3.traddocs.com/landing/quote-logo-2.png"}
          quote="TradDocs is revolutionary on how it can manage international trade documentation. Its intuitive platform simplifies the organization and retrieval of shipping documents, enhancing efficiency across global teams."
          name="Javier Mendez"
          title="Director of Operations - Global Trade Solutions"
          height={"h-12"}
        />
        <QuotationCard
          image={"https://s3.traddocs.com/landing/quote-person.png"}
          logo={"https://s3.traddocs.com/landing/quote-logo-1.png"}
          quote="TradDocs helps with our document management processes. Its seamless integration and AI-powered features allow us to handle complex international documentation with unprecedented ease and accuracy."
          name="Maria Thompson"
          title="Chief Compliance Officer - Commodex International"
          height={"h-32"}
        />
      </div>
    </div>
  );
};

export default SolutionIntro;
