const fileViewer = {
  mode: "en",
  alert: "TradDocs AI can make mistakes. Make sure to double check important information.",
  confidence: "Recognition",
  thisDocument: "THIS DOCUMENT",
  missingText: "Double check the following information",
  checked: "Checked",
  save: "Save",
  download: "Download PDF",
  memo: "Type note...",
  toast: {
    title: "Successfully saved",
  },
  checklist: "Checklist",
};

export default fileViewer;
