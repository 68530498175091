import React from "react";
import { Link } from "react-router-dom";
import { IoBookOutline } from "react-icons/io5";

const Welcome = () => {
  return (
    <div
      style={{
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
      className="w-full flex justify-center relative"
    >
      <div className="max-w-screen-xl w-full h-full flex flex-col md:items-center px-4 md:px-4 z-20 py-20 pt-12 md:pt-20 2xl:pt-24">
        <div className="w-full flex flex-col items-center text-gray-800 break-keep py-6">
          <h3 className="w-full justify-center text-center text-lg text-blue-600 opacity-90 mb-6">
            AI-Powered Trade Document Management Software
          </h3>
          <h1
            style={{ whiteSpace: "pre-wrap" }}
            className="font-semibold text-4xl md:text-[3rem] 2xl:text-[3.2rem] leading-snug tracking-[-0.01em] text-center"
          >
            {"Quick document checks"}
            <br></br>
            for<em className=""> Commodity Traders</em>
          </h1>
          <h2 className="mt-8 md:mt-10 break-keep text-sm max-w-xl text-gray-500 leading-relaxed text-center w-full">
            TradDocs offers trade professionals an intuitive platform with AI-driven tools to manage contracts and trade
            documents, including Bill of Lading, Certificate of Origin, Invoice, Packing List, and more.
          </h2>

          <div className="flex flex-row items-center space-x-3 md:space-x-4 mt-12 w-full justify-center">
            <Link to={"/auth/signup"} className="md:w-auto flex text-center justify-center">
              <button className="flex flex-shrink-0 md:w-auto items-center justify-center space-x-3 h-9 px-3 md:px-5 rounded-full shadow text-sm md:text-base text-white bg-blue-500 transition font-medium hover:brightness-125">
                <p>Start now, it's free</p>
              </button>
            </Link>
            <Link to="https://docs.traddocs.com">
              <button className="flex flex-shrink-0 md:w-auto items-center justify-center space-x-3 h-9 px-3 md:px-5 rounded-full text-sm md:text-base border transition font-medium bg-white hover:bg-gray-100 text-gray-700">
                <IoBookOutline />
                <p>Developer docs</p>
              </button>
            </Link>
          </div>
        </div>

        <div className="w-full mb-4 mt-20 flex flex-col items-center justify-center text-gray-700">
          <div className="w-full h-auto md:w-auto rounded-2xl overflow-hidden">
            <video
              key="fixed-player"
              width="100%"
              height="100%"
              muted
              autoPlay
              loop
              playsInline
              controls={false}
              src={"https://s3.traddocs.com/landing/welcomeVideo3.mov"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
