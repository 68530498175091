const footer = {
  mode: "ko",
  menu: {
    company: "회사소개",
    contact: "문의하기",
    pricing: "가격정책",
    blog: "블로그",
    terms: "서비스이용약관",
    privacy: "개인정보보호정책",
  },
  conclusionTitle: "무역거래 참여자의 업무를 효율화합니다.",
  conclusionSubtitle: "TradDocs가 어떻게 도움이 될지 궁금하신가요? 문의해 주세요.",
  demo: "지금 무료로 시작하기",
  inquire: "도입 문의",
  address1: "서울특별시 마포구 공덕동 마포대로 122",
  address2: "프론트원, 11층",
  address3: "04213",
};

export default footer;
