const terms = {
  mode: "en",
  introductionTitle: "Terms of Service",
  introductionUpdate: "Last Updated: January 20, 2023",
  introductionContent:
    "TradDocs, Inc. (hereinafter referred to as 'TradDocs', 'Company', 'we', or 'us') provides the services (collectively, the 'Service') including the websites under the TradDocs.com domain, SaaS products, web design software, tools, and related services. These terms and conditions constitute a legal agreement between you and us and apply to your use of the Service. Please read and understand these terms of service carefully before using the Service.",
  acknowledgementTitle: "2. Acknowledgement and Acceptance of Terms",
  acknowledgementContent:
    "By using the Service, you agree to comply with the posted guidelines or rules applicable to the Service. Your use of the Service or access to the Site constitutes your agreement to these Terms of Service and Privacy Policy. If you do not agree to these terms, your use of the Service may be restricted.",
  eligibilityTitle: "3. Eligibility",
  eligibilityContent:
    "The Service is only available to individuals aged 13 and older. If you are between the ages of 13 and under 18 or under the legal age of majority in your jurisdiction of residence, you must review these Terms of Service with your parent or legal guardian and your parent or legal guardian must understand and agree to these Terms of Service. If you do not have the legal capacity to agree to these Terms of Service, your use of the Service may be restricted.",
  accountInformationTitle: "4. Account Information",
  accountInformationContent:
    "To use the Service, you must register and create an account (including name, password, and email address). If you upgrade your account, you must also provide payment information. Through your TradDocs account, you may have access to services and features that we periodically establish and maintain. You are responsible for maintaining accurate and up-to-date account information. We are not responsible for any problems arising from inaccurate information provided by you.",
  whatWeOwnTitle: "5. Ownership",
  whatWeOwnContent:
    "Except for user content, all materials and the Service itself, and all intellectual property rights therein or related thereto (collectively, the 'Content'), are owned by TradDocs or its licensors and are protected by copyright, trademark, and other intellectual property laws. By using the Service, you grant TradDocs the right to use the content.",
  userContentTitle: "6. User Content",
  userContentContent:
    "You may post or upload content on the Service, and you retain all intellectual property rights in such user content. TradDocs has no obligation to store, maintain, or provide copies of user content. However, by using the Service, you grant TradDocs a free, perpetual, worldwide license to use, reproduce, modify, publicly display, distribute, and perform the user content. This license includes the right for TradDocs to use, reproduce, modify, publicly display, distribute, and perform the user content in order to provide and improve the Service.",
  rulesTitle: "7. Rules",
  rulesContent:
    "Your use of the Service must comply with all applicable laws and regulations. You may not access, acquire, copy, or monitor any part of the Service using 'deep-link', 'page scrape', 'robot', 'spider', or similar technologies. By using the Service, you agree not to engage in the following activities:",
  rulesList: [
    "Posting illegal, threatening, defamatory, obscene, abusive, vulgar, or violent content",
    "Collecting, storing, disclosing, or using other users' personal information",
    "Posting content that infringes upon copyrights, trademarks, or other intellectual property rights",
    "Engaging in actions that compromise the security, integrity, or availability of the Service",
    "Interfering with the normal operation of the Service",
    "Accessing another user's account without permission or impersonating another user",
    "Posting unnecessary or inappropriate content such as spam, advertisements, chain mail, or scams",
    "Violating legal obligations, contracts, or restrictions",
  ],
  thirdPartyContentTitle: "8. Third-Party Content",
  thirdPartyContentContent:
    "TradDocs may provide links to third-party websites. We do not endorse or warrant the content of these third-party websites and we are not responsible for their content. By visiting third-party websites or using third-party content, you do so at your own risk and we shall not be liable for any issues arising from such use.",
  billingAndPaymentTitle: "9. Billing and Payment",
  billingAndPaymentContent:
    "Users of the Service may be required to provide credit card information to the Company or a payment service provider ('PSP') designated by the Company. You are responsible for paying the agreed-upon fees for the Service. You are solely responsible for any costs associated with your use of the Service.",
  privacyTitle: "10. Privacy",
  privacyContent:
    "Our Privacy Policy applies to your use of the Service and is incorporated by reference as part of these Terms of Service. By using the Service, you consent to the collection, use, and disclosure of your personal information. Your personal information will be processed in accordance with our Privacy Policy.",
  disclaimerTitle: "11. Disclaimer of Warranties and Limitation of Liability",
  disclaimerContent:
    "The Service and all content are provided 'as is' and 'as available' without any kind of warranty, either express or implied. We shall not be liable for any issues arising from your use of the Service.",
  aiDisclaimerTitle: "AI Disclaimer",
  aiDisclaimerContent:
    "TradDocs' AI technology generates and delivers content automatically based on specific events and user interactions. You are solely responsible for the use of AI technology. We shall not be liable for any issues arising from your use of AI features.",
  emergencyServicesTitle: "Emergency Services Contact",
  emergencyServicesContent:
    "The Service is not intended for use in contacting emergency services or obtaining immediate emergency assistance. In case of an emergency, you should immediately contact the appropriate emergency services in your area or seek direct assistance from local authorities.",
  serviceChangesTitle: "12. Changes, Termination, and Liability",
  serviceChangesContent:
    "TradDocs is constantly innovating to provide new features and services. Therefore, we may change, add, or discontinue the Service at any time without prior notice. We shall not be liable for any issues arising from your dissatisfaction with service changes or the termination of the Service.",
  copyrightPolicyTitle: "13. Copyright Policy",
  copyrightPolicyContent:
    "TradDocs respects the intellectual property rights of others and expects users to do the same. If you believe that any material on the Service infringes upon copyright or other intellectual property rights, please provide the following information to TradDocs. We shall not be liable for any issues arising from copyright or intellectual property infringement.",
  disputeResolutionTitle: "15. Dispute Resolution",
  disputeResolutionContent:
    "To facilitate the resolution of any disputes, claims, or controversies ('Disputes') arising out of or in connection with this agreement, you and TradDocs agree to first attempt to negotiate any Dispute informally. If the negotiation fails, you and we may pursue legal action to resolve the Dispute.",
  miscellaneousTitle: "16. Miscellaneous",
  miscellaneousContent:
    "This agreement and the rights and licenses granted hereunder may not be transferred or assigned by you, but may be freely assigned by TradDocs. These Terms of Service constitute the entire agreement between you and us and supersede any other provisions not explicitly stated herein.",
  seo: {
    title: "Terms of Service | TradDocs",
    description: "Terms of Service for TradDocs",
    keywords: "service, terms, TradDocs",
  },
};
export default terms;
