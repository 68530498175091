const faq = {
  mode: "en",
  subtitle: "Still have question? ",
  action: "Contact us",
  q1: "What are the payment methods?",
  a1: "You can make payments on a monthly or annual basis, depending on your usage plan. Payments can be made via credit card or bank transfer, and detailed payment methods can be found on the website.",
  q2: "What happens when a new member is added to the team every month?",
  a2: "Whenever a new member is added to the team, the corresponding cost will be billed. You can add or remove members at any time in the team member management section.",
  q3: "How can I switch to Tradocs Enterprise?",
  a3: "To switch to the Enterprise plan, please contact our customer support team. The Enterprise plan offers more features and enhanced support.",
  q4: "Does Tradocs offer discounts for students or non-profit organizations?",
  a4: "Yes, Tradocs provides special discounts for educational purposes or non-profit organizations. Eligibility requirements for discounts can be found on the website, along with detailed information on how to apply.",
  q5: "What are the system requirements to use Tradocs?",
  a5: "Tradocs works on most modern web browsers. For optimal performance, it is recommended to use the latest version of Chrome, Firefox, or Safari. No additional software installation is required.",
  q6: "How is data security ensured?",
  a6: "Data security is our top priority. Tradocs uses industry-standard encryption protocols to securely protect your data.",
  q7: "What document formats does Tradocs support?",
  a7: "Tradocs supports various trade-related document formats such as PDF, Excel, Word, and more. The support for specific file formats can be checked on the website.",
  q8: "How is customer support provided?",
  a8: "Customer support is available via email, phone, and live chat. Operating hours are from 9 AM to 6 PM on weekdays, and we strive to provide prompt responses.",
  q9: "How are updates to Tradocs implemented?",
  a9: "Tradocs regularly updates the system to add new features and improve performance. All updates are applied automatically to ensure a seamless user experience.",
};

export default faq;
