import React from "react";
import { Collapse, theme } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const CheckScopeIntro = () => {
  const { token } = theme.useToken();

  const panelStyle = {
    marginBottom: 0,
    background: "transparent",
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  const CheckScopeArray1 = [
    {
      question: "Date of Issue",
      answer:
        "We ensure that the date of issue on the document aligns with the shipping schedule and other related documents. Discrepancies in dates can delay the customs clearance process and create logistical issues.",
    },
    {
      question: "Container Numbers",
      answer:
        "We verify that the container numbers listed on the documents match the physical containers used for the shipment. This prevents confusion and ensures accurate tracking and handling of the goods.",
    },
    {
      question: "Seal Numbers",
      answer:
        "We check the seal numbers. These numbers are crucial for maintaining shipment integrity and compliance with industry standards and regulations.",
    },
    {
      question: "Exporter Details",
      answer:
        "We review the exporter's names, addresses, and contact information. Ensuring all details match the Bill of Lading and other shipping documents helps avoid discrepancies that could impact customs clearance.",
    },
    {
      question: "Consignee Details",
      answer:
        "We verify the names, addresses, and contact information of the consignee. Consistent consignee details are essential for the smooth delivery of goods and adherence to international trade regulations.",
    },
    {
      question: "Notify Party Details",
      answer:
        "We check the names, addresses, and contact information of the notify party. Accurate details ensure proper notifications are sent and prevent logistical issues during shipment.",
    },
    {
      question: "Vessel Information",
      answer:
        "We confirm the vessel name, IMO number, voyage number, and flag state. These details are essential for tracking the shipment and ensuring compliance with maritime regulations.",
    },
    {
      question: "Port of Loading",
      answer:
        "We validate the names and UN/LOCODEs of the port of loading. Accurate port information is necessary for proper shipment processing and to meet customs requirements at the origin.",
    },
    {
      question: "Port of Discharge",
      answer:
        "We verify the names and UN/LOCODEs of the port of discharge. Ensuring the correct port information helps in planning the arrival and unloading of the shipment, as well as in customs clearance at the destination.",
    },
  ];

  const getItems1 = (panelStyle) =>
    CheckScopeArray1.map((item, index) => ({
      key: `${index + 1}`,
      label: <p className="font-WantedSans font-medium text-base hover:text-blue-500 transition">{item.question}</p>,
      children: <p className="font-WantedSans text-blue-900 p-4 bg-blue-50 rounded text-sm">{item.answer}</p>,
      style: panelStyle,
    }));

  const CheckScopeArray2 = [
    {
      question: "Commodity Details",
      answer:
        "We ensure that descriptions, HS codes, and crop years match exactly between the documents. Accurate commodity details are critical for customs classification, duty calculation, and compliance with international trade regulations.",
    },
    {
      question: "Packaging Details",
      answer:
        "We confirm the counts and types of packages. Proper documentation of packaging details helps in the accurate calculation of shipping costs and ensures the safe and efficient handling of goods during transit.",
    },
    {
      question: "Weights",
      answer:
        "We check both gross and net weights. Weight discrepancies can lead to issues with freight charges, loading plans, and compliance with safety regulations.",
    },
    {
      question: "Quantities",
      answer:
        "We review the shipped quantities carefully to ensure they are accurately reported. Accurate quantity information is necessary for inventory management, invoicing, and customs declarations.",
    },
    {
      question: "Units of Measure",
      answer:
        "We verify that the units of measure used are consistent and appropriate for the type of goods shipped. Consistency in units of measure is important to avoid misunderstandings and errors in quantity calculations.",
    },
    {
      question: "Country Specifics",
      answer:
        "We verify the country of origin, exporting country, and importing country. Accurate country information is required for customs declarations, duty calculations, and compliance with trade agreements.",
    },
    {
      question: "Charges and Terms",
      answer:
        "We review the freight charges, additional fees, and Incoterms. Ensuring the accuracy of these details helps in the correct invoicing and prevents disputes over shipping costs and responsibilities.",
    },
    {
      question: "Cross-Referencing",
      answer:
        "We align the information with related contracts and supporting documents. Consistent cross-referencing ensures that all documentation is accurate and supports the smooth processing and clearance of the shipment.",
    },
    {
      question: "Industry specifics (e.g. LOT for coffee)",
      answer:
        "We align industry-specific information, such as LOT numbers, with related contracts and supporting documents. Consistent cross-referencing ensures that all documentation is accurate and supports the smooth processing and clearance of the shipment.",
    },
  ];

  const getItems2 = (panelStyle) =>
    CheckScopeArray2.map((item, index) => ({
      key: `${index + 1}`,
      label: <p className="font-WantedSans font-medium text-base hover:text-blue-500 transition">{item.question}</p>,
      children: <p className="font-WantedSans text-blue-900 p-4 bg-blue-50 rounded text-sm">{item.answer}</p>,
      style: panelStyle,
    }));

  return (
    <div className="flex w-full items-center justify-center flex-col md:pt-24 py-16 text-gray-700 px-4">
      <div className="font-medium w-full justify-center flex text-blue-600 mb-6">TradDocs automation</div>
      <h3 style={{ whiteSpace: "pre-wrap" }} className="text-4xl font-semibold text-center leading-normal">
        {`Comprehensive Cross-Checks for\nShipping Accuracy`}
      </h3>
      <h4 className="mt-4 mb-8 break-keep text-base max-w-lg text-gray-500 leading-relaxed text-center w-full">
        Our tool meticulously cross-checks the following items to ensure compliance, accuracy, and efficiency in your
        shipping processes: date of issue, container numbers, seal numbers, exporter details, consignee details, and
        more.
      </h4>
      <Link to="/auth/signup">
        <button className="flex w-auto items-center justify-center space-x-3 h-9 px-3 md:px-5 rounded-full shadow md:text-base text-white bg-blue-500 transition font-medium hover:brightness-125 mb-16">
          <p>Start now for free</p>
        </button>
      </Link>
      <div className="max-w-3xl w-full flex flex-col items-center pb-12">
        <div className="w-full flex flex-col md:flex-row md:justify-center">
          <div className="w-full md:w-1/2">
            <Collapse
              bordered={false}
              size="large"
              expandIcon={({ isActive }) => (
                <CaretRightOutlined style={{ color: "#3b82f6" }} rotate={isActive ? 90 : 0} />
              )}
              style={{
                background: "transparent",
              }}
              items={getItems1(panelStyle)}
              accordion
            />
          </div>
          <div className="w-full md:w-1/2">
            <Collapse
              bordered={false}
              size="large"
              expandIcon={({ isActive }) => (
                <CaretRightOutlined style={{ color: "#3b82f6" }} rotate={isActive ? 90 : 0} />
              )}
              style={{
                background: "transparent",
              }}
              items={getItems2(panelStyle)}
              accordion
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckScopeIntro;
