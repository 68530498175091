import React, { useContext } from "react";
import { Avatar, Table } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { AuthContext } from "../../../context/authContext";

const Members = ({ teamMembers, teamData }) => {
  const { userState } = useContext(AuthContext);

  const starterData = [
    {
      user_id: userState.user.userId,
      user_email: userState.user.userEmail,
      user_name: userState.user.userName,
      user_img: userState.user.userImg,
      role: "Admin",
    },
  ];

  const columns = [
    {
      title: "Member",
      key: "member",
      render: (text, record) => (
        <div className="flex space-x-4 items-center">
          {record?.user_img ? (
            <img src={record.user_img} className="w-10 h-10 rounded-full object-cover" alt="" />
          ) : (
            <Avatar icon={<UserOutlined />} />
          )}

          <div className="space-y-1">
            <p className="text-sm font-semibold">{record.user_name}</p>
            <p className="text-xs text-gray-500">{record.user_email}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role) => <span className="text-gray-500 text-xs tracking-wider font-medium">{role}</span>,
    },
  ];

  return (
    <div className="">
      <div className="flex justify-between mb-4">
        <div className="space-y-1">
          <h1 className="text-xl font-medium mb-2">{teamData ? teamMembers.length : 1} Workspace Team Member</h1>
          <p className="text-sm text-gray-400">
            {teamData ? teamMembers.length : 1} of UNLIMITED seats are currently in use.
          </p>
        </div>
      </div>

      <Table
        dataSource={teamData ? teamMembers : starterData}
        columns={columns}
        pagination={false}
        showHeader={true}
        bordered={false}
        rootClassName="border rounded-lg bg-gray-100 overflow-hidden"
      />
    </div>
  );
};

export default Members;
