import moment from "moment";
import React, { useState } from "react";
import AddFilesModal from "../modals/AddFilesModal";
import { MdEdit } from "react-icons/md";
import ChangeFolderNameModal from "../modals/ChangeFolderNameModal";

const FolderViewHeader = ({
  archiveInfo,
  projectId,
  getFiles,
  getArchive,
  setExtractFetched,
  setSplitterFetched,
  setComparedFetched,
  setInitiallyFetched,
}) => {
  const [isCreateFolderModalOpen, setCreateFolderModalOpen] = useState(false);
  const [isFolderNameModalOpen, setFolderNameModalOpen] = useState(false);
  return (
    <div className="p-3 w-full border-b bg-white">
      <ChangeFolderNameModal
        open={isFolderNameModalOpen}
        setOpen={setFolderNameModalOpen}
        currentName={archiveInfo?.archive_name}
        projectId={projectId}
        getArchive={getArchive}
      />
      <AddFilesModal
        open={isCreateFolderModalOpen}
        setOpen={setCreateFolderModalOpen}
        archiveId={projectId}
        getArchive={getArchive}
        getFiles={getFiles}
        setExtractFetched={setExtractFetched}
        setSplitterFetched={setSplitterFetched}
        setComparedFetched={setComparedFetched}
        setInitiallyFetched={setInitiallyFetched}
      />
      <div className="mb-2">
        <div className="flex items-center mb-2 text-gray-700 text-lg font-medium">{archiveInfo?.archive_name}</div>
        <p className="text-xs text-gray-400">
          Last modified{" "}
          {moment(archiveInfo?.archive_updated_at).calendar(null, {
            sameDay: "[Today at] h:mm A",
            nextDay: "[Tomorrow at] h:mm A",
            nextWeek: "MMM DD, YYYY",
            lastDay: "[Yesterday at] h:mm A",
            lastWeek: "MMM DD, YYYY",
            sameElse: "MMM DD, YYYY",
          })}
        </p>
      </div>

      <div className="flex space-x-2 w-fullmt-4">
        <button
          onClick={() => setFolderNameModalOpen(true)}
          className="bg-gray-100 text-gray-600 hover:text-blue-500 text-sm font-medium h-9 border-none flex items-center justify-center rounded-lg transition space-x-2 px-4"
        >
          <MdEdit />
          <p className="text-xs">Edit workspace name</p>
        </button>
      </div>
    </div>
  );
};

export default FolderViewHeader;
