import { Input, Modal, notification } from "antd";
import React, { useState } from "react";
import { FaCircleCheck } from "react-icons/fa6";
import axios from "../../utils/authAxios";

const ChangePasswordModal = ({ setOpen, open }) => {
  const [newPasswordInput, setNewPasswordInput] = useState("");
  const [api, contextHolder] = notification.useNotification();

  const handleOk = () => {
    setOpen(false);
    setNewPasswordInput("");
  };

  const handleCancel = () => {
    setOpen(false);
    setNewPasswordInput("");
  };

  const changePassword = () => {
    if (!newPasswordInput) {
      notification.error({
        message: "Error",
        description: "Password cannot be empty.",
      });
      return;
    }
    axios
      .patch(`/v1/users/me/reset_password`, {
        new_password: newPasswordInput,
      })
      .then((response) => {
        openNotification();
        setOpen(false);
        setNewPasswordInput("");
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: "An error occurred while changing the password.",
        });
      });
  };

  const openNotification = () => {
    api.info({
      icon: <FaCircleCheck className="text-blue-500 w-6 h-6 opacity-90" />,
      message: <p className="text-base font-semibold text-gray-700">{"Password changed successfully"}</p>,
      description: (
        <p className="text-sm text-gray-500 break-keep">
          {"This may take a few moments."}
        </p>
      ),
      placement: "top",
    });
  };

  return (
    <>
      <Modal
        open={open}
        title={
          <div className="font-WantedSans">
            <p className="text-lg">Change Password</p>
          </div>
        }
        closeIcon={null}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={[
          <button
            key={"close"}
            className="border text-gray-600 px-4 py-2 h-10 rounded-lg transition font-medium font-WantedSans"
            onClick={handleCancel}
          >
            Close
          </button>,
          <button
            onClick={changePassword}
            key={"start"}
            className="bg-blue-600 text-white px-4 py-2 h-10 rounded-lg transition font-medium font-WantedSans ml-3"
          >
            Save
          </button>,
        ]}
      >
        <div className="w-full h-full py-6">
          <div className="bg-white w-full rounded-3xl bg-opacity-70 font-WantedSans">
            <div className="bg-white rounded w-full space-y-4">
              <p className="font-medium">New password</p>
              <Input.Password
                placeholder="New Password"
                value={newPasswordInput}
                onChange={(e) => setNewPasswordInput(e.target.value)}
                className="h-11"
              />
            </div>
          </div>
        </div>
        {contextHolder}
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
