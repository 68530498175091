import React, { useState } from "react";
import { FaCaretDown, FaCheck } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import { formatDocumentType } from "../../utils/formatDocumentType";

const CrossCheckIssueTable = ({
  highlightedIndicies,
  setHighlightedIndicies,
  currentComparedB,
  currentDocumentType,
  type,
}) => {
  const [isCollapsed, setCollapsed] = useState(false);
  const issueArray = currentComparedB.filter((discrepancy) => discrepancy.discrepancy);

  function renderValue(value) {
    if (Array.isArray(value)) {
      return value.join(", "); // or return value.map((item, index) => <li key={index}>{item}</li>);
    } else if (typeof value === "object" && value !== null) {
      return JSON.stringify(value, null, 2);
    } else if (typeof value === "string") {
      return value;
    } else {
      return "N/A";
    }
  }

  const headerCss = `rounded transition flex items-center space-x-1 transition py-1.5 px-2 w-full`;
  const headerTitleCss = `text-black text-sm font-medium`;
  const checkIconCss = "text-green-600 w-5 h-3 flex-shrink-0";
  const arrowIconCss = `${isCollapsed ? "-rotate-90" : ""} text-gray-500 transform transition`;
  const warningIconCss = "text-yellow-600 w-5 h-4 flex-shrink-0";
  const tableContainerCss = "border rounded overflow-hidden mt-2 mb-6";
  const tableCss = "w-full text-sm text-left overflow-hidden divide-y";
  const tableBodyCss = "divide-y text-xs";

  const TableHeader = () => (
    <thead className="text-gray-600 bg-gray-100 text-xs text-left">
      <tr>
        <th className="px-2 py-3 font-medium border-r">Description</th>
        <th className="px-2 py-3 font-medium border-r">
          {currentDocumentType.includes("bol")
            ? "Bill of Lading"
            : currentDocumentType.includes("coo_general")
            ? "Certificate of Origin"
            : currentDocumentType.includes("coo_ico")
            ? "Certificate of Origin (ICO)"
            : currentDocumentType.includes("coo_ldc")
            ? "Certificate of Origin (LDC)"
            : currentDocumentType.includes("coo_free_trade")
            ? "Certificate of Origin (FTA)"
            : currentDocumentType.includes("coo_gsp")
            ? "Certificate of Origin (GSP)"
            : currentDocumentType.includes("contract")
            ? "Sales Contract"
            : formatDocumentType(currentDocumentType)}
        </th>
        <th className="px-2 py-3 font-medium bg-gray-200">
          {currentDocumentType.includes("contract")
            ? "Purchase Contract"
            : type === "bol"
            ? "Bill of Lading"
            : type === "invoice"
            ? "Commercial Invoice"
            : type === "contract"
            ? "Contract"
            : type === "di"
            ? "Document Instruction"
            : "Letter of Credit"}
        </th>
      </tr>
    </thead>
  );

  if (issueArray.length === 0)
    return (
      <div className="w-full">
        <button onClick={() => setCollapsed(!isCollapsed)} className={headerCss}>
          <div className="flex space-x-2 items-center">
            <FaCaretDown className={arrowIconCss} />
            <FaCheck className={checkIconCss} />
            <p className={headerTitleCss}>Issues detected (0)</p>
          </div>
        </button>
        {!isCollapsed && (
          <div className="w-full h-8 text-sm text-left text-gray-400 flex items-center pl-10 rounded overflow-hidden divide-y mb-6 mt-2">
            No Issues were found.
          </div>
        )}
      </div>
    );
  else
    return (
      <div className="w-full overflow-auto">
        <button onClick={() => setCollapsed(!isCollapsed)} className={headerCss}>
          <div className="flex space-x-2 items-center">
            <FaCaretDown className={arrowIconCss} />
            <IoIosWarning className={warningIconCss} />
            <p className={headerTitleCss}>Issues detected ({issueArray.length})</p>
          </div>
        </button>
        {!isCollapsed && (
          <div className="pl-7 pr-2 max-w-screen-xl">
            <div className={tableContainerCss}>
              <table style={{ tableLayout: "fixed" }} className={tableCss}>
                <TableHeader />
                <tbody className={tableBodyCss}>
                  {issueArray.map((discrepancy, i) => {
                    return (
                      <tr
                        key={i}
                        onClick={() => setHighlightedIndicies([discrepancy.id])}
                        className={`divide-x transition hover:bg-sky-100 cursor-pointer`}
                      >
                        <td className="py-4 px-4 relative">
                          <div>
                            <p className="text-xs text-yellow-600 mb-1 font-medium">{discrepancy.item}</p>
                            <p className="text-gray-500 max-w-72">
                              {discrepancy.discrepancy_details || "No issue detected."}
                            </p>
                          </div>
                        </td>
                        <td className="py-4 px-4 divide-y">
                          <div className="text-yellow-600">{renderValue(discrepancy.target_detail)} </div>
                        </td>
                        <td className="py-4 px-4 divide-y bg-[#00000005]">
                          <div className="text-gray-500 h-full">{renderValue(discrepancy.ref_detail)}</div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    );
};

export default CrossCheckIssueTable;
