const dashboard = {
  mode: "ko",
  header: "대시보드",
  title: "선적서류 폴더",
  folder: {
    header: {
      status: "상태",
      name: "폴더명",
      shipType: "선적종류",
      uploader: "업로더",
      date: "일시",
      numberOfFiles: "파일수",
      docType: "종류",
    },
    cell: {
      inProgress: "검사중",
      complete: "검사완료",
    },
  },
  toast: {
    title: "하자 검사가 진행중입니다.",
    subtitle: "약 40초 소요되며 완료되면 이메일로 알려드립니다.",
  },
  popup: {
    logout: "로그아웃",
  },
  emptyTitle: "폴더가 비어 있습니다",
  emptyText1: "선적서류를 업로드해서",
  emptyText2: " 첫 하자검사를 시작하세요.",
  reload: "새로고침",
};

export default dashboard;
