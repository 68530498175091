import React from "react";
import { useNavigate } from "react-router-dom";
import { TbFile, TbFiles } from "react-icons/tb";
import { BiPlus } from "react-icons/bi";
import { FaFile } from "react-icons/fa6";
import moment from "moment";
import { FiUpload } from "react-icons/fi";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

const FoldersTable = ({ folders, isLoading, setCreateFolderModalOpen, isEmpty }) => {
  const emptyCompHeight = "calc(100svh - 6.4rem)";
  const navigate = useNavigate();

  const TableCell = ({ item, index }) => {
    const Docs = ({ title, exists }) => (
      <div className={`flex flex-col items-center space-y-1 w-14 ${exists ? "text-gray-500" : "text-gray-200"}`}>
        <FaFile className={`w-3 h-3 ${exists ? "text-gray-500" : "text-gray-200"}`} />

        <p>{title}</p>
      </div>
    );
    return (
      <div
        onClick={() => navigate(`/dashboard/folders/${item.archive_id}`)}
        className={`${
          index % 2 === 0 ? "bg-white" : "bg-gray-50"
        } hover:bg-sky-100 transition text-xs cursor-pointer flex items-center justify-between w-full`}
      >
        <div className="py-3 w-80 flex-shrink-0 flex">
          <div className="flex items-center justify-center w-[4.5rem] flex-shrink-0 text-[0.7rem]">
            <div className="flex flex-col">
              <p className={`truncate`}>TD{item.archive_id}ID</p>
            </div>
          </div>
          <div className="flex flex-col">
            <p className={`truncate`}>{item.archive_name}</p>
          </div>
        </div>
        <div className="px-2 md:px-4 py-3 w-36 flex-shrink-0">
          <div className="flex items-center space-x-2 text-gray-500">
            <FiUpload />
            <p className={`truncate`}>{item.documents} Documents</p>
          </div>
        </div>
        <div className="px-2 md:px-4 py-3 w-44 flex-shrink-0">
          <div className="flex items-center space-x-2">
            <Docs title="Sales" exists={item.contracts?.includes("sales")} />
            <Docs title="Purchase" exists={item.contracts?.includes("purchase")} />
          </div>
        </div>

        <div className="px-2 md:px-4 py-3 w-48"></div>

        <div className="px-2 md:px-2 py-3 w-52 flex space-x-2">
          {item?.user_img ? (
            <img className="border w-7 h-7 rounded-full object-cover" src={item?.user_img} alt="" />
          ) : (
            <Avatar icon={<UserOutlined />} />
          )}
          <div className="flex flex-col text-gray-500 text-[0.7rem]">
            <p>Created</p>
            <p className={`truncate`}>
              {moment(item?.archive_created_at).calendar(null, {
                sameDay: "[Today at] h:mm A",
                nextDay: "[Tomorrow at] h:mm A",
                nextWeek: "MMM DD, YYYY",
                lastDay: "[Yesterday at] h:mm A",
                lastWeek: "MMM DD, YYYY",
                sameElse: "MMM DD, YYYY",
              })}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const EmptyComponent = () => (
    <div
      style={{ height: emptyCompHeight }}
      onClick={() => setCreateFolderModalOpen(true)}
      className="flex flex-col items-center h-full w-full justify-center cursor-pointer rounded-lg"
    >
      <div className="space-x-4 flex items-center mb-6">
        <TbFile className="w-20 h-20 text-blue-500" />
        <p className="text-2xl text-gray-400">OR</p>
        <TbFiles className="w-20 h-20 text-pink-500" />
      </div>

      <div className="text-center text-gray-700 flex flex-col items-center">
        <h2 className="text-2xl font-medium mb-4">Upload single or multiple files</h2>
        <p className="text-sm max-w-md text-center text-gray-500 mb-6">
          It looks like you haven't created any checks yet. Start your first document check by creating your first
          folder.
        </p>
        <button
          className="bg-blue-600 text-white text-sm font-medium border-none flex items-center justify-center rounded-lg hover:bg-blue-500 transition space-x-2 py-2 px-5 flex-shrink-0"
          onClick={() => setCreateFolderModalOpen(true)}
        >
          <BiPlus className="w-5 h-5" />
          <p className="font-medium">New Workspace</p>
        </button>
      </div>
    </div>
  );

  return (
    <div className="overflow-hidden rounded-lg shadow bg-white">
      {!isLoading &&
        (isEmpty ? (
          <EmptyComponent />
        ) : (
          folders.map((item, index) => <TableCell key={item.archive_id} item={item} index={index} />)
        ))}
    </div>
  );
};

export default FoldersTable;
