const dashboard = {
  mode: "en",
  header: "Dashboard",
  title: "My Folders",
  folder: {
    header: {
      status: "Status",
      name: "Folder Name",
      shipType: "Ship Type",
      uploader: "Uploader",
      date: "Upload Date",
      numberOfFiles: "Files",
      docType: "Type",
    },
    cell: {
      inProgress: "In progress",
      complete: "Complete",
    },
  },
  toast: {
    title: "Inspection is in progress.",
    subtitle: "It will take less than 60 seconds.",
  },
  popup: {
    logout: "Log out",
  },
  emptyTitle: "No Inspections Yet",
  emptyText1: "Start your first inspection by",
  emptyText2: " uploading your shipping documents.",
  reload: "Reload",
};

export default dashboard;
