import React, { useEffect, useRef, useState } from "react";
import { LuCopy, LuCopyCheck } from "react-icons/lu";
import { notification } from "antd";
import { FaCircleCheck } from "react-icons/fa6";
import useFetchJson from "../../hooks/useFetchJson";

const ExtractDocumentInstructionResult = ({ splitterData, files, splitterFetched }) => {
  const { fetchJson } = useFetchJson();
  const [api, contextHolder] = notification.useNotification();
  const [copiedItem, setCopiedItem] = useState(null);
  const [diData, setDiData] = useState(null);
  const fetchedExtractDocumentIds = useRef(new Set());
  const [extractedData, setExtractedData] = useState({});

  useEffect(() => {
    const fetchExtractedData = async () => {
      const fetchPromises = files.map((file) => {
        if (!fetchedExtractDocumentIds.current.has(file.document_id)) {
          return fetchJson("extracted", file.archive_id, file.document_id, fetchedExtractDocumentIds, setExtractedData);
        }
        return Promise.resolve();
      });
      await Promise.all(fetchPromises);
    };
    if (splitterFetched) {
      fetchExtractedData();
    }

    return () => {};
  }, [files, fetchJson, extractedData, splitterFetched]);

  useEffect(() => {
    // Helper function to find the index of the document type containing "bol"
    function findIndexOfDocumentTypeWithBol(documents) {
      return documents.findIndex((document) => document.document_type.toLowerCase().includes("document_instruction"));
    }

    // Process each document ID in splitterData
    const processDocumentIds = async () => {
      const allDocumentIds = Object.keys(splitterData);
      let blIndex;
      let blDocId;
      for (const docId of allDocumentIds) {
        if (findIndexOfDocumentTypeWithBol(splitterData[docId]) > -1) {
          blIndex = findIndexOfDocumentTypeWithBol(splitterData[docId]);
          blDocId = docId;
        }
      }
      setDiData(extractedData?.[blDocId]?.[blIndex]);
    };

    if (splitterData && extractedData) {
      processDocumentIds();
    }
  }, [splitterData, extractedData]);

  const openNotification = (value) => {
    api.info({
      icon: <FaCircleCheck className="text-blue-500 w-6 h-6 opacity-90" />,
      message: <p className="text-base font-semibold text-gray-700">{"Copied to clipboard"}</p>,
      description: <p className="text-sm text-gray-500 break-keep uppercase">{value}</p>,
      placement: "topRight",
    });
  };

  const handleCopy = (key, value) => {
    navigator.clipboard.writeText(value).then(() => {
      setCopiedItem(key);
      openNotification(value);
    });
  };

  const KeyValueDisplay = ({ label, value, keyName, handleCopy, copiedItem }) => {
    return (
      <div className={`text-xs pr-3`}>
        <div className="flex w-full items-center">
          <div
            className={`${
              keyName ? "w-36" : "w-[11.2rem]"
            } px-2 h-full text-sky-600 font-medium text-xs flex-shrink-0 flex items-center`}
          >
            {label}
          </div>
          <div className="w-full border-l h-full">
            <div className="text-gray-600 h-full w-full py-4 px-2">
              {Array.isArray(value)
                ? keyName === "requiredDocuments"
                  ? value?.length > 0
                    ? value.map((doc, index) => <div key={index}>+ {doc.fullInformation},</div>)
                    : "-"
                  : value.join(", ")
                : keyName === "remarks"
                ? value?.length > 0
                  ? value.map((doc, index) => <div key={index}>+ {doc},</div>)
                  : "-"
                : value || "-"}
            </div>
          </div>
          <button
            className="w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-100 transition flex-shrink-0"
            onClick={() => handleCopy(keyName, value)}
          >
            {copiedItem === keyName ? (
              <LuCopyCheck className="w-4 h-4 text-blue-500" />
            ) : (
              <LuCopy className="w-4 h-4 text-gray-400" />
            )}
          </button>
        </div>
      </div>
    );
  };

  const diFields = [
    {
      key: "shipperFullInformation",
      label: "Shipper Full Information",
      value: diData?.shipper?.fullInformation,
      text: "Shipper Full Information",
    },
    {
      key: "shipperName",
      label: "Shipper Name",
      value: diData?.shipper?.name,
      text: "Shipper Name",
    },
    {
      key: "shipperAddress",
      label: "Shipper Address",
      value: diData?.shipper?.address,
      text: "Shipper Address",
    },
    {
      key: "shipperPhone",
      label: "Shipper Phone",
      value: diData?.shipper?.contactDetails?.phone,
      text: "Shipper Phone",
    },
    {
      key: "shipperEmail",
      label: "Shipper Email",
      value: diData?.shipper?.contactDetails?.email,
      text: "Shipper Email",
    },
    {
      key: "consigneeFullInformation",
      label: "Consignee Full Information",
      value: diData?.consignee?.fullInformation,
      text: "Consignee Full Information",
    },
    {
      key: "consigneeName",
      label: "Consignee Name",
      value: diData?.consignee?.name,
      text: "Consignee Name",
    },
    {
      key: "consigneeAddress",
      label: "Consignee Address",
      value: diData?.consignee?.address,
      text: "Consignee Address",
    },
    {
      key: "consigneePhone",
      label: "Consignee Phone",
      value: diData?.consignee?.contactDetails?.phone,
      text: "Consignee Phone",
    },
    {
      key: "consigneeEmail",
      label: "Consignee Email",
      value: diData?.consignee?.contactDetails?.email,
      text: "Consignee Email",
    },
    {
      key: "notifyParties",
      label: "Notify Parties",
      value: diData?.notifyParties?.map((party) => party.fullInformation).join(", "),
      text: "Notify Parties",
    },
    {
      key: "vesselName",
      label: "Vessel Name",
      value: diData?.vesselDetails?.vesselName,
      text: "Vessel Name",
    },
    {
      key: "vesselFlag",
      label: "Vessel Flag",
      value: diData?.vesselDetails?.flag,
      text: "Vessel Flag",
    },
    {
      key: "portOfLoading",
      label: "Port of Loading",
      value: diData?.ports?.portOfLoading,
      text: "Port of Loading",
    },
    {
      key: "portOfDischarge",
      label: "Port of Discharge",
      value: diData?.ports?.portOfDischarge,
      text: "Port of Discharge",
    },
    {
      key: "commodityName",
      label: "Commodity Name",
      value: diData?.descriptionOfGoods?.[0]?.commodity?.name,
      text: "Commodity Name",
    },
    {
      key: "commodityOrigin",
      label: "Commodity Origin",
      value: diData?.descriptionOfGoods?.[0]?.commodity?.origin,
      text: "Commodity Origin",
    },
    {
      key: "commodityCropYear",
      label: "Commodity Crop Year",
      value: diData?.descriptionOfGoods?.[0]?.commodity?.cropYear,
      text: "Commodity Crop Year",
    },
    {
      key: "commodityHSCode",
      label: "Commodity HS Code",
      value: diData?.descriptionOfGoods?.[0]?.commodity?.hsCode,
      text: "Commodity HS Code",
    },
    {
      key: "quantityValue",
      label: "Quantity Value",
      value: diData?.descriptionOfGoods?.[0]?.quantity?.value,
      text: "Quantity Value",
    },
    {
      key: "quantityUnit",
      label: "Quantity Unit",
      value: diData?.descriptionOfGoods?.[0]?.quantity?.unit,
      text: "Quantity Unit",
    },
    {
      key: "tolerancePlus",
      label: "Tolerance Plus",
      value: diData?.descriptionOfGoods?.[0]?.quantity?.tolerance?.plus,
      text: "Tolerance Plus",
    },
    {
      key: "toleranceMinus",
      label: "Tolerance Minus",
      value: diData?.descriptionOfGoods?.[0]?.quantity?.tolerance?.minus,
      text: "Tolerance Minus",
    },
    {
      key: "toleranceUnit",
      label: "Tolerance Unit",
      value: diData?.descriptionOfGoods?.[0]?.quantity?.tolerance?.unit,
      text: "Tolerance Unit",
    },
    {
      key: "incoterm",
      label: "Incoterm",
      value: diData?.descriptionOfGoods?.[0]?.incoterm,
      text: "Incoterm",
    },
    {
      key: "remarks",
      label: "Remarks",
      value: diData?.remarks,
      text: "Remarks",
    },
    {
      key: "requiredDocuments",
      label: "Required Documents",
      value: diData?.requiredDocuments,
      text: "Required Documents",
    },
  ];

  return (
    <div className="relative flex w-full">
      <div className="w-full pb-12">
        <div className="font-medium text-sky-600 uppercase text-xs py-2 border-b px-2 bg-sky-50">
          LETTER OF CREDIT INFORMATION
        </div>

        <div className="divide-y w-full rounded-lg overflow-hidden">
          {diFields.map((field) => (
            <KeyValueDisplay
              key={field.key}
              label={`${field.label}`}
              value={field.value}
              keyName={field.key}
              handleCopy={handleCopy}
              copiedItem={copiedItem}
            />
          ))}
        </div>
      </div>
      {contextHolder}
    </div>
  );
};

export default ExtractDocumentInstructionResult;
