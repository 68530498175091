import React, { useContext } from "react";
import { AuthContext } from "../../../context/authContext";
import moment from "moment";
import { RiFileExcel2Line } from "react-icons/ri";
import { MdDownload } from "react-icons/md";
import { VscLoading } from "react-icons/vsc";
import { FaCheck } from "react-icons/fa6";
import { downloadXlsx } from "../../../utils/fileDownload";

const Spreadsheet = ({ teamData }) => {
  const { userState } = useContext(AuthContext);
  const userInfo = userState.user;

  const currentMonth = moment().format("MMMM");
  const nextMonth = moment().add(1, "months").format("MMMM");

  const DashboardHeader = () => (
    <div className="w-full bg-white border-b pr-4 p-2 flex justify-between items-center">
      <div className="flex space-x-4 items-center">
        {teamData ? (
          <div className="h-14 w-14 bg-gray-200 bg-opacity-40 rounded-lg flex-shrink-0 text-7xl font-medium text-white flex items-center justify-center overflow-hidden border">
            <img className="w-full h-full" draggable={false} src={teamData.team_img} alt="" />
          </div>
        ) : (
          <div className="h-14 w-14 bg-white flex-shrink-0 bg-gradient-to-br from-fuchsia-400 to-fuchsia-300 text-4xl font-medium text-white flex items-center justify-center rounded-lg">
            {userInfo.userName.slice(0, 1)}
          </div>
        )}
        <div className="">
          <p className="text-xl font-semibold">
            {teamData ? `${teamData.team_name}'s Team` : `${userInfo.userName}'s Team`}
          </p>
          <div className="flex items-center space-x-2">
            <p className="text-xs text-gray-400">
              Current usage period ({currentMonth} 3 to {nextMonth} 2)
            </p>
            <div className="bg-gray-100 text-xs font-medium text-gray-500 px-2 py-1 rounded">
              {teamData ? "Master Plan" : "Free Trial Plan"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const fileData = {
    name: "FOB Sales", // The name of the file without the extension
    header: [
      "Contract No.",
      "Contract Date",
      "Account",
      "Diff.",
      "MT",
      "Lot/s",
      "Shipment",
      "Quality",
      "GTC",
      "Place Date",
      "Fixed Date",
    ],
    body: [
      [
        "I1-4508882",
        "29/Mar/24",
        "ILDIO Inc.",
        "-2.00",
        "19.175",
        "",
        "Jun-24",
        "Brazil Arabica NY 2 S17/18 SSFC",
        "",
        "",
        "",
      ],
      [
        "I1-4508882",
        "29/Mar/24",
        "ILDIO Inc.",
        "-2.00",
        "19.175",
        "",
        "Jun-24",
        "Brazil Arabica NY 2 S17/18 SSFC",
        "",
        "",
        "",
      ],
    ],
  };

  return (
    <div className="w-full h-full overflow-auto bg-gray-100 text-gray-700">
      <DashboardHeader />
      <div className="w-full bg-gray-100 rounded-lg p-3">
        <div className="flex space-x-3">
          <div>
            <p className="mb-2 font-medium text-gray-500 text-sm">Forward your email to:</p>
            <div className="p-3 bg-white shadow rounded-lg text-sm text-blue-600">spreadsheet@traddocs.com</div>
          </div>
          <div>
            <p className="mb-2 font-medium text-gray-500 text-sm">Download spreadsheet:</p>
            <button
              onClick={() => downloadXlsx(fileData)}
              className="p-3 bg-white shadow rounded-lg text-sm text-green-700 flex space-x-2 items-center transition hover:bg-green-50"
            >
              <RiFileExcel2Line className="w-5 h-5" />
              <p>SOMENAME_Aug_17_2024</p>
              <MdDownload className="w-5 h-5" />
            </button>
          </div>
        </div>

        <div className="mt-3">
          <p className="mb-2 font-medium text-gray-500 text-sm">History</p>
          <div className="p-3 bg-white shadow rounded-lg text-sm text-blue-600">
            <div className="px-3 py-2 text-xs flex min-w-48 items-center space-x-3 font-medium bg-gray-100 rounded-lg mb-2">
              <div className="w-48 h-full text-gray-500">Status</div>
              <div className="w-48 h-full text-gray-500">Date</div>
            </div>

            <div className="px-3 py-2 text-xs flex min-w-48 items-center space-x-3 font-medium rounded-lg">
              <div className="w-48 h-full text-gray-500 flex items-center space-x-2">
                <VscLoading className="text-gray-400 animate-spin w-4 h-4" />
                <p>Processing</p>
              </div>
              <div className="w-48 h-full text-gray-500">August 26, 2024 - 08:32 AM</div>
            </div>

            <div className="px-3 py-2 text-xs flex min-w-48 items-center space-x-3 font-medium rounded-lg">
              <div className="w-48 h-full text-gray-500 flex items-center space-x-2">
                <FaCheck className="w-3.5 h-3.5 text-green-600" />
                <p>Added to spreadsheet</p>
              </div>
              <div className="w-48 h-full text-gray-500">August 26, 2024 - 08:32 AM</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spreadsheet;
