const terms = {
  mode: "ko",
  introductionTitle: "서비스 이용 약관",
  introductionUpdate: "마지막 업데이트: 2023년 1월 20일",
  introductionContent:
    "TradDocs, Inc. (이하 'TradDocs', '회사', '우리' 또는 '저희')는 귀하의 이용약관 준수를 전제로 TradDocs.com 도메인 하의 기타 웹사이트들(종합적으로 '사이트')과 SaaS 제품, 웹 디자인 소프트웨어, 도구 및 관련 서비스(사이트와 함께 '서비스')를 제공합니다. 본 약관은 귀하와 저희 사이의 법적 계약을 구성하며, 귀하의 서비스 이용에 적용됩니다. 이용 약관은 주의 깊게 읽고 이해한 후에 서비스를 이용해 주시기 바랍니다.",
  acknowledgementTitle: "2. 약관의 인정 및 수락",
  acknowledgementContent:
    "서비스를 이용할 때, 귀하는 서비스에 적용되는 게시된 지침이나 규칙을 따라야 합니다. 서비스 이용 또는 사이트 접속 시 이 이용약관, 개인정보 보호정책에 동의하는 것입니다. 귀하가 본 약관에 동의하지 않는 경우, 서비스 이용은 제한될 수 있습니다.",
  eligibilityTitle: "3. 자격",
  eligibilityContent:
    "서비스는 13세 이상의 개인에게만 제공됩니다. 만약 귀하가 13세 이상이지만 18세 미만이거나 거주 지역의 법적 성년 연령 미만이라면, 이 이용약관을 부모님 또는 법적 보호자와 함께 검토해야 하며, 귀하와 귀하의 부모님 또는 법적 보호자가 이 이용약관을 이해하고 동의해야 합니다. 서비스 이용 시 귀하가 본 약관에 대한 법적 자격이 없는 경우, 서비스 이용은 제한될 수 있습니다.",
  accountInformationTitle: "4. 계정 정보",
  accountInformationContent:
    "서비스를 이용하려면 계정을 등록하고 생성해야 합니다(이름, 비밀번호, 이메일 주소 포함). 계정을 업그레이드하는 경우 결제 정보도 제공해야 합니다. TradDocs 계정을 통해 저희가 간헐적으로 설정하고 유지하는 서비스 및 기능에 접근할 수 있습니다. 귀하는 계정 정보를 정확하게 유지하고 최신 정보로 업데이트해야 합니다. 귀하가 제공한 부정확한 정보로 인해 발생하는 어떠한 문제에 대해서도 저희는 책임지지 않습니다.",
  whatWeOwnTitle: "5. 저희가 소유한 것",
  whatWeOwnContent:
    "사용자 콘텐츠를 제외하고, 서비스에 있는 모든 자료 및 서비스 자체, 그리고 그것과 관련된 또는 포함된 모든 지적 재산권(이하 '콘텐츠')은 TradDocs 또는 그 라이선스 제공자가 소유하며 저작권, 상표권 및 기타 지적 재산권 법률에 의해 보호됩니다. 귀하는 서비스를 이용함으로써 TradDocs에게 콘텐츠 사용 권한을 부여하게 됩니다.",
  userContentTitle: "6. 사용자 콘텐츠",
  userContentContent:
    "귀하는 서비스에서 콘텐츠를 게시하거나 업로드할 수 있으며, 그러한 사용자 콘텐츠에 대한 모든 지적 재산권은 사용자가 보유합니다. TradDocs는 사용자 콘텐츠를 저장, 유지 또는 복사본을 제공할 의무가 없습니다. 그러나 귀하는 서비스를 이용함으로써 TradDocs에게 사용자 콘텐츠에 대한 무료, 영구적인, 전 세계적인 라이선스를 부여하게 됩니다. 이 라이선스는 TradDocs가 서비스를 제공하고 개선하기 위해 사용자 콘텐츠를 사용, 복제, 수정, 공개, 배포 및 표시할 수 있는 권한을 포함합니다.",
  rulesTitle: "7. 규칙",
  rulesContent:
    "서비스 이용은 모든 적용 가능한 법률 및 규정을 준수해야 합니다. 서비스의 어떠한 부분에 대해서도 '딥링크', '페이지 스크레이프', '로봇', '스파이더' 등을 사용해 접근, 획득, 복사 또는 모니터링할 수 없습니다. 귀하가 서비스를 이용함으로써 다음과 같은 행위를 하지 않는 것에 동의합니다:",
  rulesList: [
    "불법적인, 위협적인, 모욕적인, 욕설적인, 음란한, 협박적인, 폭력적인, 차별적인 또는 공격적인 콘텐츠 게시",
    "다른 사용자의 개인정보를 수집, 저장, 공개 또는 사용",
    "저작권, 상표권, 기타 지적 재산권을 침해하는 콘텐츠 게시",
    "서비스의 안전성, 보안성 또는 가용성을 해치는 행위",
    "서비스의 정상적인 작동을 방해하는 행위",
    "사용자의 동의 없이 다른 사용자의 계정에 접근하거나 다른 사용자를 사칭하는 행위",
    "스팸, 광고, 홍보, 체인 메일, 스키밍 등의 불필요한 또는 부적절한 콘텐츠 게시",
    "법적인 의무, 계약, 제한 사항을 위반하는 행위",
  ],
  thirdPartyContentTitle: "8. 제3자 콘텐츠",
  thirdPartyContentContent:
    "TradDocs는 제3자 웹사이트로의 링크를 제공할 수 있습니다. 이러한 제3자 웹사이트의 콘텐츠에 대해 추천하거나 보증하지 않으며, 그 콘텐츠의 책임을 지지 않습니다. 제3자 웹사이트를 방문하거나 제3자 콘텐츠를 이용함으로써 발생하는 어떠한 문제에 대해서도 저희는 책임지지 않습니다.",
  billingAndPaymentTitle: "9. 청구 및 결제",
  billingAndPaymentContent:
    "서비스의 사용자는 회사 또는 회사가 선정한 결제 서비스 제공업체('PSP')에 신용카드 정보를 제공해야 할 수 있습니다. 서비스에 대한 합의된 수수료를 지불할 책임이 있습니다. 귀하가 서비스 이용 중에 발생하는 모든 비용과 관련된 책임은 귀하에게 있습니다.",
  privacyTitle: "10. 개인정보 보호",
  privacyContent:
    "우리의 개인정보 보호정책은 서비스 사용에 적용되며, 이러한 이용 약관의 일부로 본 참조로 만들어집니다. 서비스를 이용함으로써 귀하는 개인정보 수집, 사용 및 공개에 동의하는 것입니다. 귀하의 개인정보는 우리의 개인정보 보호정책에 따라 처리됩니다.",
  disclaimerTitle: "11. 보증의 부인 및 책임 제한",
  disclaimerContent:
    "서비스와 모든 콘텐츠는 '있는 그대로' 및 '사용 가능한 상태로' 제공되며, 명시적 또는 묵시적으로 어떠한 종류의 보증도 제공하지 않습니다. 귀하가 서비스를 이용함으로써 발생하는 어떠한 문제에 대해서도 저희는 책임지지 않습니다.",
  aiDisclaimerTitle: "인공지능 면책 조항",
  aiDisclaimerContent:
    "TradDocs의 인공지능 기술과 기능은 특정 이벤트와 사용자 상호작용에 따라 자동으로 콘텐츠를 생성하며 사용자 및 최종 사용자에게 전달합니다. 귀하는 AI 기술을 사용하는 데 있어 전적으로 책임을 집니다. 귀하가 인공지능 기능을 사용함으로써 발생하는 어떠한 문제에 대해서도 저희는 책임지지 않습니다.",
  emergencyServicesTitle: "긴급 서비스 연락",
  emergencyServicesContent:
    "서비스는 긴급 서비스 연락이나 즉각적인 긴급 지원 획득을 위한 용도로 사용될 수 없습니다. 긴급 상황에서는 즉시 해당 지역의 긴급 서비스에 연락하거나 현지 당국에 직접 도움을 요청해야 합니다.",
  serviceChangesTitle: "12. 서비스 변경, 종료 및 책임",
  serviceChangesContent:
    "TradDocs는 새로운 기능과 서비스를 제공하기 위해 지속적으로 혁신하고 있습니다. 따라서 사전 통지 없이 언제든지 서비스를 변경, 추가 또는 중단할 수 있습니다. 귀하가 서비스 변경에 대해 불만이 있거나 서비스 종료로 인해 발생하는 어떠한 문제에 대해서도 저희는 책임지지 않습니다.",
  copyrightPolicyTitle: "13. 저작권 정책",
  copyrightPolicyContent:
    "TradDocs는 타인의 지적 재산권을 존중하며 사용자도 동일하게 행동할 것을 기대합니다. 서비스와 관련하여 저작권 또는 기타 지적 재산권 침해라고 생각되는 자료가 있다면 아래 정보를 TradDocs에 보내주시기 바랍니다. 저작권 또는 지적 재산권 침해로 인해 발생하는 어떠한 문제에 대해서도 저희는 책임지지 않습니다.",
  disputeResolutionTitle: "15. 분쟁 해결",
  disputeResolutionContent:
    "분쟁, 논쟁 또는 이 계약과 관련된 클레임('분쟁')에 대한 해결을 촉진하고 비용을 줄이기 위해, 귀하는 TradDocs와 함께 먼저 비공식적으로 협상을 시도합니다. 협상이 실패한 경우, 귀하와 저희는 분쟁을 해결하기 위해 법적 조치를 취할 수 있습니다.",
  miscellaneousTitle: "16. 기타",
  miscellaneousContent:
    "이 계약과 여기에 부여된 권리 및 라이선스는 귀하에 의해 전달되거나 양도될 수 없으나, TradDocs는 제한 없이 이를 양도할 수 있습니다. 본 약관은 귀하와 저희 사이의 전체 계약을 구성하며, 본 약관에 명시되지 않은 어떠한 조항도 적용되지 않습니다.",
  seo: {
    title: "서비스 이용 약관 | 트레독스 TradDocs",
    description: "트레독스 서비스 이용에 대한 약관",
    keywords: "서비스, 이용 약관, 트레독스",
  },
};
export default terms;
