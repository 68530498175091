import { notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { IoCloudUpload } from "react-icons/io5";

const DropComponent = ({ subtitle, setSelectedFiles, selectedFiles }) => {
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to leave?";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };
    if (selectedFiles.length > 0) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [selectedFiles]);

  const onDragEnter = useCallback(() => {
    setIsDragging(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      setIsDragging(false);

      if (fileRejections.length > 0) {
        openNotificationWithIcon(
          "error",
          <p className="font-WantedSans break-keep">Unsupported File Format</p>,
          <p className="text-sm font-WantedSans text-gray-500">Currently, we only support PDF files.</p>
        );
      }

      // Concatenate new files to the existing list
      setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
    [setSelectedFiles]
  );

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDragEnter,
    onDragLeave,
    onDrop,
    multiple: true, // Disallow multiple file uploads
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      // "image/jpeg": [".jpeg", ".jpg"],
      // "image/png": [".png"],
      // "image/gif": [".gif"],
    },
  });

  return (
    <div className="h-full">
      <div
        {...getRootProps()}
        className={`${
          isDragging ? "border-blue-500 bg-blue-100" : "border-gray-300 hover:border-blue-500 bg-gray-50"
        }  relative w-full h-full pb-4 pt-1 rounded-xl border-dashed border flex flex-col items-center justify-center space-y-4 cursor-pointer transition`}
      >
        <input {...getInputProps()} id="fileUpload" style={{ display: "none" }} />

        <div className="flex flex-col items-center w-full px-6 space-y-4">
          <div className="w-14 h-14 rounded-full p-3 bg-white  hover:bg-gray-100 transition">
            <IoCloudUpload className="w-full h-full opacity-90 text-gray-400" />
          </div>
          <div className="space-x-1 flex items-center">
            <p className="text-xl text-gray-700">Drop your files here or</p>
            <p className="text-xl text-blue-500 hover:text-blue-600">Browse</p>
          </div>
          <p className="text-sm text-gray-400">{subtitle}</p>
        </div>
      </div>
    </div>
  );
};

export default DropComponent;
