import React, { useEffect, useState } from "react";
import { useNavigate, Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import LeftPanel from "./LeftPanel";
import Team from "./Dashboard/Team";
import axios from "../../utils/authAxios";
import Spreadsheet from "./Dashboard/Spreadsheet";

const Main = () => {
  const navigate = useNavigate();
  const [teamData, setTeamData] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath === "/") {
      navigate("/dashboard");
    }
  }, [navigate]);

  const fetchTeam = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/v1/teams`);
      const teamMemberResponse = await axios.get(`/v1/teams/${response.data?.[0].team_id}/members`);
      setTeamData(response.data?.[0] || null);
      setTeamMembers(teamMemberResponse.data || []);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeam();
    return () => {};
  }, []);

  if (!isLoading)
    return (
      <div style={{ height: "100svh" }} className="w-full flex">
        <Routes>
          <Route path="/*" element={<LeftPanel />} />
          <Route path="/dashboard/folders/:projectId/check-results/*" element={<></>} />
          <Route path="/dashboard/folders/:projectId/check-contract-results/*" element={<></>} />
          {/* <Route path="/settings/*" element={<Settings />} /> */}
          {/* <Route path="/*" element={<Dashboard folders={folders} />} /> */}
        </Routes>
        <Routes>
          <Route path="/dashboard/*" element={<Dashboard teamData={teamData} />} />
          <Route path="/spreadsheet/*" element={<Spreadsheet teamData={teamData} />} />
          <Route path="/team/*" element={<Team teamData={teamData} teamMembers={teamMembers} />} />
          {/* <Route path="/settings/*" element={<Settings />} /> */}
          {/* <Route path="/*" element={<Dashboard folders={folders} />} /> */}
        </Routes>
      </div>
    );
};

export default Main;
