const company = {
  mode: "en",
  title1: "We transform the operations",
  title2: "of international trade",
  subtitle:
    "TradDocs is revolutionizing international trade with easy-to-use AI solutions, driving efficiency for teams globally",
  header: "About TradDocs",
  body1:
    "In 2023, amidst the complex maze of international trade, we found a beacon of hope. My closest colleague and I could no longer stand idly by the challenges of trade. That moment marked the beginning of our journey with TradDocs. Our goal was simple: to revolutionize trade document processes, making global trade easier and more accessible for everyone.",
  body2:
    "Through my own experiences, I've witnessed firsthand how cumbersome document processing can be a significant burden. Spending countless nights reviewing and correcting documents, and experiencing substantial losses over a single mistake fueled our passion and conviction that there had to be a better way.",
  body3:
    "Launching TradDocs, we set our sights on improving the lives of back office workers, importers, and exporters. These individuals are the true heroes of trade but are often unable to fully realize their potential due to the cumbersome nature of document processing. Our vision was to create a world where they could unleash their full potential, making the trade process easier and faster.",
  body4:
    "TradDocs has dramatically improved the speed and accuracy of trade document processing by using innovative AI technology to automatically review and handle documents. Thanks to our platform, everyone involved in trade can now complete transactions more quickly and escape the stress caused by errors.",
  body5:
    "The journey that began in 2023 is more than just a business venture; it's about our passion, our dreams, and, most importantly, our commitment to making international trade a better place. With TradDocs, we are moving towards a future where the complexities of trade documents are simplified, and everyone can easily access the global market.",
  body6:
    "Our journey cannot be complete without your participation and support. Together, we can open a new chapter in international trade, creating a world where everyone can embrace greater opportunities. Join us in shaping the future of trade with TradDocs.",
  vision: "Our vision and goals",
  visionBody1:
    "At TradDocs, we envision a world where international trade is boundless and accessible to all, driven by innovation, efficiency, and trust. Our mission is to dismantle the barriers of global commerce through the power of technology, making trade faster, safer, and more transparent for everyone. We aim to be the leading force in revolutionizing trade documentation, empowering businesses and individuals alike to thrive in the global marketplace.",
  passionAndCreativity: "Passion and Creativity",
  collaborationSkills: "Collaboration Skills",
  willingnessToLearn: "Willingness to Learn",
  problemSolvingSkills: "Problem Solving Skills",
  adaptabilityAndFlexibility: "Adaptability and Flexibility",
  globalMindset: "Global Mindset",

  leadership: {
    subtitle:
      "At TradDocs, our diverse team of experts in technology and trade finance works tirelessly to transform global trade. Their unique skills and insights fuel our mission to make trade finance more efficient and accessible.",
    ceo: "Michael Choi",
    cto: "John Kim",
  },
  //PARTNER SECTION
  partnerTitle1: "TradDocs advances through",
  partnerTitle2: "support of these partners",
  //SEO
  title: "Company - TradDocs",
  description:
    "Learn about TradDocs, a leader in automating trade finance documentation with AI technology, making international trade more secure and efficient.",
  keywords:
    "Trade Finance Automation, TradDocs About, AI Document Processing, International Trade, Secure Trade Documentation, AI Innovation in Trade",
};

export default company;
