const signup = {
  mode: "ko",
  google: "Google 계정으로 계속",
  continue: "계속하기",
  signup: "무료로 회원가입",
  name: "이름",
  email: "이메일 주소",
  password: "비밀번호",
  passwordConfirm: "비밀번호 확인",
  complete: "회원가입 완료",
  loading: "로딩중...",
  already: "이미 회원이신가요?",
  login: "로그인",
  or: "또는",
  error: {
    unknown: "잠시 후 시도해주세요",
    unregistered: "이메일 또는 비밀번호가 틀렸습니다",
    wrong: "이메일 또는 비밀번호가 틀렸습니다",
    invalidFormat: "올바른 이메일 형식이 아닙니다",
    passwordMatch: "비밀번호를 다시 확인하세요F",
  },
};

export default signup;
