import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../utils/authAxios";
import { HttpStatusCode } from "axios";
import { AuthContext } from "../../context/authContext";
import { useGoogleLogin } from "@react-oauth/google";
import { useTranslation } from "react-i18next";
import { Button, Input } from "antd";
import { FcGoogle } from "react-icons/fc";
import { Checkbox } from "antd";

const Signup = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("signup");
  const { updateAccessToken } = useContext(AuthContext);

  const [isLoading, setLoading] = useState(false);
  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [passwordConfirmValue, setPasswordConfirmValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [signupDisabled, setSignupDisabled] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);

  const onPrivacyChange = (e) => {
    setPrivacyChecked(e.target.checked);
  };

  useEffect(() => {
    if (
      nameValue.length < 1 ||
      emailValue.length < 1 ||
      passwordValue.length < 1 ||
      passwordConfirmValue.length < 1 ||
      isLoading ||
      !privacyChecked
    ) {
      setSignupDisabled(true);
    } else {
      setSignupDisabled(false);
    }
  }, [
    isLoading,
    passwordValue.length,
    nameValue.length,
    emailValue.length,
    passwordConfirmValue.length,
    privacyChecked,
  ]);

  const authenticate = (item) => {
    setLoading(true);
    setErrorMessage("");
    axios
      .post(`/v1/auth/login`, item)
      .then((response) => {
        if (response.status === HttpStatusCode.Ok) {
          updateAccessToken(response.data.access_token).then(() => navigate("/"));
        } else {
          setErrorMessage(t("error.unknown"));
        }
        setLoading(false);
      })
      .catch((e) => {
        if (e.response.status === HttpStatusCode.NotFound) {
          if (item.auth.auth_type !== "email") {
            axios
              .post(`/v1/auth/register`, {
                ...item,
                user: {},
              })
              .then((registerResponse) => {
                updateAccessToken(registerResponse.data.access_token).then(() => {
                  navigate("/");
                });
              });
          } else {
            setErrorMessage(t("error.unregistered"));
          }
        } else if (e.response.status === HttpStatusCode.Forbidden) {
          // 403:Wrong email or password
          setErrorMessage(t("error.wrong"));
        } else if (e.response.status === HttpStatusCode.UnprocessableEntity) {
          // 402:Invalid email format
          setErrorMessage(t("error.invalidFormat"));
        } else {
          setErrorMessage(t("error.unknown"));
        }
      })
      .finally(() => setLoading(false));
  };

  const completeRegister = () => {
    setLoading(true);
    setErrorMessage("");
    if (passwordConfirmValue !== passwordValue) {
      setErrorMessage("error.passwordMatch");
      setLoading(false);
    } else {
      axios
        .post("/v1/auth/register", {
          auth: {
            auth_type: "email",
            user_email: emailValue,
            user_password: passwordValue,
          },
          user: {
            user_name: nameValue,
          },
        })
        .then((response) => {
          if (response.status === HttpStatusCode.Ok) {
            updateAccessToken(response.data.access_token).then(() => navigate("/"));
          }
        })
        .catch((e) => {
          if (e.response.status === HttpStatusCode.UnprocessableEntity) {
            setErrorMessage(t("error.invalidFormat"));
          } else if (e.response.status === HttpStatusCode.Conflict) {
            setErrorMessage("This email is already in use");
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      completeRegister();
    }
  };

  const googleLoginCallback = useGoogleLogin({
    flow: "implicit",
    onSuccess: (tokenResponse) => {
      authenticate({ auth: { auth_type: "google", access_token: tokenResponse.access_token } });
    },
  });

  return (
    <div className="max-w-md px-4 w-full">
      <div className="bg-white px-6 md:px-8 py-6 md:py-10 rounded-lg shadow-lg">
        <div className="mb-6">
          <h1 className="text-2xl font-bold">{t("signup")}</h1>
        </div>

        <div onKeyDown={handleKeypress} className="mt-4 bg-white rounded bg-opacity-10 w-full space-y-4">
          <Input
            placeholder={t("name")}
            type="text"
            value={nameValue}
            onChange={(e) => setNameValue(e.target.value)}
            className="h-11"
          />
          <Input
            placeholder={t("email")}
            type="email"
            value={emailValue}
            onChange={(e) => setEmailValue(e.target.value)}
            className="h-11"
          />
          <Input.Password
            placeholder={t("password")}
            type="password"
            value={passwordValue}
            onChange={(e) => setPasswordValue(e.target.value)}
            className="h-11"
          />
          <Input.Password
            placeholder={t("passwordConfirm")}
            type="password"
            value={passwordConfirmValue}
            onChange={(e) => setPasswordConfirmValue(e.target.value)}
            className="h-11"
          />
          <Checkbox className="text-xs" onChange={onPrivacyChange}>
            I agree to TradDocs'{" "}
            <Link className="text-blue-500" to={"/legal/terms"}>
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link className="text-blue-500" to={"/legal/privacy"}>
              Privacy Policy
            </Link>
            .
          </Checkbox>
        </div>
        {errorMessage && <p className="mt-3 text-xs text-red-500">{errorMessage}</p>}
        <Button
          type="primary"
          onClick={() => completeRegister()}
          disabled={signupDisabled}
          loading={isLoading}
          className={`mt-10 w-full h-10 text-base font-semibold px-24 border-none ${
            signupDisabled ? "bg-[#d9d9d9]" : "bg-blue-500"
          }`}
        >
          {isLoading ? t("loading") : t("complete")}
        </Button>
        <div className="flex items-center h-1 py-8 w-full">
          <div className="border-t h-px w-full border-gray-300"></div>
          <p className="flex-shrink-0 px-3 text-sm text-gray-400">{t("or")}</p>
          <div className="border-t h-px w-full border-gray-300"></div>
        </div>
        <Button
          onClick={() => googleLoginCallback()}
          className="w-full h-10 flex items-center justify-center"
          icon={<FcGoogle className="w-5 h-5" />}
        >
          {t("google")}
        </Button>
      </div>
      <div className="flex justify-center mt-8 text-gray-700 text-sm items-center space-x-1">
        <p>{t("already")}</p>
        <Link to="/auth/login">
          <button>
            <p className="hover:underline font-semibold text-blue-500">{t("login")}</p>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Signup;
