import React from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "../../components/Footer";
import Privacy from "./Privacy";
import Terms from "./Terms";

const Legal = () => {
  return (
    <div className="w-full flex flex-col items-center">
      <div
        style={{ minHeight: "calc(100vh - 8rem)" }}
        className="w-full flex flex-col items-center justify-center relative flex-shrink-0 bg-white"
      >
        <Routes>
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default Legal;
