const createFolderModal = {
  mode: "en",
  modalHeader: "New inspection folder",
  footer: {
    backButton: "Go Back",
    nextButton: "Continue",
    completeButton: "Start Inspection",
  },
  steps: {
    first: "null",
    second: "Create new folder",
    third: "Start",
  },
  stepOne: {
    option1: "Bulk Carrier",
    option2: "Container Shipper",
    option3: "Tanker",
    option4: "LNG Carrier",
    option5: "Chemical Tanker",
    option6: "RO-RO",
    inDevelopement: "Coming soon",
  },
  stepTwo: {
    title1: "Set Folder Name",
    title2: "Attach Shipping Document Files",
    option1: "New Shipping Case",
    option2: "Add Documents to Existing Folder",
    placeholder1: "Enter New Folder Name",
    placeholder2: "Select Existing Folder",
    errorMessage: "Folder name already exists. Please select 'Add Documents to Existing Folder'.",
    dropzone: "You can select multiple PDF files up to 500MB",
    dropzoneButton: "Select Files",
  },
  stepThree: {
    title: "Please confirm the information below",
    subtitle1: "Ship Type (Type of Shipping Documents)",
    subtitle2: "Folder Name",
    subtitle3: "Selected Files",
  },

  toast: {
    title: "Inspection has started",
    subtitle: "It will take less than 60 seconds.",
  },
};

export default createFolderModal;
