import React, { useState } from "react";
import { LuCopy, LuCopyCheck } from "react-icons/lu";
import { notification } from "antd";
import { FaCircleCheck } from "react-icons/fa6";

const ExtractContractResults = ({ archiveInfo, documentId }) => {
  const [api, contextHolder] = notification.useNotification();
  const [copiedItem, setCopiedItem] = useState(null);

  const contractData =
    archiveInfo?.contracts?.filter((contract) => contract.document_id === parseInt(documentId)) || [];

  const openNotification = (value) => {
    api.info({
      icon: <FaCircleCheck className="text-blue-500 w-6 h-6 opacity-90" />,
      message: <p className="text-base font-semibold text-gray-700">{"Copied to clipboard"}</p>,
      description: <p className="text-sm text-gray-500 break-keep uppercase">{value}</p>,
      placement: "topRight",
    });
  };

  const handleCopy = (key, value) => {
    let valueToCopy;

    if (typeof value === "object" && value !== null) {
      valueToCopy = JSON.stringify(value, null, 2); // Pretty-print the JSON with 2-space indentation
    } else {
      valueToCopy = value;
    }

    navigator.clipboard.writeText(valueToCopy).then(() => {
      setCopiedItem(key);
      openNotification(valueToCopy);
    });
  };

  const KeyValueDisplay = ({ label, value, handleCopy, copiedItem }) => {
    const renderValue = (value) => {
      if (typeof value === "boolean") {
        return value ? "Allowed" : "Not allowed";
      } else if (Array.isArray(value)) {
        if (label === "Required Documents") {
          return value.length > 0 ? value.map((doc, index) => <div key={index}>+ {doc.fullInformation},</div>) : "-";
        }
        return value.join(", ");
      } else if (typeof value === "object" && value !== null) {
        return Object.entries(value).map(([key, val], index) => (
          <div key={index}>
            <strong>{key}:</strong> {val}
          </div>
        ));
      }
      return value || "-";
    };

    return (
      <div className={`text-xs bg-white shadow rounded-lg`}>
        <div className="flex w-full items-center">
          <div className="flex items-center space-x-2 px-2">
            <button
              className="w-7 h-7 flex items-center justify-center rounded-full hover:bg-gray-100 transition flex-shrink-0"
              onClick={() => handleCopy(label, value)}
            >
              {copiedItem === label ? (
                <LuCopyCheck className="w-3.5 h-3.5 text-blue-500" />
              ) : (
                <LuCopy className="w-3.5 h-3.5 text-gray-400" />
              )}
            </button>
            <div className="w-36 h-full text-sky-600 font-medium text-xs flex-shrink-0">{label}</div>
          </div>
          <div className="w-full">
            <div className="text-gray-600 h-full w-full py-3 px-2">{renderValue(value)}</div>
          </div>
        </div>
      </div>
    );
  };

  const contractFields = [
    { label: "Buyer Name", value: contractData?.[0]?.contract_data?.buyer?.name },
    { label: "Buyer Address", value: contractData?.[0]?.contract_data?.buyer?.address },
    { label: "Seller Name", value: contractData?.[0]?.contract_data?.seller?.name },
    { label: "Seller Address", value: contractData?.[0]?.contract_data?.seller?.address },
    { label: "Contract Number", value: contractData?.[0]?.contract_data?.contractNumber },
    { label: "Contract Type", value: contractData?.[0]?.contract_data?.contractType },
    { label: "Date of Contract", value: contractData?.[0]?.contract_data?.dateOfContract },
    { label: "Packing", value: contractData?.[0]?.contract_data?.packing },
    { label: "Loading Rate", value: contractData?.[0]?.contract_data?.loadingRate?.rate },
    { label: "Loading Rate Unit", value: contractData?.[0]?.contract_data?.loadingRate?.unit },
    { label: "Discharge Rate", value: contractData?.[0]?.contract_data?.dischargeRate?.rate },
    { label: "Discharge Rate Unit", value: contractData?.[0]?.contract_data?.dischargeRate?.unit },
    { label: "Payment Term", value: contractData?.[0]?.contract_data?.payment?.term },
    { label: "LC Type", value: contractData?.[0]?.contract_data?.payment?.lcType },
    { label: "Payment Percentage", value: contractData?.[0]?.contract_data?.payment?.percentage },
    { label: "Payment Time Frame", value: contractData?.[0]?.contract_data?.payment?.timeFrame },
    { label: "Document Copyable", value: `${contractData?.[0]?.contract_data?.payment?.copyable}` },
    { label: "Port of Loading", value: contractData?.[0]?.contract_data?.portOfLoading?.port },
    { label: "Safe Port of Loading", value: contractData?.[0]?.contract_data?.portOfLoading?.safePort },
    { label: "Safe Berth of Loading", value: contractData?.[0]?.contract_data?.portOfLoading?.safeBerth },
    { label: "Date of Shipment From", value: contractData?.[0]?.contract_data?.portOfLoading?.dateOfShipment?.from },
    { label: "Date of Shipment Until", value: contractData?.[0]?.contract_data?.portOfLoading?.dateOfShipment?.until },
    { label: "Transshipment", value: contractData?.[0]?.contract_data?.portOfLoading?.dateOfShipment?.transshipment },
    {
      label: "Partial Shipment",
      value: contractData?.[0]?.contract_data?.portOfLoading?.dateOfShipment?.partialShipment,
    },
    { label: "Free Date (days)", value: contractData?.[0]?.contract_data?.freeDate?.days },
    { label: "Extension Of Shipment", value: contractData?.[0]?.contract_data?.extensionOfShipment?.fullInformation },
    { label: "Destination Country", value: contractData?.[0]?.contract_data?.destination?.country },
    { label: "Port of Discharge", value: contractData?.[0]?.contract_data?.destination?.portOfDischarge?.port },
    {
      label: "Safe Port of Discharge",
      value: contractData?.[0]?.contract_data?.destination?.portOfDischarge?.safePort,
    },
    {
      label: "Safe Berth of Discharge",
      value: contractData?.[0]?.contract_data?.destination?.portOfDischarge?.safeBerth,
    },
    {
      label: "Final Port",
      value: contractData?.[0]?.contract_data?.weightQualityCondition?.finalPort,
    },
    {
      label: "Arbitration Country",
      value: contractData?.[0]?.contract_data?.arbitration?.country,
    },
    {
      label: "Arbitration Rule",
      value: contractData?.[0]?.contract_data?.arbitration?.rule,
    },
    { label: "Insurance Option", value: contractData?.[0]?.contract_data?.insuranceOption },
    { label: "Surveyor Cost Option", value: contractData?.[0]?.contract_data?.surveyorCostOption },
    { label: "Surveyor Nomination Option", value: contractData?.[0]?.contract_data?.surveyorNominationOption },
    { label: "Required Documents", value: contractData?.[0]?.contract_data?.requiredDocuments },
  ];

  const ListHeader = ({ title }) => <div className="font-medium text-sky-800 text-xs py-2 border-t px-2">{title}</div>;

  return (
    <div className="relative flex w-full">
      <div className="w-full pb-12 bg-gray-100">
        <ListHeader title="GENERAL INFORMATION" />
        <div className="space-y-1 w-full rounded-lg overflow-hidden px-2">
          {contractFields.map((field, index) => (
            <KeyValueDisplay
              key={index}
              label={field.label}
              value={field.value}
              handleCopy={handleCopy}
              copiedItem={copiedItem}
            />
          ))}
        </div>

        {contractData?.[0]?.contract_data?.descriptionOfGoods &&
          contractData?.[0]?.contract_data?.descriptionOfGoods.map((good, index) => (
            <div key={index}>
              <ListHeader title={`COMMODITY (${good.commodity?.name || `Commodity ${index + 1}`})`} />

              <div key={index} className="text-xs rounded-lg px-2 space-y-1">
                <KeyValueDisplay
                  label="Commodity Name"
                  value={good.commodity?.name}
                  keyName={`commodity-${index}`}
                  handleCopy={handleCopy}
                  copiedItem={copiedItem}
                />
                <KeyValueDisplay
                  label="Origin"
                  value={good.commodity?.origin}
                  keyName={`commodity-origin-${index}`}
                  handleCopy={handleCopy}
                  copiedItem={copiedItem}
                />
                <KeyValueDisplay
                  label="Crop Year"
                  value={good.commodity?.cropYear}
                  keyName={`commodity-crop-year-${index}`}
                  handleCopy={handleCopy}
                  copiedItem={copiedItem}
                />
                <KeyValueDisplay
                  label="HS Code"
                  value={good.commodity?.hsCode}
                  keyName={`hsCode-${index}`}
                  handleCopy={handleCopy}
                  copiedItem={copiedItem}
                />
                <KeyValueDisplay
                  label="Quantity"
                  value={good.quantity?.value}
                  keyName={`quantity-${index}`}
                  handleCopy={handleCopy}
                  copiedItem={copiedItem}
                />
                <KeyValueDisplay
                  label="Quantity Unit"
                  value={good.quantity?.unit}
                  keyName={`quantity-unit-${index}`}
                  handleCopy={handleCopy}
                  copiedItem={copiedItem}
                />
                <KeyValueDisplay
                  label="Tolerance +"
                  value={good.quantity?.tolerance?.plus}
                  keyName={`toleranceUp-${index}`}
                  handleCopy={handleCopy}
                  copiedItem={copiedItem}
                />
                <KeyValueDisplay
                  label="Tolerance -"
                  value={good.quantity?.tolerance?.minus}
                  keyName={`toleranceDown-${index}`}
                  handleCopy={handleCopy}
                  copiedItem={copiedItem}
                />
                <KeyValueDisplay
                  label="Tolerance Unit"
                  value={good.quantity?.tolerance?.unit}
                  keyName={`toleranceUnit-${index}`}
                  handleCopy={handleCopy}
                  copiedItem={copiedItem}
                />
                <KeyValueDisplay
                  label="Tolerance Option"
                  value={good.quantity?.tolerance?.option}
                  keyName={`toleranceUnit-${index}`}
                  handleCopy={handleCopy}
                  copiedItem={copiedItem}
                />
                <KeyValueDisplay
                  label="Price Full Information"
                  value={good.price?.fullInformation}
                  keyName={`price-${index}`}
                  handleCopy={handleCopy}
                  copiedItem={copiedItem}
                />
                <KeyValueDisplay
                  label="Price Value"
                  value={good.price?.value}
                  keyName={`price-value-${index}`}
                  handleCopy={handleCopy}
                  copiedItem={copiedItem}
                />
                <KeyValueDisplay
                  label="Price Unit"
                  value={good.price?.unit}
                  keyName={`price-unit-${index}`}
                  handleCopy={handleCopy}
                  copiedItem={copiedItem}
                />
                <KeyValueDisplay
                  label="Incoterm"
                  value={good.incoterm}
                  keyName={`incoterm-${index}`}
                  handleCopy={handleCopy}
                  copiedItem={copiedItem}
                />
                {typeof good.quality === "object" && good.quality !== null ? (
                  Object.entries(good.quality).map(([subKey, subValue]) => (
                    <KeyValueDisplay
                      key={subKey}
                      label={`Quality: ${subKey}`}
                      value={subValue}
                      keyName={`quality-${index}-${subKey}`}
                      handleCopy={handleCopy}
                      copiedItem={copiedItem}
                    />
                  ))
                ) : (
                  <KeyValueDisplay
                    label="Quality"
                    value={good.quality}
                    keyName={`quality-${index}`}
                    handleCopy={handleCopy}
                    copiedItem={copiedItem}
                  />
                )}
              </div>
            </div>
          ))}
      </div>
      {contextHolder}
    </div>
  );
};

export default ExtractContractResults;
