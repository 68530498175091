import React from "react";
import { FaFile } from "react-icons/fa";

const RecognizedGrid = ({ splitterData }) => {
  const documentTypesWithIds = Object.entries(splitterData).flatMap(([documentId, items]) =>
    items.map((doc) => ({ ...doc, documentId }))
  );

  const documentTypes = [
    { title: "Contract", includes: "contract" },
    { title: "Letter of Credit", includes: "letter_of_credit" },
    { title: "Document Instruction", includes: "instruction" },
    { title: "Bill of Lading", includes: "bol" },
    { title: "Commercial Invoice", includes: "commercial_invoice" },
    { title: "Packing List", includes: "packing_list" },
    { title: "Weight Certificate", includes: "certificate_of_weight" },
    { title: "Quality Certificate", includes: "certificate_of_quality" },
    { title: "Certificate of Origin", includes: "coo_general" },
    { title: "Certificate of Origin - ICO", includes: "coo_ico" },
    { title: "Certificate of Origin - LDC", includes: "coo_ldc" },
    { title: "Certificate of Origin - FTA", includes: "coo_free_trade" },
    { title: "Certificate of Origin - GSP", includes: "coo_gsp" },
    { title: "Phytosanitary Certificate", includes: "phytosanitary_certificate" },
    { title: "Fumigation Certificate", includes: "fumigation_certificate" },
  ];

  const Cell = ({ title, hasFile }) => {
    return (
      <div className="w-full h-full text-xs flex items-center rounded py-0.5 space-x-2 cursor-default">
        <FaFile className={`${hasFile ? "text-gray-500" : "text-gray-200"} w-3 h-3`} />
        <div className="flex space-x-2 items-center">
          <p className={`${hasFile ? "text-gray-700 font-medium" : "text-gray-300"} text-xs text-center`}>{title}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="h-full space-y-1">
        {documentTypes.map((cell, index) => {
          const matchingDoc = documentTypesWithIds.find((docType) => docType.document_type.includes(cell.includes));

          return <Cell key={index} title={cell.title} hasFile={Boolean(matchingDoc)} />;
        })}
      </div>
    </>
  );
};

export default RecognizedGrid;
