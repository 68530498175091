import "../index.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import FolderView from "../../../components/FolderView/FolderView";
import FoldersView from "../../../components/Dashboard/FoldersView";

const Dashboard = ({ teamData }) => {
  return (
    <div className="w-full">
      <Routes>
        <Route path="/*" element={<FoldersView teamData={teamData} />} />
        <Route path="/folders/:projectId/*" element={<FolderView />} />
      </Routes>
    </div>
  );
};

export default Dashboard;
