const signup = {
  mode: "en",
  google: "Continue with Google",
  continue: "Continue",
  signup: "Create your account",
  name: "Full name",
  email: "Email",
  password: "Password",
  passwordConfirm: "Confirm password",
  complete: "Create account",
  loading: "Loading...",
  already: "Already a member?",
  login: "Log in",
  or: "OR",
  error: {
    unknown: "Something went wrong...",
    unregistered: "Wrong email or password",
    wrong: "Wrong email or password",
    invalidFormat: "Invalid email format",
    passwordMatch: "Passwords do not match",
  },
};

export default signup;
