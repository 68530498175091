import React, { useState } from "react";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import SEOMetaTag from "../utils/SEOMetaTag";
import FAQ from "../components/Landing/FAQ";
import { Button, Input, Select } from "antd";

const Contact = () => {
  const { t } = useTranslation("contact");
  const [isLoading, setLoading] = useState(false);
  const { TextArea } = Input;
  const { Option } = Select;

  const ContactSection = () => {
    const [formData, setFormData] = useState({
      name: "",
      companyName: "",
      position: "",
      email: "",
      teamSize: null,
      how: "",
      message: "",
    });

    const handleChange = (e) => {
      const { id, value } = e.target;
      setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      setLoading(true);
      emailjs
        .send("service_hplyum8", "template_w30f3mm", formData, "sJcsqlrN69C-tIddS")
        .then(() => {
          setFormData({
            name: "",
            companyName: "",
            position: "",
            email: "",
            teamSize: null,
            how: "",
            message: "",
          });
          alert(`${t("toast.title")} - ${t("toast.subtitle")}`);
        })
        .catch((e) => {
          console.error(e);
          // Open an error notification
          alert(`Something went wrong - please try again.`);
        })
        .finally(() => setLoading(false));
    };

    return (
      <div className="w-full md:py-12 py-3 flex justify-center text-gray-700">
        <div className="max-w-screen-xl w-full h-full flex flex-col-reverse md:flex-row items-center px-4 md:space-x-16 md:px-16 md:space-y-0 pt-32">
          <div className="flex md:flex-row flex-col justify-between md:space-x-24 h-full md:items-center">
            <div className="w-full md:w-1/2">
              <h2 className="text-5xl mb-6 font-semibold">Contact us</h2>
              <h3 className="mb-6 text-gray-500 break-keep">{t("subtitle1")}</h3>
              <h3 className="mb-6 text-gray-500 break-keep">{t("subtitle2")}</h3>
              <form className="space-y-5 w-full">
                <Input
                  value={formData.name}
                  placeholder={t("name")}
                  id="name"
                  onChange={handleChange}
                  className="h-11"
                />
                <div className="flex w-full flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3">
                  <Input
                    value={formData.companyName}
                    placeholder={t("companyName")}
                    id="companyName"
                    onChange={handleChange}
                    className="h-11 w-full"
                  />
                  <Input
                    value={formData.position}
                    placeholder={t("position")}
                    id="position"
                    onChange={handleChange}
                    className="h-11 w-full"
                  />
                </div>
                <Input
                  placeholder={t("email")}
                  value={formData.email}
                  type="email"
                  id="email"
                  onChange={handleChange}
                  className="h-11 w-full"
                />
                <Select
                  placeholder={t("teamSize")}
                  id="teamSize"
                  onChange={(value) => handleChange({ target: { id: "teamSize", value: value } })}
                  className="h-11 max-w-xs md:max-w-full w-full"
                  value={formData.teamSize}
                >
                  <Option value="TradDocs 사용을 희망하는 팀원의 수">{t("teamSize")}</Option>
                  <Option value="1~2">1~2</Option>
                  <Option value="3~4">3~4</Option>
                  <Option value="5~10">5~10</Option>
                  <Option value="11+">11+</Option>
                </Select>
                <Input
                  placeholder={t("how")}
                  id="how"
                  value={formData.how}
                  onChange={handleChange}
                  className="h-11 w-full"
                />
                <TextArea
                  rows={4}
                  multiple
                  id="message"
                  value={formData.message}
                  placeholder={t("message")}
                  onChange={handleChange}
                  className="h-11 w-full"
                />

                <Button
                  onClick={handleSubmit}
                  type="primary"
                  loading={isLoading}
                  className="h-12 bg-blue-500 w-full text-white text-lg font-medium"
                >
                  {t("submit")}
                </Button>
              </form>
            </div>
            <div className="w-full h-full md:w-1/2 break-keep bg-gray-50 mt-24 md:mt-0">
              <div className="w-full md:h-80 rounded-lg border bg-white shadow-lg flex flex-col md:flex-row overflow-hidden">
                <img
                  src={"https://s3.traddocs.com/landing/sample_person.jpg"}
                  alt=""
                  className="h-full w-full md:w-60 max-h-72 md:max-h-80 object-cover object-top"
                  draggable={false}
                />
                <div className="w-full h-full p-5">
                  <img
                    src={"https://s3.traddocs.com/landing/sample_logo.png"}
                    alt=""
                    className="h-14 object-contain mb-6"
                    draggable={false}
                  />

                  <p className="break-keep mb-8">"{t("quote")}"</p>

                  <p className="font-medium mb-2">{t("quoteName")}</p>
                  <p className="text-gray-400">{t("quotePosition")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <SEOMetaTag
        title={t("title")}
        description={t("description")}
        keywords={t("keywords")}
        url="https://www.traddocs.com/contact-us"
      />
      <ContactSection />
      <FAQ />
      <Footer />
    </>
  );
};

export default Contact;
