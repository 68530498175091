export const cleanString = (str) => str?.replace(/[^A-Z0-9]/gi, "").toUpperCase();

export const camelCaseToNormalText = (camelCaseString) => {
  // Insert a space before each uppercase letter
  const normalText = camelCaseString
    .replace(/([A-Z])/g, " $1") // Add space before each uppercase letter
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter

  return normalText;
};
