import React from "react";
import TradeDocumentsTable from "./TradeDocumentsTable";
import RequiredDocuments from "../Dashboard/RequiredDocuments";
// import ImpfoodTab from "./ImpfoodTab";
import ContractTable from "./ContractTable";

const TradeDocumentsTab = ({
  projectId,
  files,
  splitterData,
  comparedData,
  comparedInvoiceData,
  contractData,
  comparedLcData,
  comparedDiData,
  contractsData,
  archiveInfo,
  comparedFetched,
  initiallyFetched,
  splitterFetched,
  getArchive,
  getFiles,
  setExtractFetched,
  setSplitterFetched,
  setComparedFetched,
  setInitiallyFetched,
}) => {
  console.log("ASDASD", splitterData);
  return (
    <div className="w-full rounded-lg flex space-x-3 bg-gray-100 pb-4">
      <div className="flex flex-col w-full h-full space-y-3">
        <ContractTable
          archiveInfo={archiveInfo}
          splitterData={splitterData}
          files={files}
          comparedFetched={comparedFetched}
          contractsData={contractsData}
          projectId={projectId}
          getArchive={getArchive}
          getFiles={getFiles}
          setExtractFetched={setExtractFetched}
          setSplitterFetched={setSplitterFetched}
          setComparedFetched={setComparedFetched}
          setInitiallyFetched={setInitiallyFetched}
        />
        <TradeDocumentsTable
          files={files}
          splitterData={splitterData}
          comparedData={comparedData}
          comparedInvoiceData={comparedInvoiceData}
          contractData={contractData}
          comparedLcData={comparedLcData}
          comparedDiData={comparedDiData}
          projectId={projectId}
          comparedFetched={comparedFetched}
          archiveInfo={archiveInfo}
          splitterFetched={splitterFetched}
          initiallyFetched={initiallyFetched}
          getArchive={getArchive}
          getFiles={getFiles}
          setExtractFetched={setExtractFetched}
          setSplitterFetched={setSplitterFetched}
          setComparedFetched={setComparedFetched}
          setInitiallyFetched={setInitiallyFetched}
        />
        {/* <ImpfoodTab splitterData={splitterData} files={files} splitterFetched={splitterFetched} /> */}
      </div>
      <div className="flex flex-col w-1/3 h-full space-y-3">
        <RequiredDocuments
          splitterData={splitterData}
          initiallyFetched={initiallyFetched}
          archiveInfo={archiveInfo}
          projectId={projectId}
          getArchive={getArchive}
          comparedFetched={comparedFetched}
        />
      </div>
    </div>
  );
};

export default TradeDocumentsTab;
