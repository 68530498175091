import React from "react";

const FolderDashboardHeader = ({ title, action, subtitle }) => {
  return (
    <div className={`flex items-center px-3 space-x-3 border-gray-200 justify-between ${subtitle ? "h-16" : "h-12"} `}>
      <div>
        <p className="text-sm font-medium">{title}</p>
        {subtitle && <p className="text-xs text-gray-400 mt-1">{subtitle}</p>}
      </div>

      {action}
    </div>
  );
};

export default FolderDashboardHeader;
