const contact = {
  mode: "en",
  subtitle1:
    "Contact our sales team to find out how TradDocs' experts or business plans can help importers and exporters.",
  subtitle2: "Product support? Send an email to info@traddocs.com.",
  name: "Name",
  companyName: "Company name",
  position: "Job title",
  email: "Work email",
  teamSize: "Number of team members interested in using TradDocs",
  how: "How did you hear about us?",
  message: "Tell us about your case",
  submit: "Submit",

  quote:
    "TradDocs improved the efficiency of our operations dramatically. We can save a significant amount of time and focus on sales.",
  quoteName: "Jun Odesha",
  quotePosition: "Import Team Assistant",

  toast: {
    title: "Inquiry sent!",
    subtitle: "We'll get back to you within 1 business day.",
  },

  //SEO
  title: "Contact TradDocs - Get in Touch with Our Team",
  description:
    "Reach out to TradDocs for inquiries about our AI-driven trade document automation services and how we can assist your business.",
  keywords:
    "Contact TradDocs, Trade Document Automation Inquiry, Business Assistance with AI, Connect with TradDocs, Trade Finance Support",
};

export default contact;
