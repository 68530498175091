import React, { useState } from "react";
import RecognizedGrid from "./RecognizedGrid";
import StreamLoadingPopup from "./StreamLoadingPopup";
import ChangeFolderNameModal from "../modals/ChangeFolderNameModal";
import FolderDashboardHeader from "../FolderView/FolderDashboard/FolderDashboardHeader";

const RequiredDocuments = ({ splitterData, initiallyFetched, archiveInfo, projectId, getArchive, comparedFetched }) => {
  const [isFolderNameModalOpen, setFolderNameModalOpen] = useState(false);

  return (
    <div className="w-full space-y-4 flex-shrink-0 rounded-lg overflow-hidden shadow">
      <div className="w-full h-full flex flex-col bg-white relative">
        <FolderDashboardHeader title="Required Documents" />

        <div className="overflow-y-auto relative px-3 pb-3">
          <div className="">
            {!(initiallyFetched || comparedFetched) && <StreamLoadingPopup />}
            <RecognizedGrid splitterData={splitterData} />
          </div>
        </div>
      </div>
      <ChangeFolderNameModal
        open={isFolderNameModalOpen}
        setOpen={setFolderNameModalOpen}
        currentName={archiveInfo?.archive_name}
        projectId={projectId}
        getArchive={getArchive}
      />
    </div>
  );
};

export default RequiredDocuments;
