import React, { useCallback, useContext, useEffect, useState } from "react";
import FoldersTable from "./FoldersTable";
import { BiPlus } from "react-icons/bi";
import axios from "../../utils/authAxios";
import CreateFolderModal from "../modals/CreateFolderModal";
import moment from "moment";
import { AuthContext } from "../../context/authContext";

const FoldersView = ({ teamData }) => {
  const [folders, setFolders] = useState([]);
  const [isCreateFolderModalOpen, setCreateFolderModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const isEmpty = folders.length === 0;
  const { userState } = useContext(AuthContext);
  const userInfo = userState.user;

  const currentMonth = moment().format("MMMM");
  const nextMonth = moment().add(1, "months").format("MMMM");

  const fetchArchives = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/v1/archives`, {
        params: { team_id: teamData?.team_id || null },
      });

      setFolders(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [teamData]);

  useEffect(() => {
    fetchArchives();
  }, [fetchArchives]);

  const DashboardHeader = () => (
    <div className="w-full bg-white border-b pr-4 p-2 flex justify-between items-center">
      <div className="flex space-x-4 items-center">
        {teamData ? (
          <div className="h-14 w-14 bg-gray-200 bg-opacity-40 rounded-lg flex-shrink-0 text-7xl font-medium text-white flex items-center justify-center overflow-hidden border">
            <img className="w-full h-full" draggable={false} src={teamData.team_img} alt="" />
          </div>
        ) : (
          <div className="h-14 w-14 bg-white flex-shrink-0 bg-gradient-to-br from-fuchsia-400 to-fuchsia-300 text-4xl font-medium text-white flex items-center justify-center rounded-lg">
            {userInfo.userName.slice(0, 1)}
          </div>
        )}
        <div className="">
          <p className="text-xl font-semibold">
            {teamData ? `${teamData.team_name}'s Team` : `${userInfo.userName}'s Team`}
          </p>
          <div className="flex items-center space-x-2">
            <p className="text-xs text-gray-400">
              Current usage period ({currentMonth} 3 to {nextMonth} 2)
            </p>
            <div className="bg-gray-100 text-xs font-medium text-gray-500 px-2 py-1 rounded">
              {teamData ? "Master Plan" : "Free Trial Plan"}
            </div>
          </div>
        </div>
      </div>
      {!isEmpty && (
        <button
          className="bg-blue-600 text-white text-sm font-medium border-none flex items-center justify-center rounded-lg hover:bg-blue-500 transition space-x-2 py-2 px-4 flex-shrink-0"
          onClick={() => setCreateFolderModalOpen(true)}
        >
          <BiPlus className="w-5 h-5" />
          <p className="font-medium">New Workspace</p>
        </button>
      )}
    </div>
  );

  return (
    <div className="w-full h-full overflow-auto bg-gray-100 text-gray-700">
      <CreateFolderModal
        open={isCreateFolderModalOpen}
        setOpen={setCreateFolderModalOpen}
        fetchArchives={fetchArchives}
        type="new"
      />

      <DashboardHeader />
      <div className="w-full bg-gray-100 rounded-lg p-3">
        <FoldersTable
          folders={folders}
          isLoading={isLoading}
          setCreateFolderModalOpen={setCreateFolderModalOpen}
          isEmpty={isEmpty}
        />
      </div>
    </div>
  );
};

export default FoldersView;
