import AWS from "aws-sdk";
import axios from "./authAxios";

// This function uploads multiple files to AWS S3 and returns a promise with an array of objects containing the file names
export async function uploadDocument(imageFiles, archiveId, documentId) {
  // Fetch AWS credentials once for all uploads
  const response = await axios.get("v1/services/storage-access");
  const s3 = new AWS.S3({
    accessKeyId: response.data.access_key,
    secretAccessKey: response.data.secret_access_key,
    sessionToken: response.data.session_token,
    region: response.data.region,
  });

  // Function to upload a single file and return its document name
  const uploadFileAndGetDocumentName = async (imageFile) => {
    const extension = imageFile.name.substring(imageFile.name.lastIndexOf("."), imageFile.name.length).toLowerCase();
    const projectPath = `documents/${archiveId}/source/${documentId}${extension}`;
    // No need to create a Blob here, S3 ManagedUpload can handle File objects directly
    const uploadToPath = async (path) => {
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: response.data.bucket,
          Key: path,
          Body: imageFile, // Directly use the File object
          ContentType: imageFile.type,
        },
        service: s3,
      });

      await upload.promise();
    };

    // Upload to both project path and training path simultaneously
    await Promise.all([uploadToPath(projectPath)]);
    // Return the document name
    return {
      document_name: imageFile.name.substring(0, imageFile.name.lastIndexOf(".")) + extension,
    };
  };

  // Map over imageFiles to upload each one and construct an array of promises
  const uploadPromises = imageFiles.map((file) => uploadFileAndGetDocumentName(file));
  // Wait for all promises to resolve and return the array of objects with 'document_name'
  return Promise.all(uploadPromises);
}
