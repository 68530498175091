import { Modal, Spin, notification } from "antd";
import React, { useState } from "react";
import axios from "../../utils/authAxios.js";
import { uploadDocument } from "../../utils/uploadDocument.js";
import { FaCircleCheck, FaRegTrashCan } from "react-icons/fa6";
import DropComponent from "../dropComponent/DropComponent.jsx";
import { useSearchParams } from "react-router-dom";
import { RxFile } from "react-icons/rx";
import { formatFileSize } from "../../utils/formatFileSize.js";

const AddFilesModal = ({
  setOpen,
  open,
  archiveId,
  getArchive,
  getFiles,
  setExtractFetched,
  setSplitterFetched,
  setComparedFetched,
  setInitiallyFetched,
}) => {
  const [, setSearchParams] = useSearchParams();

  const [isDocLoading, setDocLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [hasError] = useState(false);

  const reset = () => {
    window.location.reload();
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setDocLoading(false);
    setSelectedFiles([]);
    setOpen(false);
  };

  // END:MODAL FUNCTIONS ======
  // START:UPLOAD FUNCTIONS ======
  const openNotification = () => {
    api.info({
      icon: <FaCircleCheck className="text-blue-500 w-6 h-6 opacity-90" />,
      message: <p className="text-base font-semibold text-gray-700">{"Folder Created Successfully"}</p>,
      description: (
        <p className="text-sm text-gray-500 break-keep">
          {"The documents are being uploaded and processed. This may take a few moments."}
        </p>
      ),
      placement: "top",
    });
  };

  const uploadToExistingFolder = (event) => {
    if (!selectedFiles) {
      console.error("No files selected");
      return;
    }

    const updatedFilesResponse = selectedFiles.map((item) => ({
      document_name:
        item.name.substring(0, item.name.lastIndexOf(".")) +
        item.name.substring(item.name.lastIndexOf("."), item.name.length).toLowerCase(),
    }));

    if (archiveId) {
      axios.post(`/v1/documents`, { archive_id: archiveId, documents: updatedFilesResponse }).then((secondResponse) => {
        const documentPaths = secondResponse.data.map((doc, index) => {
          const extension = selectedFiles[index].name.split(".").pop().toLowerCase();
          return `${doc.document_id}.${extension}`;
        });

        const uploadPromises = secondResponse.data.map((doc, index) =>
          uploadDocument([selectedFiles[index]], archiveId, doc.document_id)
        );

        Promise.all(uploadPromises).then((filesResponses) => {
          if (filesResponses.length > 0) {
            axios.post(`/v1/documents/run`, { archive_id: archiveId, document_paths: documentPaths }).then(() => {
              getArchive();
              getFiles(archiveId);
              setSearchParams({ tab: 1 });
              setExtractFetched(false);
              setSplitterFetched(false);
              setComparedFetched(false);
              setInitiallyFetched(false);
              openNotification();
              handleCancel();
            });
          }
        });
      });
    }
  };

  const uploadAndRequest = () => {
    setDocLoading(true); // Indicate the start of the upload process
    uploadToExistingFolder();
  };
  // END:UPLOAD FUNCTIONS======

  const handleRemoveFile = (indexToRemove) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
  };

  return (
    <>
      <Modal
        open={open}
        title={
          <div className="font-WantedSans">
            <p className="text-lg">Upload more shipping documents</p>
          </div>
        }
        closeIcon={null}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={[
          <button
            key={"close"}
            className={`border text-gray-600 px-4 py-2 h-10 rounded-lg transition font-medium font-WantedSans`}
            onClick={handleCancel}
          >
            Close
          </button>,
          <button
            key={"start"}
            className={`${
              isDocLoading || selectedFiles.length === 0 ? "bg-gray-300" : "bg-blue-600"
            } text-white px-4 py-2 h-10 rounded-lg transition font-medium font-WantedSans ml-3`}
            disabled={selectedFiles.length === 0 || isDocLoading}
            onClick={() => (selectedFiles.length === 0 || isDocLoading ? null : uploadAndRequest())}
          >
            Create Workspace
          </button>,
        ]}
      >
        <div className="w-full h-full mt-6 mb-2">
          {hasError ? (
            <div className="py-8 bg-white w-full rounded-3xl bg-opacity-70 shadow-lg p-6 flex flex-col space-y-6 items-center justify-center h-96">
              <div className="flex flex-col items-center space-y-1 tracking-tight">
                <p className="text-gray-400">Couldn't read the files.</p>
                <p className="text-gray-400">Please try again later.</p>
              </div>
              <button
                onClick={reset}
                className="flex items-center space-x-2 text-blue-500 py-2 px-4 rounded-xl bg-gray-100 hover:text-blue-700 font-medium hover:bg-gray-200 transition"
              >
                <p className="text-sm flex-shrink-0">Retry</p>
              </button>
            </div>
          ) : isDocLoading ? (
            <div className="h-64 bg-white w-full rounded-3xl bg-opacity-70 p-6 flex flex-col space-y-6 items-center justify-center">
              <div className="flex flex-col items-center space-y-1 tracking-tight">
                <Spin size="large" className="pb-4" />
                <p className="text-gray-400">Please wait while we upload your document.</p>
              </div>
            </div>
          ) : (
            <div className="">
              <div className="h-64 bg-white w-full rounded-3xl bg-opacity-70 font-WantedSans">
                <DropComponent
                  subtitle="PDF files up to 20MB"
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                />
              </div>
              {selectedFiles.length > 0 && (
                <div className="flex flex-col items-start py-1 space-y-1">
                  {selectedFiles.map((item, index) => (
                    <button
                      key={index}
                      onClick={() => handleRemoveFile(index)} // Add the onClick event handler
                      className="text-sm py-1 flex items-center space-x-2 border rounded w-full px-2 justify-between"
                    >
                      <div className="flex items-center space-x-2">
                        <div className="w-10 h-10 rounded bg-white flex items-center justify-center text-gray-500 flex-shrink-0">
                          <RxFile className="w-5 h-5" />
                        </div>

                        <div className="flex flex-col items-start">
                          <p className="text-sm text-gray-700 font-medium text-left">{item.name}</p>
                          <p className="text-xs text-gray-400">{formatFileSize(item.size)}</p>
                        </div>
                      </div>

                      <FaRegTrashCan className="w-6 h-4 text-gray-500 hover:text-red-500" />
                    </button>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        {contextHolder}
      </Modal>
    </>
  );
};

export default AddFilesModal;
