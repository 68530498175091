import React from "react";
import { useTranslation } from "react-i18next";
import SEOMetaTag from "../../utils/SEOMetaTag";

const Terms = () => {
  const { t } = useTranslation("terms");

  const WelcomeSection = () => (
    <div className="w-full flex justify-center relative">
      <div className="max-w-screen-lg w-full h-full flex items-center px-4 md:px-16 z-20 text-center py-20">
        <div className="w-full flex flex-col justify-center items-center text-gray-700 break-keep">
          <h1 className="font-bold text-5xl md:text-5xl leading-tight">{t("introductionTitle")}</h1>
          <h2 className="mt-8 md:mt-10 break-keep text-base md:text-lg text-gray-500 leading-relaxed">
            {t("introductionUpdate")}
          </h2>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <SEOMetaTag
        title={t("seo.title")}
        description={t("seo.description")}
        keywords={t("seo.keywords")}
        url="https://www.traddocs.com/legal/terms"
      />
      <div className="container px-4 text-lg text-gray-600 break-keep w-full flex flex-col items-center mb-20 pt-32">
        <WelcomeSection />
        <div className="max-w-screen-lg w-full h-full flex flex-col px-4 md:px-16 z-20">
          <p className="mb-4">{t("introductionContent")}</p>

          <h2 className="text-2xl font-semibold my-4">{t("acknowledgementTitle")}</h2>
          <p className="mb-4">{t("acknowledgementContent")}</p>

          <h2 className="text-2xl font-semibold my-4">{t("eligibilityTitle")}</h2>
          <p className="mb-4">{t("eligibilityContent")}</p>

          <h2 className="text-2xl font-semibold my-4">{t("accountInformationTitle")}</h2>
          <p className="mb-4">{t("accountInformationContent")}</p>

          <h2 className="text-2xl font-semibold my-4">{t("whatWeOwnTitle")}</h2>
          <p className="mb-4">{t("whatWeOwnContent")}</p>

          <h2 className="text-2xl font-semibold my-4">{t("userContentTitle")}</h2>
          <p className="mb-4">{t("userContentContent")}</p>

          <h2 className="text-2xl font-semibold my-4">{t("rulesTitle")}</h2>
          <p className="mb-4">{t("rulesContent")}</p>

          <h2 className="text-2xl font-semibold my-4">{t("thirdPartyContentTitle")}</h2>
          <p className="mb-4">{t("thirdPartyContentContent")}</p>

          <h2 className="text-2xl font-semibold my-4">{t("billingAndPaymentTitle")}</h2>
          <p className="mb-4">{t("billingAndPaymentContent")}</p>

          <h2 className="text-2xl font-semibold my-4">{t("privacyTitle")}</h2>
          <p className="mb-4">{t("privacyContent")}</p>

          <h2 className="text-2xl font-semibold my-4">{t("disclaimerTitle")}</h2>
          <p className="mb-4">{t("disclaimerContent")}</p>

          <h2 className="text-2xl font-semibold my-4">{t("aiDisclaimerTitle")}</h2>
          <p className="mb-4">{t("aiDisclaimerContent")}</p>

          <h2 className="text-2xl font-semibold my-4">{t("emergencyServicesTitle")}</h2>
          <p className="mb-4">{t("emergencyServicesContent")}</p>

          <h2 className="text-2xl font-semibold my-4">{t("serviceChangesTitle")}</h2>
          <p className="mb-4">{t("serviceChangesContent")}</p>

          <h2 className="text-2xl font-semibold my-4">{t("copyrightPolicyTitle")}</h2>
          <p className="mb-4">{t("copyrightPolicyContent")}</p>

          <h2 className="text-2xl font-semibold my-4">{t("disputeResolutionTitle")}</h2>
          <p className="mb-4">{t("disputeResolutionContent")}</p>

          <h2 className="text-2xl font-semibold my-4">{t("miscellaneousTitle")}</h2>
          <p className="mb-4">{t("miscellaneousContent")}</p>
        </div>
      </div>
    </>
  );
};

export default Terms;
