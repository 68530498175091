import React from "react";
import { camelCaseToNormalText } from "../../utils/cleanString";

const ExtractResults = ({ documentId, extractData, splitIdPath }) => {
  const extractionData = extractData?.[documentId]?.[splitIdPath];

  const KeyValueDisplay = ({ label, value }) => {
    const renderValue = (value) => {
      if (typeof value === "boolean") {
        return value ? "True" : "False";
      } else if (Array.isArray(value)) {
        // Handle arrays, which could contain objects or primitive values
        return value.length > 0
          ? value.map((item, index) => (
              <div key={index}>
                {typeof item === "object" && item !== null ? (
                  <div>
                    {Object.entries(item).map(([key, val], index) => (
                      <div
                        key={index}
                        className={`${
                          typeof val === "string"
                            ? "flex-row"
                            : Array.isArray(val) && val.length === 0
                            ? "flex-row"
                            : typeof val === "object" && (val === null || Object.keys(val).length === 0)
                            ? "flex-row"
                            : "flex-col ml-4"
                        } flex w-full`}
                      >
                        <div className="w-40 flex space-x-2 flex-shrink-0">
                          <div className="h-7 flex items-center text-sky-600 font-medium text-xs flex-shrink-0">
                            • {camelCaseToNormalText(key)}
                          </div>
                        </div>
                        <div className="w-full text-gray-600">{renderValue(val)}</div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>{item}</div>
                )}
              </div>
            ))
          : "-";
      } else if (typeof value === "object" && value !== null) {
        // Handle objects
        return Object.entries(value).map(([key, val], index) => (
          <div
            key={index}
            className={`${
              typeof val === "string"
                ? "flex-row"
                : Array.isArray(val) && val.length === 0
                ? "flex-row"
                : typeof val === "object" && (val === null || Object.keys(val).length === 0)
                ? "flex-row"
                : "flex-col ml-4"
            } flex w-full`}
          >
            <div className="w-40 h-7 flex items-center text-sky-600 font-medium text-xs flex-shrink-0">
              • {camelCaseToNormalText(key)}
            </div>
            <div className="w-full text-gray-600">{renderValue(val)}</div>
          </div>
        ));
      }
      return <div className="min-h-7 flex items-center">{value || "-"}</div>;
    };

    return (
      <div
        className={`${
          typeof value === "string"
            ? "flex-row"
            : Array.isArray(value) && value.length === 0
            ? "flex-row"
            : typeof value === "object" && (value === null || Object.keys(value).length === 0)
            ? "flex-row"
            : "flex-col"
        } flex w-full p-2 px-3 text-xs bg-white shadow rounded-lg`}
      >
        <div className="w-40 flex space-x-2 flex-shrink-0">
          <div className="h-7 flex items-center text-sky-600 font-medium text-xs flex-shrink-0">
            • {camelCaseToNormalText(label)}
          </div>
        </div>
        <div className="w-full text-gray-600 ml-4">{renderValue(value)}</div>
      </div>
    );
  };

  const ListHeader = ({ title }) => <div className="font-medium text-sky-800 text-xs py-2 border-t px-2">{title}</div>;

  return (
    <div className="relative flex w-full">
      <div className="w-full pb-4 bg-gray-100">
        <ListHeader title="GENERAL INFORMATION" />
        <div className="space-y-1 w-full rounded-lg overflow-hidden px-2">
          {extractionData &&
            Object.entries(extractionData).map(([key, value], index) => (
              <KeyValueDisplay key={index} label={key} value={value} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ExtractResults;
