import { notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FiUpload } from "react-icons/fi";
import { IoMdRemoveCircleOutline } from "react-icons/io";

const DropComponentSmall = ({ title, setSelectedFiles, selectedFiles, multiple }) => {
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to leave?";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };
    if (selectedFiles.length > 0) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [selectedFiles]);

  const onDragEnter = useCallback(() => {
    setIsDragging(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      setIsDragging(false);
      if (fileRejections.length > 0) {
        openNotificationWithIcon(
          "error",
          <p className="font-WantedSans break-keep">
            {fileRejections[0].errors[0].code === "file-invalid-type"
              ? "Unsupported File Format"
              : "Only One File Allowed"}
          </p>,
          <p className="text-sm font-WantedSans text-gray-500">
            {fileRejections[0].errors[0].code === "file-invalid-type"
              ? "Currently, we only support PDF and DOCX files."
              : "Please upload only one file at a time."}
          </p>
        );
      }

      // Concatenate new files to the existing list
      setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
    [setSelectedFiles]
  );

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  const handleRemoveFile = (indexToRemove) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDragEnter,
    onDragLeave,
    onDrop,
    multiple: multiple, // Disallow multiple file uploads
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      // "image/jpeg": [".jpeg", ".jpg"],
      // "image/png": [".png"],
      // "image/gif": [".gif"],
    },
  });

  if (multiple)
    return (
      <div className="">
        <div
          {...getRootProps()}
          className={`${
            isDragging
              ? "border-blue-500 bg-blue-100"
              : "border-gray-300 hover:border-blue-500 bg-gray-50 hover:bg-blue-50"
          }  relative w-full rounded-lg border-dashed border flex items-center cursor-pointer transition h-14`}
        >
          <input {...getInputProps()} id="fileUpload" style={{ display: "none" }} />

          <div className="flex items-center w-full px-3 space-x-3">
            <FiUpload className="w-4 h-4 opacity-90 text-gray-400" />
            <div className="space-x-1 flex items-center">
              <p className="text-xs text-gray-700">
                Upload or Drop <span className="font-semibold text-blue-500">{title}</span>{" "}
                {multiple && " - Multiple files allowed"}
              </p>
            </div>
          </div>
        </div>

        {selectedFiles.length > 0 && (
          <div className="flex flex-col items-start space-y-1 mt-1">
            {selectedFiles.map((item, index) => (
              <div
                key={index}
                className="text-sm flex items-center space-x-3 rounded-lg w-full px-2 pr-1 justify-between h-5"
              >
                <div className="flex items-center w-full space-x-3">
                  <div className="flex flex-col items-start">
                    <p className="text-xs text-gray-500 text-left">{item.name}</p>
                  </div>
                </div>
                <button onClick={() => handleRemoveFile(index)}>
                  <IoMdRemoveCircleOutline className="w-4 h-4 text-gray-400 hover:text-red-500" />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  else
    return (
      <div className="w-full max-w-60">
        {selectedFiles.length > 0 ? (
          <div className="flex flex-col items-start space-y-1">
            {selectedFiles.map((item, index) => (
              <div key={index} className="text-sm flex items-center rounded-lg p-3 w-full h-24 border relative shadow">
                <div className="flex flex-col items-start w-full h-full">
                  <div className="flex items-center justify-between w-full">
                    <p className="text-xs text-blue-600 font-medium flex-shrink-0">{title}</p>
                    <button className="-mr-1.5 -mt-1.5" onClick={() => handleRemoveFile(index)}>
                      <IoMdRemoveCircleOutline className="w-4 h-4 text-gray-400 hover:text-red-500" />
                    </button>
                  </div>
                  <p className="text-xs text-gray-500 text-left line-clamp-3 break-all mt-2">{item.name}</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div
            {...getRootProps()}
            className={`${
              isDragging ? "border-blue-500 bg-blue-100" : "border-gray-300 hover:border-blue-500 bg-gray-50"
            } relative w-full h-24 rounded-lg border-dashed border flex items-center cursor-pointer transition hover:bg-blue-50`}
          >
            <input {...getInputProps()} id="fileUpload" style={{ display: "none" }} />
            <div className="flex flex-col items-center w-full px-2 space-y-2">
              <FiUpload className="w-6 h-6 opacity-90 text-gray-400" />
              <div className="flex flex-col items-center text-xs text-gray-700 text-center">
                <p>Upload or Drop</p>
                <p style={{ whiteSpace: "pre-wrap" }} className="">
                  <span className="font-medium text-blue-500">{title}</span>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
};

export default DropComponentSmall;
