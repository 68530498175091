const footer = {
  mode: "en",
  menu: {
    company: "Company",
    contact: "Contact",
    pricing: "Pricing",
    blog: "Blog",
    terms: "Terms of Service",
    privacy: "Privacy Policy",
  },
  conclusionTitle: "Streamline your document workflow",
  conclusionSubtitle: "Curious how TradDocs can help you? Let’s talk.",
  demo: "Start now for free",
  inquire: "Contact Us",
  address1: "122 Mapo-daero, Gongdeok-dong, Mapo-gu, Seoul",
  address2: "11th Floor, FrontOne",
  address3: "04213",
};

export default footer;
