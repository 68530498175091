import React from "react";
import { Collapse } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t } = useTranslation("faq");

  const FAQArray = [
    {
      question: t("q1"),
      answer: t("a1"),
    },
    {
      question: t("q2"),
      answer: t("a2"),
    },
    {
      question: t("q3"),
      answer: t("a3"),
    },
    {
      question: t("q4"),
      answer: t("a4"),
    },
    {
      question: t("q5"),
      answer: t("a5"),
    },
    {
      question: t("q6"),
      answer: t("a6"),
    },
    {
      question: t("q7"),
      answer: t("a7"),
    },
    {
      question: t("q8"),
      answer: t("a8"),
    },
    {
      question: t("q9"),
      answer: t("a9"),
    },
  ];
  return (
    <div className="w-full flex justify-center relative bg-gray-50">
      <div className="max-w-screen-xl w-full h-full flex flex-col md:flex-row px-4 md:px-16 z-20 text-center py-20 md:space-x-8">
        <div className="w-full md:w-2/5 flex flex-col items-start mb-12">
          <p className="text-6xl font-bold text-gray-700">FAQs</p>
          <div className="mt-4 text-lg break-keep">
            <p className="inline text-gray-500">{t("subtitle")}</p>
            <Link to="/contact-us">
              <p className="inline text-blue-500">{t("action")}</p>
            </Link>
          </div>
        </div>

        <div className="w-full space-y-4">
          {FAQArray.map((item, index) => (
            <Collapse
              key={index}
              size="large"
              className="bg-white text-left text-gray-700"
              items={[
                {
                  key: index,
                  label: item.question,
                  children: <p className="break-keep">{item.answer}</p>,
                },
              ]}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
