const contact = {
  mode: "ko",
  subtitle1:
    "TradDocs의 전문가 또는 기업 플랜이 수입자와 수출자를 어떻게 도울 수 있는지, 영업 팀에 문의하여 확인해보세요.",
  subtitle2: "서비스 지원이 필요하신가요? info@traddocs.com으로 메일을 보내주세요.",
  name: "담당자 이름",
  companyName: "회사명",
  position: "직책 / 직함",
  email: "이메일 주소",
  teamSize: "TradDocs 사용을 희망하는 팀원의 수",
  how: "TradDocs를 어떻게 알게 되었나요?",
  message: "본문 작성",
  submit: "문의하기",

  quote:
    "TradDocs를 사용한 이후로 서무 업무 효율성이 눈에 띄게 변했습니다. 시간을 대폭 절약할 수 있게 되었고 영업 활동에 집중할 수 있게 되었어요.",
  quoteName: "박준혁",
  quotePosition: "수입팀 대리",

  toast: {
    title: "문의 완료",
    subtitle: "1영업일 안에 연락드리겠습니다.",
  },

  //SEO
  title: "문의하기 | 트레독스 TradDocs",
  description: "트레독스의 AI 기반 선적서류 자동화 서비스에 대한 문의 및 비즈니스 협력.",
  keywords: "트레독스 문의, 선적서류 자동화 문의, AI 비즈니스 지원, 트레독스 연결, 무역 금융 지원",
};

export default contact;
