import React from "react";
import { useTranslation } from "react-i18next";
import SEOMetaTag from "../../utils/SEOMetaTag";

const Privacy = () => {
  const { t } = useTranslation("privacy");

  const WelcomeSection = () => (
    <div className="w-full flex justify-center relative">
      <div className="max-w-screen-lg w-full h-full flex items-center px-4 md:px-16 z-20 text-center py-20">
        <div className="w-full flex flex-col justify-center items-center text-gray-700 break-keep">
          <h1 className="font-bold text-5xl md:text-5xl leading-tight">{t("title")}</h1>
          <h2 className="mt-8 md:mt-10 break-keep text-base md:text-lg text-gray-500 leading-relaxed">
            {t("lastUpdated")}
          </h2>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <SEOMetaTag
        title={t("seo.title")}
        description={t("seo.description")}
        keywords={t("seo.keywords")}
        url="https://www.traddocs.com/legal/privacy"
      />
      <div className="container px-4 text-lg text-gray-600 break-keep w-full flex flex-col items-center mb-20 pt-32">
        <WelcomeSection />
        <div className="max-w-screen-lg w-full h-full flex flex-col px-4 md:px-16 z-20">
          <h2 className="mb-4">{t("introduction")}</h2>
          <p className="mb-4">{t("personalInformationDefinition")}</p>

          <p className="mb-4">{t("collectionUseSharing")}</p>

          <p className="mb-4">{t("thirdPartyDataSharing")}</p>

          <p className="mb-4">{t("userRights")}</p>

          <p className="mb-4">{t("dataSecurity")}</p>

          <p className="mb-4">{t("internationalTransfers")}</p>

          <p className="mb-4">{t("changesToPolicy")}</p>

          <p className="mb-4">{t("contactUs")}</p>
        </div>
      </div>
    </>
  );
};

export default Privacy;
