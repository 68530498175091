import { Button, Modal } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const SubscriptionModal = ({ setOpen, open }) => {
  const [loading, setLoading] = useState(false);
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const Card = ({ title, subtitle, price, type }) => (
    <div
      className={`${
        type === "현재" ? "border-gray-300 bg-gray-50" : "border-blue-500 bg-white shadow"
      } w-full mt-4 text-gray-700 px-6 py-4 border rounded-lg flex flex-col items-start`}
    >
      <div className="flex items-center justify-between w-full mb-2">
        <div className="flex items-center space-x-3">
          <p className="text-base font-medium">{title}</p>
          <div className="px-1.5 rounded-full h-5 flex items-center shadow">
            <span className={`${type === "현재" ? "text-gray-400" : "text-blue-500"} text-xs`}>{type}</span>
          </div>
        </div>
        <div className="flex items-end space-x-1">
          <p className="text-base font-semibold">{price}</p>
          <p className="text-sm font-normal text-gray-400">/월</p>
        </div>
      </div>
      <div className="w-full font-normal text-sm text-gray-500 text-left">
        <p className="break-keep inline">{subtitle} </p>
        <Link to={"/pricing"} className="hover:text-blue-500 transition w-auto inline">
          <button className="inline">
            <p>자세히 보기</p>
          </button>
        </Link>
      </div>
    </div>
  );
  return (
    <>
      <Modal
        open={open}
        title="요금제 선택"
        onOk={handleOk}
        onCancel={handleCancel}
        className="px-4"
        footer={[
          <Button className="px-5 h-10 rounded-lg transition font-semibold text-sm" key="back" onClick={handleCancel}>
            취소
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            className="text-white px-5 h-10 rounded-lg transition bg-blue-500 font-semibold text-sm"
            onClick={handleOk}
          >
            결제로 이동
          </Button>,
        ]}
      >
        <div className="w-full h-full text-center overflow-auto">
          <Card
            title={"무료 플랜"}
            subtitle={"개인 및 사이드잡으로 선적서류 하자체크를 하는 사람들을 위한 플랜입니다."}
            type={"현재"}
            price={"무료"}
          />
          <Card
            title={"전문가 플랜"}
            subtitle={"선적서류의 하자체크를 전문적으로 하는 사람들을 위한 플랜입니다."}
            type={"인기"}
            price={"3만원"}
          />
          <div className="mb-4 flex mt-4 items-center justify-between px-5 bg-gray-100 rounded-lg">
            <div className="space-y-1 flex flex-col items-start py-3">
              <p className="text-base font-semibold text-gray-600">팀 및 기업</p>
              <p className="text-sm text-gray-500">추가적으로 필요하신게 있으신가요?</p>
            </div>
            <Button className="px-4 h-10 rounded-lg transition bg-blue-100 text-blue-500 border-none font-medium text-sm">
              세일즈 문의하기
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SubscriptionModal;
