const privacy = {
  mode: "ko",
  title: "개인정보 보호 정책",
  lastUpdated: "2023년 1월 20일",
  introduction: "TradDocs, Inc. (이하 'TradDocs', '회사', '우리' 또는 '저희')는 방문자 및 고객의 개인 정보 보호를 중요시합니다. 저희는 TradDocs.com 및 해당 도메인 하의 웹사이트들('사이트'), 그리고 저희의 SaaS 제품, 웹 디자인 소프트웨어, 도구 및 관련 서비스를 통해 수집되는 정보를 적극적으로 보호하고 관리합니다.",

  personalInformationDefinition: "본 정책에서 '개인 정보'란 개인을 직접 또는 간접적으로 식별할 수 있는 정보를 말합니다. TradDocs는 제3자에게 개인 정보를 판매, 임대 또는 대여하지 않습니다. '사용 데이터'는 개인 식별 정보를 포함하지 않는 암호화되거나 익명화된 정보를 말하며, 서비스 사용 트렌드를 이해하는 데 도움이 됩니다.",

  collectionUseSharing: "서비스 등록 시, 이름, 이메일 주소, 결제 정보 등의 개인 정보를 요청할 수 있습니다. 사이트 사용 중에 제공하는 모든 정보는 TradDocs의 서비스 운영, 개선, 개인화에 사용될 수 있습니다. 이외에도, 서비스 개선 및 마케팅 목적으로 제3자와 정보를 공유할 수 있습니다.",

  thirdPartyDataSharing: "TradDocs는 제3자로부터 개인 정보를 수집할 수 있으며, 이는 서비스 제공에 필수적인 경우에만 이루어집니다. 제3자 서비스 제공업체와의 협력을 통해 서비스 품질을 향상시키고 사용자 경험을 개선합니다.",

  userRights: "사용자는 언제든지 자신의 개인 정보에 대한 접근, 수정, 삭제를 요청할 권리가 있습니다. TradDocs는 이러한 요청을 존중하며, 적절한 법적 요구사항을 준수합니다.",

  dataSecurity: "TradDocs는 개인 정보의 안전성과 보안을 보장하기 위해 업계 표준의 보안 조치를 사용합니다. 그러나 인터넷을 통한 어떠한 전송도 100% 안전하게 보장할 수 없으므로, 전송하는 데이터의 보안을 위한 책임은 사용자에게 있습니다.",

  internationalTransfers: "TradDocs는 전 세계적으로 운영되며, 이에 따라 다양한 국가의 데이터 보호법을 준수합니다. 국제적인 데이터 전송은 해당 국가의 법률을 준수하는 방식으로 이루어집니다.",

  changesToPolicy: "본 정책은 필요에 따라 수정될 수 있습니다. 중대한 변경 사항이 있을 경우, 웹사이트에 공지하거나 이메일을 통해 알려드릴 것입니다. 정책 변경에 동의하지 않을 경우, 서비스 사용을 중단해야 합니다.",

  contactUs: "개인정보 보호 정책이나 TradDocs의 개인정보 처리 방식에 대해 질문이 있는 경우, support@tradDocs.com으로 문의해 주세요.",
  seo: {
    title: "개인정보보호정책 | 트레독스 TradDocs",
    description: "트레독스의 개인정보보호정책에 대한 설명서",
    keywords: "개인정보, 보호 정책, 트레독스",
  },
};

export default privacy;
