import React from "react";
import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import { MdOutlineRotate90DegreesCcw, MdOutlineRotate90DegreesCw } from "react-icons/md";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";

const PDFPreviewControls = ({
  zoomLevel,
  setZoomLevel,
  rotationAngle,
  setRotationAngle,
  currentPage,
  setCurrentPage,
  numPages,
}) => {
  const zoomOut = () => {
    if (zoomLevel > 0.8) {
      setZoomLevel(zoomLevel - 0.1);
    }
  };
  const zoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };

  const nextPage = () => {
    if (currentPage < numPages - 1) setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    if (currentPage > 0) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="space-y-2 flex flex-col items-center h-10">
      <div className="rounded flex-shrink-0 h-full flex items-center text-white space-x-3 px-3">
        <button
          className="w-8 h-8 flex items-center justify-center hover:bg-black text-white rounded-full transition"
          onClick={() => setRotationAngle(rotationAngle - 90)}
        >
          <MdOutlineRotate90DegreesCcw className="w-5 h-5" />
        </button>
        <button
          className="w-8 h-8 flex items-center justify-center hover:bg-black text-white rounded-full transition"
          onClick={() => setRotationAngle(rotationAngle + 90)}
        >
          <MdOutlineRotate90DegreesCw className="w-5 h-5" />
        </button>
        <button
          className="w-8 h-8 flex items-center justify-center hover:bg-black text-white rounded-full transition"
          onClick={previousPage}
        >
          <GoChevronLeft className="w-7 h-7" />
        </button>

        <div className="bg-black bg-opacity-50 text-white rounded px-3 py-1">
          {currentPage + 1} / {numPages}
        </div>

        <button
          className="w-8 h-8 flex items-center justify-center hover:bg-black text-white rounded-full transition"
          onClick={nextPage}
        >
          <GoChevronRight className="w-7 h-7" />
        </button>

        <button
          onClick={zoomOut}
          className="w-8 h-8 flex items-center justify-center hover:bg-black text-white rounded-full transition"
        >
          <FiZoomOut className="w-5 h-5" />
        </button>
        <button
          onClick={zoomIn}
          className="w-8 h-8 flex items-center justify-center hover:bg-black text-white rounded-full transition"
        >
          <FiZoomIn className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default PDFPreviewControls;
