import { useCallback } from "react";
import axios from "../utils/authAxios";

const useFetchData = (setFiles, setArchiveInfo, setFilesFetched) => {
  const getArchive = useCallback(
    (projectId) => {
      axios
        .get(`/v1/archives/${projectId}`)
        .then((response) => {
          setArchiveInfo(response.data);
        })
        .catch((e) => console.log(e));
    },
    [setArchiveInfo]
  );

  const getFiles = useCallback(
    (projectId) => {
      axios
        .get(`/v1/archives/${projectId}/documents`)
        .then((response) => {
          setFiles(response.data);
          setFilesFetched(true);
        })
        .catch((e) => console.log(e));
    },
    [setFiles, setFilesFetched]
  );

  return {
    getArchive,
    getFiles,
    setFilesFetched,
  };
};

export default useFetchData;
