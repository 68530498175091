const seo = {
  title: "TradDocs - AI-Powered Trade Document Management Software",
  description:
    "TradDocs is the leading platform for automating international trade documents, including Letters of Credit and Bills of Lading, enhancing accuracy and efficiency in global trade.",
  keywords:
    "trade document automation, AI trade finance, Letter of Credit automation, Bills of Lading automation, trade finance solutions, secure document processing, international trade AI, efficient trade documentation, trade finance platform, document discrepancy inspection",
  ogTitle: "TradDocs - AI-Powered Trade Document Management Software",
  ogImage: "https://s3.traddocs.com/new_traddocs_og.png",
};

export default seo;