import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import Partners from "../components/Landing/Partners";
import { FaCheck } from "react-icons/fa6";
import { Link } from "react-router-dom";
import FAQ from "../components/Landing/FAQ";
import SEOMetaTag from "../utils/SEOMetaTag";

const Pricing = () => {
  const { t } = useTranslation("pricing");

  const SandboxArray = [t("sandbox_feature.1"), t("sandbox_feature.2"), t("sandbox_feature.3")];
  const IndividualArray = [
    t("individual_feature.1"),
    t("individual_feature.2"),
    t("individual_feature.3"),
    t("individual_feature.4"),
    t("individual_feature.5"),
  ];
  const ProfessionalArray = [
    t("professional_feature.1"),
    t("professional_feature.2"),
    t("professional_feature.3"),
    t("professional_feature.4"),
    t("professional_feature.5"),
    t("professional_feature.6"),
  ];

  const WelcomeSection = () => (
    <div className="w-full flex justify-center relative pt-32">
      <div className="max-w-screen-xl w-full h-full flex items-center px-4 md:px-16 z-20 text-center py-20">
        <div className="w-full flex flex-col justify-center items-center text-gray-700 break-keep">
          <h1 className="font-semibold text-5xl md:text-6xl leading-tight">{t("title")}</h1>
          <h2 className="mt-8 md:mt-10 break-keep text-base md:text-xl text-gray-500 leading-relaxed">
            {t("subtitle")}
          </h2>
        </div>
      </div>
    </div>
  );

  const PricingCard = ({ type, bgColor, title, subtitle, note, featureArray, footerNote }) => {
    return (
      <div className="w-full rounded-lg shadow-lg border bg-white overflow-hidden">
        <div
          style={{ backgroundColor: bgColor }}
          className="w-full h-40 flex flex-col justify-center items-center border-b"
        >
          <p className={`${type === "sandbox" ? "text-gray-700" : "text-white"} font-semibold text-3xl`}>{title}</p>
          <p className={`${type === "sandbox" ? "text-gray-500" : "text-white"} font-medium text-lg mt-2`}>
            {subtitle}
          </p>
          <p className={`${type === "sandbox" ? "text-gray-500" : "text-white"} mt-1 text-sm opacity-70`}>{note}</p>
        </div>
        <div style={{ height: "33rem" }} className="py-8 px-6 space-y-6">
          <p className="text-gray-500 py-1">
            {type === "sandbox"
              ? t("sandbox.featureHeader")
              : type === "individual"
              ? t("individual.featureHeader")
              : type === "professional"
              ? t("professional.featureHeader")
              : t("enterprise.featureHeader")}
          </p>
          {featureArray?.map((item) => (
            <div className="flex items-center">
              <div className="w-10 flex-shrink-0">
                <div
                  className={`${
                    type === "sandbox"
                      ? "bg-gray-600 text-white"
                      : type === "individual"
                      ? "bg-green-100 text-green-600"
                      : type === "professional"
                      ? "bg-blue-500 text-white"
                      : "bg-black text-white"
                  } w-4 h-4 rounded-full flex items-center justify-center`}
                >
                  <FaCheck className="w-2.5 h-2.5" />
                </div>
              </div>
              <p className="text-gray-500 break-keep">{item}</p>
            </div>
          ))}
        </div>

        <div className="w-full flex flex-col justify-end px-4 pb-4">
          <div className="h-14 w-full border rounded-lg overflow-hidden">
            <Link
              to={
                type === "sandbox"
                  ? "/auth/signup"
                  : type === "individual"
                  ? "/contact-us"
                  : type === "professional"
                  ? "/contact-us"
                  : "/contact-us"
              }
            >
              <button
                className={`${
                  type === "sandbox"
                    ? "bg-white text-gray-600"
                    : type === "individual"
                    ? "bg-green-600 text-white"
                    : type === "professional"
                    ? "bg-blue-600 text-white"
                    : "bg-black text-white"
                } w-full h-full font-semibold shadow hover:bg-opacity-75`}
              >
                {type === "sandbox"
                  ? "Start your free trial"
                  : type === "individual"
                  ? t("individual.button")
                  : type === "professional"
                  ? t("professional.button")
                  : t("enterprise.button")}
              </button>
            </Link>
          </div>
          <div className="w-full flex justify-center">
            <p className="mt-4 text-xs text-gray-500">{footerNote}</p>
          </div>
        </div>
      </div>
    );
  };

  const PricingTable = () => {
    return (
      <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-5 w-full">
        <PricingCard
          type="sandbox"
          bgColor="#F2F6F8"
          title={t("sandbox.title")}
          subtitle={t("sandbox.subtitle")}
          note={t("sandbox.note")}
          featureArray={SandboxArray}
          footerNote={t("sandbox.footerNote")}
        />
        <PricingCard
          type="individual"
          bgColor="#4EA660"
          title={t("individual.title")}
          subtitle={t("individual.subtitle")}
          note={t("individual.note")}
          featureArray={IndividualArray}
          footerNote={t("individual.footerNote")}
        />
        <PricingCard
          type="professional"
          bgColor="#1E6AD9"
          title={t("professional.title")}
          subtitle={t("professional.subtitle")}
          note={t("professional.note")}
          featureArray={ProfessionalArray}
          footerNote={t("professional.footerNote")}
        />
      </div>
    );
  };

  return (
    <>
      <SEOMetaTag
        title={t("seo.title")}
        description={t("seo.description")}
        keywords={t("seo.keywords")}
        url="https://www.traddocs.com/pricing"
      />
      <div className="flex h-full items-center flex-col sm:flex-row z-20 w-screen relative justify-center transition pb-20 bg-white">
        <div className="max-w-screen-xl w-full relative h-full flex px-4 flex-col items-center sm:items-start">
          <WelcomeSection />
          <PricingTable />
        </div>
      </div>
      <Partners />
      <FAQ />
      <Footer />
    </>
  );
};

export default Pricing;
