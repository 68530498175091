const pricing = {
  mode: "en",
  title: "Features & pricing",
  subtitle: "Simple pricing. Cancel anytime.",
  sandbox: {
    title: "Starter",
    subtitle: "Start for free now",
    note: "No credit card required",
    footerNote: "No credit card required",
    featureHeader: "Free to get started",
    button: "Join our Waitlist",
  },
  individual: {
    title: "Basic",
    subtitle: "Contact us",
    note: "Save 20% with annual billing",
    footerNote: "Credit card payment after 14-day free trial",
    featureHeader: "Everything in Starter plus...",
    button: "Contact us",
  },
  professional: {
    title: "Pro",
    subtitle: "Contact us",
    note: "Save 20% with annual billing",
    footerNote: "Credit card payment",
    featureHeader: "Everything in Individual plus...",
    button: "Contact us",
  },
  enterprise: {
    title: "Enterprise",
    subtitle: "Custom pricing",
    note: "Annual billing only",
    footerNote: "Payment by invoice",
    featureHeader: "Everything in Professional plus...",
    button: "Contact us",
  },
  sandbox_feature: {
    1: "10 inspections per month",
    2: "1GB storage space for docs",
    3: "Dashboard provided",
  },
  individual_feature: {
    1: "20 inspections per month",
    2: "Sync email for contract automation",
    3: "10GB storage space for docs",
    4: "Team member invitation",
    5: "Billing support",
  },
  professional_feature: {
    1: "Unlimited inspections",
    2: "Sync email for contract automation",
    3: "100GB storage space for documents",
    4: "Real-time collaboration feature",
    5: "Team member invitation",
    6: "Priority support",
  },
  enterprise_feature: {
    1: "Unlimited inspections",
    2: "L/C supported",
    3: "All shipping documents including B/L, C/I, C/O, P/L, and 3rd party certificates",
    4: "Unlimited storage space for documents",
    5: "Additional services",
  },
  seo: {
    title: "TradDocs Pricing",
    description: "Affordable pricing plans for TradDocs services",
    keywords: "pricing, plans, TradDocs, services",
  },
};

export default pricing;
