import { useCallback } from "react";
import axios from "axios";

const useFetchJson = () => {
  const fetchJson = useCallback((type, archiveId, documentId, fetchedDocumentIds, setData) => {
    if (fetchedDocumentIds.current.has(documentId)) return Promise.resolve();

    const url =
      type === "comparedInvoice"
        ? `https://s3.traddocs.com/documents/${archiveId}/results/compared/commercial_invoice/${documentId}.json`
        : type === "lc"
        ? `https://s3.traddocs.com/documents/${archiveId}/results/compared/letter_of_credit/${documentId}.json`
        : type === "di"
        ? `https://s3.traddocs.com/documents/${archiveId}/results/compared/document_instruction/${documentId}.json`
        : type === "compared"
        ? `https://s3.traddocs.com/documents/${archiveId}/results/compared/bol/${documentId}.json`
        : type === "contract"
        ? `https://s3.traddocs.com/documents/${archiveId}/results/compared/contract/${documentId}.json`
        : type === "contractSales"
        ? `https://s3.traddocs.com/documents/${archiveId}/results/compared/contract_sales/${documentId}.json`
        : `https://s3.traddocs.com/documents/${archiveId}/results/${type}/${documentId}.json`;

    return axios
      .get(url, { headers: { Authorization: null } })
      .then((response) => {
        console.log(`File ${documentId} ${type.toUpperCase()} fetched successfully:`);
        setData((prevData) => ({ ...prevData, [documentId]: response.data }));
        fetchedDocumentIds.current.add(documentId); // Mark the document ID as fetched
      })
      .catch((error) => {
        console.error(`Error fetching file ${documentId}:`, error);
      });
  }, []);

  const checkFileExistence = useCallback((type, archiveId, documentId, fetchedDocumentIds) => {
    if (fetchedDocumentIds.current.has(documentId)) {
      // Skipping already fetched document ID
      return Promise.resolve();
    }

    const url =
      type === "comparedInvoice"
        ? `https://s3.traddocs.com/documents/${archiveId}/results/compared/commercial_invoice/${documentId}.json`
        : type === "lc"
        ? `https://s3.traddocs.com/documents/${archiveId}/results/compared/letter_of_credit/${documentId}.json`
        : type === "di"
        ? `https://s3.traddocs.com/documents/${archiveId}/results/compared/document_instruction/${documentId}.json`
        : type === "compared"
        ? `https://s3.traddocs.com/documents/${archiveId}/results/compared/bol/${documentId}.json`
        : type === "contract"
        ? `https://s3.traddocs.com/documents/${archiveId}/results/compared/contract/${documentId}.json`
        : type === "contractSales"
        ? `https://s3.traddocs.com/documents/${archiveId}/results/compared/contract_sales/${documentId}.json`
        : `https://s3.traddocs.com/documents/${archiveId}/results/${type}/${documentId}.json`;

    return axios
      .head(url, { headers: { Authorization: null } })
      .then((response) => {
        console.log(`File ${documentId} ${type.toUpperCase()} exists.`);
        fetchedDocumentIds.current.add(documentId); // Mark the document ID as fetched
      })
      .catch((error) => {
        if (error.response) {
          switch (error.response.status) {
            case 403:
              console.error(`Access forbidden for file ${documentId}:`, error.response.data);
              break;
            case 404:
              console.log(`File ${documentId} not found.`);
              break;
            case 400:
              console.error(`Bad request for file ${documentId}:`, error.response.data);
              break;
            default:
              console.error(`Error checking file ${documentId}:`, error);
              break;
          }
        } else {
          console.error(`Error checking file ${documentId}:`, error);
        }
      });
  }, []);

  return { fetchJson, checkFileExistence };
};

export default useFetchJson;
