import XLSX from "xlsx-js-style";

export const downloadXlsx = (fileData) => {
  const wb = XLSX.utils.book_new();

  // Define some styles
  const headerStyle = {
    font: { bold: true, color: { rgb: "FFFFFF" } },
    fill: { fgColor: { rgb: "4F81BD" } },
    alignment: { horizontal: "center", vertical: "center" },
    border: {
      top: { style: "thin", color: { rgb: "000000" } },
      bottom: { style: "thin", color: { rgb: "000000" } },
      left: { style: "thin", color: { rgb: "000000" } },
      right: { style: "thin", color: { rgb: "000000" } },
    },
  };

  const bodyStyle = {
    font: { color: { rgb: "000000" } },
    alignment: { horizontal: "left", vertical: "center" },
    border: {
      top: { style: "thin", color: { rgb: "000000" } },
      bottom: { style: "thin", color: { rgb: "000000" } },
      left: { style: "thin", color: { rgb: "000000" } },
      right: { style: "thin", color: { rgb: "000000" } },
    },
  };

  // Apply styles to header and body
  const styledHeader = fileData.header.map((cell) => ({ v: cell, s: headerStyle }));
  const styledBody = fileData.body.map((row) => row.map((cell) => ({ v: cell, s: bodyStyle })));

  // Create sheets with styled data
  const fobSales = XLSX.utils.aoa_to_sheet([styledHeader, ...styledBody]);
  const openPurchase = XLSX.utils.aoa_to_sheet([styledHeader, ...styledBody]);

  // Set the width of the first column
  const columnWidths = [{ wch: 20 }, { wch: 30 }]; // Set the width of the first column to 20 characters and the second column to 30 characters
  fobSales["!cols"] = columnWidths;
  openPurchase["!cols"] = columnWidths;

  // Append sheets to workbook
  XLSX.utils.book_append_sheet(wb, fobSales, "FOB Sales");
  XLSX.utils.book_append_sheet(wb, openPurchase, "Open Purchase");

  // Write the workbook to a file
  XLSX.writeFile(wb, `${fileData.name}.xlsx`);
};
