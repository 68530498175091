const createFolderModal = {
  mode: "ko",
  modalHeader: "신규 하자검사 폴더",
  footer: {
    backButton: "뒤로가기",
    nextButton: "계속하기",
    completeButton: "검사 시작",
  },
  steps: {
    first: "null",
    second: "새 폴더 생성",
    third: "검사 시작",
  },
  stepOne: {
    option1: "벌크선",
    option2: "컨테이너선",
    option3: "탱커선",
    option4: "LNG선",
    option5: "PC선",
    option6: "RO-RO선",
    inDevelopement: "준비중",
  },
  stepTwo: {
    title1: "폴더명 설정",
    title2: "선적서류 파일 첨부",
    option1: "신규 선적건",
    option2: "기존 폴더에 서류 추가",
    placeholder1: "신규 폴더명 작성",
    placeholder2: "기존 폴더 선택",
    errorMessage: "폴더명이 이미 존재합니다. '기존 폴더에 서류를 추가'를 선택하세요.",
    dropzone: "500MB 이하의 pdf 파일 복수 선택 가능합니다",
    dropzoneButton: "파일 선택",
  },
  stepThree: {
    title: "아래의 정보가 맞는지 확인해주세요",
    subtitle1: "선종 (선적서류 종류)",
    subtitle2: "폴더명",
    subtitle3: "선택한 파일",
  },

  toast: {
    title: "하자 검사가 시작되었습니다.",
    subtitle: "약 40초 소요되며 완료되면 이메일로 알려드립니다.",
  },
};

export default createFolderModal;
