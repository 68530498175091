import React from "react";
import { FaCircleCheck } from "react-icons/fa6";
import { Link } from "react-router-dom";

const DocumentTypesIntro = () => {
  return (
    <div className="flex w-full items-center justify-center flex-col md:pt-24 py-16 text-gray-700 px-4  bg-gradient-to-b from-white to-sky-50">
      <div className="font-medium w-full justify-center flex text-blue-600 mb-6">Supported Documents</div>
      <h3 style={{ whiteSpace: "pre-wrap" }} className="text-4xl font-semibold text-center leading-normal">
        {`Automated checks are available for the\nfollowing document types`}
      </h3>
      <h4 className="mt-4 mb-8 break-keep text-base max-w-lg text-gray-500 leading-relaxed text-center w-full">
        We are constantly expanding our coverage to include more document types based on user feedback and demand.
      </h4>

      <div className="max-w-screen-xl w-full flex flex-col items-center py-16 bg-gray-700 rounded-lg shadow mt-8">
        <div className="gap-x-8 md:gap-x-24 gap-y-12 text-xl grid grid-cols-1 md:grid-cols-2 text-white">
          <div className="space-y-2 max-w-xs min-h-24">
            <div className="flex items-center space-x-3">
              <FaCircleCheck className="text-green-600" />
              <p>Bill of Lading</p>
            </div>
            <p className="pl-6 text-sm text-gray-400">• All types are accepted, including:</p>
            <p className="pl-6 text-sm text-gray-400">• Container BLs</p>
            <p className="pl-6 text-sm text-gray-400">• Bulk BLs</p>
            <p className="pl-6 text-sm text-gray-400">• Seaway bills</p>
          </div>
          <div className="space-y-2 max-w-xs min-h-24">
            <div className="flex items-center space-x-3">
              <FaCircleCheck className="text-green-600" />
              <p>Certificate of Origin</p>
            </div>
            <p className="pl-6 text-sm text-gray-400">• All types are accepted, including:</p>
            <p className="pl-6 text-sm text-gray-400">• Free Trade Agreement</p>
            <p className="pl-6 text-sm text-gray-400">• Least Developed Countries</p>
            <p className="pl-6 text-sm text-gray-400">• Generalized System of Preferences</p>
          </div>
          <div className="space-y-2 max-w-xs min-h-24">
            <div className="flex items-center space-x-3">
              <FaCircleCheck className="text-green-600" />
              <p>Packing List</p>
            </div>
            <p className="pl-6 text-sm text-gray-400">• All types are accepted.</p>
          </div>
          <div className="space-y-2 max-w-xs min-h-24">
            <div className="flex items-center space-x-3">
              <FaCircleCheck className="text-green-600" />
              <p>Commercial Invoice</p>
            </div>
            <p className="pl-6 text-sm text-gray-400">• All types are accepted.</p>
          </div>
          <div className="space-y-2 max-w-xs min-h-24">
            <div className="flex items-center space-x-3">
              <FaCircleCheck className="text-green-600" />
              <p>Weight Certificate</p>
            </div>
            <p className="pl-6 text-sm text-gray-400">• All types are accepted.</p>
          </div>
          <div className="space-y-2 max-w-xs min-h-24">
            <div className="flex items-center space-x-3">
              <FaCircleCheck className="text-green-600" />
              <p>Quality Certificate</p>
            </div>
            <p className="pl-6 text-sm text-gray-400">• All types are accepted.</p>
          </div>
          <div className="space-y-2 max-w-xs min-h-24">
            <div className="flex items-center space-x-3">
              <FaCircleCheck className="text-green-600" />
              <p>Phytosanitary Certificate</p>
            </div>
            <p className="pl-6 text-sm text-gray-400">• All types are accepted.</p>
          </div>

          <div className="space-y-2 max-w-xs min-h-24">
            <div className="flex items-center space-x-3">
              <FaCircleCheck className="text-green-600" />
              <p>Other 3rd party Certificates</p>
            </div>
            <p className="pl-6 text-sm text-gray-400 max-w-64">
              • More 3rd party certificates can be added with ease if requested
            </p>
          </div>
        </div>

        <div className="mt-12 text-sm break-keep flex space-x-1.5 text-gray-400">
          <p>Can't find what you're looking for? Request it</p>
          <Link to="/contact-us">
            <p className="text-blue-400 hover:text-blue-300">here.</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DocumentTypesIntro;
